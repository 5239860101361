import React, { useState, } from 'react';
import { Container, Row, Col, Card, Badge, Nav, } from 'react-bootstrap';
import About from './modal_about.jsx';
import AdvBottom from './advertisement_bottom.jsx';

// 单独的卡片组件
const CardComponent = ({ icon, title, text, onClick }) => (
    <Col md={6} lg={4} className="mb-4">
        <Card className='chat-index-card' onClick={onClick}>
            <Card.Body>
                <Card.Title><i className={`bi ${icon} text-success`}></i> {title}</Card.Title>
                <Card.Text>{text}</Card.Text>
            </Card.Body>
        </Card>
    </Col>
);

function App() {
    const currentYear = new Date().getFullYear(); // 获取当前年份
    const [showAbout, setShowAbout] = useState(false);  //显示 About 页面

    // 卡片数据数组
    const cardsData = [
        { icon: "bi-pen", title: "写入", text: "帮助我准备并编写成功的销售信件", name: "ChatGPT" },
        { icon: "bi-search", title: "研究", text: "比较竞争者和概念销售策略", name: "ChatGPT" },
        { icon: "bi-bar-chart-line", title: "分析", text: "创建有关大型营销和销售的SWOT分析", name: "ChatGPT" },
        { icon: "bi-book", title: "学习", text: "如何设定可实现的工作目标", name: "ChatGPT" },
        { icon: "bi-emoji-laughing", title: "大笑", text: "有什么好的笑话能让我在下次会议上打破僵局", name: "ChatGPT" },
        { icon: "bi-code-slash", title: "代码", text: "最新 JavaScript 版本中有哪些新功能?", name: "ChatGPT_Programmer" },
        // ...其他卡片数据...
    ];

    return (
        <>
            <Container className="chat-index my-5 d-none d-md-block">
                <Row className="justify-content-md-center">
                    <Col md={10}>
                        <img className="login-logo" src="/logo.png" alt="Logo" />
                        <h4 className="pt-5 pb-5 me-auto mb-0 d-flex justify-content-center align-items-center">
                            <Badge className="rounded-pill bg-warning me-1">
                                <span className="mb-2">AI</span>
                            </Badge>思维车间
                        </h4>
                        <Row className="pt-4">
                            {cardsData.map((card, index) => (
                                <CardComponent
                                    key={index}
                                    icon={card.icon}
                                    title={card.title}
                                    text={card.text}
                                    // onClick={handleOnClick(card.name)}
                                />
                            ))}
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center mt-3 mb-2">
                    <small className="text-muted">
                        手机上就能用 ChatGPT 和 Midjourney, 微信关注公众号{' '}
                        "<Nav.Link className="custom-nav-link text-success p-0" style={{ display: 'inline' }} onClick={() => setShowAbout(true)}>
                            AI思维车间
                        </Nav.Link>"
                    </small>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center mt-2 mb-3">
                        <small className="text-muted">
                            © {currentYear} AI思维车间 保留所有权利。
                        </small>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col md={11}>
                        <AdvBottom />
                    </Col>
                </Row>
                
            </Container>
            <About show={showAbout} onHide={() => setShowAbout(false)}></About>            
        </>
    );
}

export default App;
