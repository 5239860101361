import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Button } from 'react-bootstrap';
import { baseUrl_api, baseUrl, apiUrl_api } from './config.jsx';
import Adv from './advertisement_checkin';
import { format } from 'date-fns';


// 单独的卡片组件
const CardComponent = React.memo(({ date, day, text, signed }) => {    
    return (
        <Card
            className={`${signed ? 'check-in-card-checked' : 'check-in-card'} border-0`}
            data-day={day}
            title={date}
        >
            <Card.Body
                className="check-in-card-body d-flex flex-column justify-content-center align-items-center p-0"
            >
                <Card.Text className={`${signed ? 'text-success': 'text-secondary'} check-in-font-text`}>
                    {text}
                </Card.Text>
            </Card.Body>
        </Card>
    )
});


function CheckIn() {
    const [cardsDay, setCardsDay] = useState([]);
    const [nextDelta, setNextDelta] = useState(5);
    const [signed, setSigned] = useState(null);
    const [countdown, setCountdown] = useState(5);
    const [allowClose, setAllowClose] = useState(false);
    const [uid, setUid] = useState(null);
    
    function getSimpleUserLocaleDate() {
        // 省略区域设置参数，让浏览器自动使用用户的本地设置
        return new Date().toLocaleDateString();
    }

    // 使用
    const date = getSimpleUserLocaleDate();   
    
    const Login = async () => {
        const bodyData = {url: baseUrl};
        
        fetch(apiUrl_api + "/login/get_uid", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyData),
            keepalive: true,
            credentials: "include", // 允许发送 cookie
        })
        .then(response => response.json())
        .then(data => {
            if(data.uid) {                
                setUid(data.uid);
            } else {
                // 如果后端没有返回 uid，显示登录检查弹窗
                setUid(null);
            };
        })
        .catch(error => {
            console.error(error);
        });
    };

    const loadCheckIn = async () => {
        try {
            const localDate = format(new Date(), 'yyyy-MM-dd');
            
            const response = await fetch(baseUrl_api + '/getcheckin', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                credentials: 'include',
                body: JSON.stringify({ uid: uid, date: date }),
            });
            
            const data = await response.json();
            
            if (data.code === 200) {
                 // 检查是否有匹配的日期
                const isMatched = data.message.value.some(item => item.checkdate === localDate);

                if(isMatched) {
                    setSigned(true);
                } else {
                    setSigned(false);
                };

                setNextDelta(data.message.nextDelta);
                setCardsDay(data.message.value);  
            } else {
                setNextDelta(5);
                setCardsDay([]); 
            };
        } catch (error) {
            console.error('Error:', error);
        };
    };

    useEffect(() => {
        Login();

    }, []);

    useEffect(() => {
        if(uid) {
            loadCheckIn();
        };
    }, [uid]);

    useEffect(() => {
        if (signed) {
            setCountdown(0);
        } else {
            setCountdown(5);
        };
        
        setAllowClose(false);
        const timer = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown <= 1) {
                    setAllowClose(true);
                }
                return prevCountdown - 1;
            });
        }, 1000);
        return () => clearInterval(timer);
    }, [signed])

    const handleClose = () => {
        if(allowClose) {
            onAdvHide();
            // window.location.href = baseUrl;
            loadCheckIn();
        };
    };

    const onAdvHide = async () => {
        const iDelta = nextDelta;
        const response = await fetch(baseUrl_api + '/addcheckin', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', },
            credentials: 'include',
            body: JSON.stringify({ uid: uid, date: date, iDelta: iDelta }),
        });
    };    

    return (
        <div className="check-in-container">
            <h2 className="text-success">Get Free Tokens</h2>
            <div className="d-flex flex-wrap justify-content-center align-items-center">
                {cardsDay.map((card, index) => (
                    <CardComponent
                        key={index}
                        date={card.checkdate}
                        day={card.sort}
                        text={card.text}
                        signed={card.signed}
                    />
                ))}
            </div>

            <div className="container text-center py-1">
                {uid && (
                    <Button
                        variant="outline-success"
                        className="btn-outline-secondary rounded-3 btn-inline"
                        disabled={countdown > 0}
                        onClick={handleClose}
                    >
                        {countdown > 0 ? `${countdown}s` : `${ signed ? 'Checked' : 'Check In'}`}
                    </Button>
                )}
            </div>

            <div className="adv-container">
                <Adv advSlot="8900857602" />
                <Adv advSlot="1237990008" />
            </div>
        </div>
    );
}

export default CheckIn;
