import React, { useEffect, useState, useRef } from 'react';
import './advertisement.css'

// 自适应广告
function AdvCheckIn({ advSlot = "1237990008" }) {
    const [showAd, setShowAd] = useState(true);

    useEffect(() => {
        let adLoadTimeout;

        const existingScript = document.querySelector('script[src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"]');
        
        if (!existingScript) {
            const script = document.createElement('script');
            script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
            script.async = true;
            script.crossOrigin = "anonymous";
            document.head.appendChild(script);
            script.onload = () => {
                initAds();
            };
        } else {
            initAds();
        };

        function initAds() {
            if (window.adsbygoogle ) {
                // 确保 window.adsbygoogle 是一个数组
                window.adsbygoogle = window.adsbygoogle || [];

                // 获取所有的 <ins class="adsbygoogle"> 元素
                const adSlots = document.querySelectorAll('ins.adsbygoogle');

                // 检查是否有任何广告尚未被加载
                const alreadyLoaded = Array.from(adSlots).every(adSlot => adSlot.getAttribute('data-adsbygoogle-status') === 'done');
                
                try {
                    window.adsbygoogle.push({});
                } catch (error) {
                    if (error.message.includes("All 'ins' elements in the DOM with class=adsbygoogle already have ads in them.")) {
                        // 处理特定的 TagError
                        // console.error('Specific TagError caught:', error);
                        setShowAd(true);
                        // 在这里添加您的错误处理逻辑
                    } else {
                        // 处理其他类型的错误
                        console.error('AdSense init error:', error);
                        setShowAd(false);
                    };
                }
            };
        };        

        return () => {
            if (adLoadTimeout) {
                clearTimeout(adLoadTimeout);
            };
        };
    }, []);
    

    // 如果 showAd 为 false，则不渲染广告
    if (!showAd) {
        return null;
    };

    return (
        <>
            <div className="pt-2">
                <ins className="adsbygoogle advertisement-checkin"
                    data-ad-layout="in-article"
                    data-ad-format="fluid"
                    data-ad-client="ca-pub-9568214720585557"
                    data-ad-slot={advSlot}
                />
            </div>
        </>
    );
    
}

export default AdvCheckIn;
