import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Tooltip, Button, OverlayTrigger } from 'react-bootstrap';
import { fileIcon } from './config';
import './file_icon.css';
import ShowRenderByFileType from './render_by_filetype_show.jsx';

const ChatMainMessageBodyFileCard = ({ file }) => {
    const [showRenderByFileType, setShowRenderByFileType] = useState(false);
    const [blobUrls, setBlobUrls] = useState({});

    const renderTooltip = (props) => {        
        return (
            <Tooltip id="button-lyric" {...props} className="media-lyric-tooltip">
                <Card>
                    <Card.Text>
                        {}
                    </Card.Text>
                </Card>
            </Tooltip>
        );
    };

    const handleIconClick = (event) => {
        event.preventDefault();  // 阻止<a>标签的默认行为
        
        if (file.filetype === "text/plain" || file.file_type === "text/plain") {
            fetch(file.file_url)
                .then(response => response.blob())
                .then(blob => {
                    const newBlob = new Blob([blob], { type: 'text/plain; charset=utf-8' });
                    const blobUrl = URL.createObjectURL(newBlob);
                    setBlobUrls(prevUrls => {
                        const updatedUrls = { ...prevUrls, [file.file_url]: blobUrl };
                        setShowRenderByFileType(true);  // 在状态更新后显示组件
                        return updatedUrls;
                    });
                })
                .catch(error => {
                    console.error('Error loading the text file:', error);
                    setShowRenderByFileType(true);  // 即使出错也显示组件，可能需要处理错误显示
                });
        } else {
            setShowRenderByFileType(true);  // 对于非 text/plain 类型，直接显示组件
        }
    
        return () => {
            Object.values(blobUrls).forEach(url => URL.revokeObjectURL(url));
        };
    };

    return (
        <>
            <ShowRenderByFileType
                show={showRenderByFileType}
                onHide={() => setShowRenderByFileType(false)}
                files={[file]}
                blobUrls={blobUrls}
                showEstimate={false}
            />

            <Col md={4}>
                <Row className="g-0">
                    <Col md={3} className="pe-2 position-relative">
                        <Card className="border-0">
                            <a href={file.file_url}
                                className={fileIcon[file.file_type].desc}
                                target="_blank" rel="noopener noreferrer"
                                onClick={handleIconClick}
                            >
                                {fileIcon[file.file_type].icon}
                            </a>
                        </Card>
                    </Col>
                    <Col>
                        <Card.Body>
                            <Card.Title>
                                <a href={file.file_url} className="text-secondary" target="_blank" rel="noopener noreferrer" download={file.file_name}>
                                    {file.file_name}
                                </a>
                            </Card.Title>

                            <Card.Text>{file.metadata && file.metadata.tags}</Card.Text>
                            
                            <Card.Text style={{ display: 'flex', flexDirection: 'column' }}>
                                <span className="text-muted mb-1 text-success">
                                    <em>{file.source_language}</em> into <em>{file.target_language}</em>
                                </span>

                                {file.characters && (<small className="text-muted mb-1 text-success">
                                    <em><strong>{file.characters}</strong></em> characters
                                </small>)}

                                {file.tokens && (<small className="text-muted mb-1 text-success">
                                    <em><strong>{file.tokens}</strong></em> tokens
                                </small>)}

                                <small className="text-muted">
                                    {!file.status ? 'Generating' : file.created}
                                </small>
                            </Card.Text>
                        </Card.Body>
                    </Col>
                </Row>
            </Col>
        </>
    );    
};

export default ChatMainMessageBodyFileCard;
