// 加载文件

import React, { useRef, useImperativeHandle, forwardRef, useState } from 'react';
import { fileAccept, } from './config.jsx';
import { uploadMultipleFiles, uploadDiscordFiles, } from './file_upload';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShowUploadModal from './modal_uploading.jsx';
import { discordUpload, } from './config.jsx'

const FileUpload = forwardRef(({ uid, fileType, onFileSelect, chatClassName, maxFiles = 6, resize = false, container = "image" }, ref) => {    
    const fileInputRef = useRef();
    const maxFileSize = 20 * 1024 * 1024; // 20MB in bytes
    const [showModal, setShowModal] = useState(false);
    const acceptType = fileAccept[fileType];
    // 多个文件
    const handleFileChange = async (event) => {
        const files = Array.from(event.target.files); // 获取所有选中的文件
        if (files.length > 0) {
            // const maxFiles = 9; // 最大文件数量
            // 检查文件数量是否超过限制
            if (files.length > maxFiles) {
                toast.error(`You can only upload up to ${maxFiles} file(s) at a time.`);
                setShowModal(false);
                return; // 如果超过，停止处理
            };
            
            const acceptedTypes = Object.values(acceptType).flat();

            // 过滤出符合条件的文件
            // const validFiles = files.filter(file => acceptedTypes.includes(file.type) && file.size <= maxFileSize);
            const validFiles = files.filter(file => {
                const fileExtension = file.name.split('.').pop().toLowerCase();
                // 检查文件类型是否在接受的类型列表中，或者文件类型为空但扩展名为'msg'
                return (acceptedTypes.includes(file.type) || (file.type === '' && fileExtension === 'msg' && fileType === "translator")) && file.size <= maxFileSize;
            });
                        
            // 如果有不符合条件的文件，提醒用户
            files.forEach(file => {
                const fileExtension = file.name.split('.').pop().toLowerCase();
                let file_type = file.type;
                if(file.type === '' && fileExtension === 'msg' && fileType === 'translator') file_type = '.msg';
                
                if (!acceptedTypes.includes(file_type)) {
                    toast.error(`The file type of "${file.name}" is not accepted.`);
                    setShowModal(false);
                    return;
                } else if (file.size > maxFileSize) {
                    toast.error(`The file "${file.name}" exceeds the 20MB size limit.`);
                    setShowModal(false);
                    return;
                };
            });
    
            // 如果所有文件都符合条件，上传文件
            if (validFiles.length > 0 && validFiles.length <= maxFiles) {                
                setShowModal(true);
                
                if(discordUpload.includes(chatClassName)) {
                    uploadDiscordFiles(uid, validFiles, handleUploadResponse);
                } else {
                    uploadMultipleFiles(
                        uid, validFiles, handleUploadResponse,
                        resize, maxFileSize, container
                    );
                };                
            };
        } else {
            setShowModal(false);
        };

        // 重置input的值
        event.target.value = null;
    };

    
    useImperativeHandle(ref, () => ({
        click: () => {
            fileInputRef.current.click();
        }
    }));

    const handleUploadResponse = (text, type) => {
        setShowModal(false);
        if (type === "error") {
            toast.error(text);
        } else {
            onFileSelect(text.message, type);
        };
        
    };
    

    return (
        <>
            <div>
                <input
                    type="file"
                    accept={acceptType}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    multiple={true}
                />
            </div>
            <ShowUploadModal show={showModal} onHide={() => setShowModal(false)}/>
        </>
    );
});

export default FileUpload;
