import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

function ShowPhotoMakerUploadedModal({ show, onHide }) {
    const [countdown, setCountdown] = useState(10); // 设置初始倒计时为10秒

    useEffect(() => {
        // 如果模态框不显示或倒计时已结束，则不执行任何操作
        if (!show) {
            // 当模态框不显示时，重置倒计时
            setCountdown(10);
            return;
        };

        // 设置一个新的计时器
        const timerId = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        // 清理函数：清除计时器
        return () => clearInterval(timerId);
    }, [show, countdown]);

    // 当倒计时结束时调用 onHide 关闭模态框
    useEffect(() => {
        if (countdown === 0) {
            onHide();
        }
    }, [countdown, onHide]);

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">PhotoMaker Uploaded</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <h5 className="mb-3">Upload finished, you can click the "Image" button later to find your images.</h5>
                    <h5 className="mb-5">上传完成, 您可以点击 "Images" 按钮找到您的图片.</h5>
                    <p>Modal will close in <span className="text-success">{countdown}</span> seconds...</p> {/* 显示倒计时 */}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ShowPhotoMakerUploadedModal;
