import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, Col, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';

const ChatMainMessageBodyMediaCard = ({ media, isPlaying, onPlayToggle }) => {
    const audioRef = useRef(null);
    const [lyrics, setLyrics] = useState(''); // 歌词状态

    const renderTooltipLyric = (props) => {
        // 将歌词按换行符分割成数组
        const lyricsLines = media.metadata.prompt.split('\n').map((line, index) => (
            // 为每一行创建一个包含文本和 <br> 的 JSX 元素
            // 使用 React.Fragment 避免额外的 DOM 元素包裹
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    
        return (
            <Tooltip id="button-lyric" {...props} className="media-lyric-tooltip">
                <Card>
                    <Card.Text>
                        {lyricsLines}
                    </Card.Text>
                </Card>
            </Tooltip>
        );
    };
    

    useEffect(() => {
        // 当 isPlaying 状态改变时，播放或暂停音频
        if (audioRef.current) {
            if (isPlaying) {
                const playPromise = audioRef.current.play();
                if (playPromise !== undefined) {
                    playPromise.catch(error => {
                        // Auto-play was prevented
                        console.error("Playback was prevented:", error);
                    });
                }
            } else {
                audioRef.current.pause();
            }
        }
    }, [isPlaying]);

    // 当音频播放结束时，通知父组件更新播放状态
    const handleAudioEnded = () => {
        onPlayToggle(); // 这里调用父组件传递的 onPlayToggle 函数
    };

    return (
        <Col md={6}>
            <Row className="g-0">
                <Col md={4} className="pe-2 position-relative">
                    <Card.Img variant="top" src={media.image_url || 'placeholder-image.png'} />
                    <Button
                        variant="outline-light"
                        onClick={onPlayToggle}
                        className="media-play-on-image"
                        hidden={!media.finish || !media.audio_url}
                    >
                        <i className={`bi ${isPlaying ? 'bi-pause-fill' : 'bi-play-fill'} fs-3`}></i>
                    </Button>
                    <audio ref={audioRef} src={media.audio_url} onEnded={handleAudioEnded} />
                </Col>
                <Col md={8}>
                    <Card.Body>
                        <Card.Title>{media.title}</Card.Title>
                        <Card.Text>{media.metadata && media.metadata.tags}</Card.Text>
                        
                        <Card.Text>
                            <small className="text-muted">
                                {!media.finish ? 'Generating' : new Date(media.created_at).toLocaleString()}
                            </small>
                        </Card.Text>

                        <Button
                            variant="outline-secondary"
                            title="mp3"
                            // href={media.audio_url}
                            className="me-2 border-0 ps-1 pe-1 pt-1 pb-1"
                            onClick={() => window.open(media.audio_url, '_blank', 'noopener,noreferrer')}
                            hidden={!media.finish || !media.audio_url}
                        ><i className="bi bi-file-music fs-4"></i></Button>

                        <Button
                            variant="outline-secondary"
                            title="mp4"
                            // href={media.video_url}
                            className="me-2 border-0 ps-1 pe-1 pt-1 pb-1"
                            onClick={() => window.open(media.video_url, '_blank', 'noopener,noreferrer')}
                            hidden={!media.finish || !media.video_url}
                        >
                            <i className="bi bi-file-play fs-4"></i>
                        </Button>

                        <OverlayTrigger
                            placement="right"
                            overlay={renderTooltipLyric}
                        >
                            <Button
                                variant="outline-secondary"
                                title="lrc"
                                id="button-lyric"
                                // href={media.video_url}
                                className="me-2 border-0 ps-1 pe-1 pt-1 pb-1"
                                onClick={() => window.open(media.lyric_url, '_blank', 'noopener,noreferrer')}
                                hidden={!media.finish || !media.lyric_url}
                            >
                                <i className="bi bi-file-font fs-4"></i>
                            </Button>
                        </OverlayTrigger>
                    </Card.Body>
                </Col>
            </Row>
        </Col>
    );    
};

export default ChatMainMessageBodyMediaCard;
