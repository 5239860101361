import React, { useState, useEffect, useRef, } from 'react';
import { Modal, Button, FormLabel, Row, Col, FormControl } from 'react-bootstrap';
import { baseUrl_api } from './config.jsx'


function ModalEdit({ classID, setShowEditChat, onSetCustomChat }) {
    const [nameText, setNameText] = useState("");
    const [descText, setDescText] = useState("");
    const [promptText, setPromptText] = useState("");
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [isShowEditControl, setIsShowEditControl] = useState(false);
    const [temperatureText, setTemperatureText] = useState(0.7); 
    const [temperatureDesc, setTemperatureDesc] = useState("Balanced");
    const [temperatureClassName, setTemperatureClassName] = useState("");
    // 创建一个 ref 来引用输入框
    const inputRef = useRef(null);


     // 当组件挂载后，将焦点设置到输入框上
    useEffect(() => {
        // 检查 inputRef.current 是否存在，并设置焦点
        if (isShowEditControl && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isShowEditControl]); // 空依赖数组确保这个 effect 只在挂载时运行一次

    useEffect(() => {
        // 定义一个异步函数
        const fetchData = async () => {
            try {
                const response = await fetch(baseUrl_api + '/getnewchat', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include',
                    body: JSON.stringify({ id: classID }),
                });
                const data = await response.json();
                
                setIsShowEditControl(true); // 开放编辑
                if(data.code ===200) {
                    setNameText(data.name);
                    setDescText(data.desc);
                    setPromptText(data.prompt);
                    setTemperatureText(data.temperature);
                    
                }
                // 在这里处理 data
            } catch (error) {
                console.error('Error:', error);
            }
        };
    
        // 调用异步函数
        if(classID) {
            fetchData();
        } else {
            setIsShowEditControl(true); // 开放编辑
        };
    }, [classID]); // 如果 classID 会变化，应该将它作为依赖项

    useEffect(() => {
        if (temperatureText >= 0 && temperatureText <= 0.2) {
            // temperatureText 在 0 到 0.3 之间
            setTemperatureDesc("More Precise")
            setTemperatureClassName("badge bg-secondary-subtle border border-secondary-subtle text-secondary-emphasis rounded-pill")
        } else if (temperatureText > 0.2 && temperatureText <= 0.4) {
            // temperatureText 在 0.3 到 0.6 之间
            setTemperatureDesc("Precise")
            setTemperatureClassName("badge bg-secondary-subtle border border-secondary-subtle text-secondary-emphasis rounded-pill")
        } else if (temperatureText > 0.4 && temperatureText <= 0.6) {
            // temperatureText 在 0.3 到 0.6 之间
            setTemperatureDesc("Balanced")
            setTemperatureClassName("badge bg-success-subtle border border-success-subtle text-success-emphasis rounded-pill")
        } else if (temperatureText > 0.6 && temperatureText <= 0.8) {
            // temperatureText 在 0.3 到 0.6 之间
            setTemperatureDesc("Creative")
            setTemperatureClassName("badge bg-warning-subtle border border-warning-subtle text-warning-emphasis rounded-pill")
        } else {
            // temperatureText 在 0.6 到 1 之间
            setTemperatureDesc("More Creative")
            setTemperatureClassName("badge bg-warning-subtle border border-warning-subtle text-warning-emphasis rounded-pill")
        };
        
    }, [temperatureText])
    

    const getInputClassName = () => {
        let baseClass = "form-control border-1 mt-0 chat-siderbar-items-edit-modal-input ps-3";
        if (isSubmitClicked && (!nameText || nameText === "")) {
            baseClass += " input_unfinish"; // 添加额外的类
        };
        
        return baseClass;
    };

    const getTextareaClassName = () => {
        let baseClass = "form-control border-1 mt-0 chat-siderbar-items-edit-modal-textarea p-3";
        if (isSubmitClicked && (!promptText || promptText === "")) {
            baseClass += " input_unfinish"; // 添加额外的类
        };
        
        return baseClass;
    };

    const handleYes = () => {
        setIsSubmitClicked(true); // 设置按钮点击状态为 true

        if ((nameText && nameText.trim() !== "") 
            && (promptText && promptText.trim() !== "")){

            const cumstomChat = {
                id: classID,
                name: nameText,
                desc: descText,
                prompt: promptText,
                temperature: temperatureText,
            };
            
            onSetCustomChat(cumstomChat);
            setShowEditChat(false);
            // setIsEditChat(true);
        };
    };

    const handleNo = () => {
        setShowEditChat(false);
        // setIsEditChat(false);
    };

    const onNameChange = (event) => {
        const newValue = event.target.value;
        setNameText(newValue);
    };

    const onDescChange = (event) => {
        const newValue = event.target.value;
        setDescText(newValue);
    };

    const onPromptChange = (event) => {
        const newValue = event.target.value;
        setPromptText(newValue);
    };

    const handleTemperatureChange = (e) => {
        setTemperatureText(e.target.value);
    };

    return (
        <Modal show={true} onHide={() => setShowEditChat(false)} centered size="lg">
            <Modal.Body className="rounded-3 shadow">
                <Modal.Body className="p-3 text-center">
                    <Row className="g-3 chat-siderbar-items-edit-modal-row">
                        <h5 className="mb-3 link-success text-start">You can make your own ChatGPT here.</h5>                       
                    </Row>
                    <Row className="chat-siderbar-items-edit-modal-row mt-3 mb-3">
                        <Col xs={6} className="text-start">
                            <FormLabel className="text-start ms-1">Name</FormLabel>
                            <FormControl                                
                                value={nameText}
                                placeholder="New Chat"
                                onChange={onNameChange}
                                maxLength={20}
                                className={getInputClassName()}
                                disabled={!isShowEditControl}
                                ref={inputRef}
                            />

                        </Col>
                        <Col xs={6} className="text-start">
                            <FormLabel className="text-start ms-1">Description</FormLabel>
                            <FormControl
                                value={descText}
                                placeholder="Make your own chat"
                                onChange={onDescChange}
                                maxLength={40}
                                className="form-control border-1 mt-0 chat-siderbar-items-edit-modal-input ps-3"
                                disabled={!isShowEditControl}
                            />
                        </Col>
                    </Row>

                    <Row className="chat-siderbar-items-edit-modal-row mt-3 mb-3">
                        <Col xs={12} className="text-start">
                            <Row className="chat-siderbar-items-edit-modal-row mt-3 mb-3">
                                <FormLabel className="text-start ms-1">Style</FormLabel>
                                <Col xs={9} className="text-start">
                                    <FormControl
                                        type="range"
                                        className="range-temperature"
                                        id="customRange"
                                        min="0.1"
                                        max="1"
                                        step="0.1"
                                        value={temperatureText}
                                        onChange={handleTemperatureChange}
                                        disabled={!isShowEditControl}
                                    />
                                </Col>
                                <Col xs={3} className="text-start d-flex align-items-center">
                                    <FormLabel className={`text-start ms-1 mt-1 ${temperatureClassName}`}>{temperatureDesc}</FormLabel>                            
                                </Col>
                            </Row>
                        </Col>                        
                    </Row>
                    
                    <Row className="chat-siderbar-items-edit-modal-row mt-3 mb-3">
                        <Col xs={12} className="text-start">
                            <FormLabel className="text-start ms-1">Prompt</FormLabel>
                            <textarea
                                value={promptText}
                                onChange={onPromptChange}
                                placeholder="Your chat prompt"
                                rows="12"
                                maxLength={4000}
                                className={getTextareaClassName()}
                                disabled={!isShowEditControl}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="flex-nowrap p-0 mt-3">
                    <Button
                        variant="link-success"
                        className="btn-lg fs-6 text-decoration-none col-6 py-3 m-0 border-0 rounded-0 border-end"
                        onClick={handleYes}
                        disabled={!isShowEditControl}
                    >
                        <strong className="link-success">Submit</strong>
                    </Button>
                    <Button
                        variant="link-success"
                        className="btn-lg fs-6 text-decoration-none col-6 py-3 m-0 border-0 rounded-0"
                        // data-bs-dismiss="modal"              
                        onClick={handleNo}
                        disabled={!isShowEditControl}
                    >
                        <div className='link-success'>Cancel</div>
                    </Button>
                </Modal.Footer>
            </Modal.Body>
        </Modal>    
    );
};

export default ModalEdit;