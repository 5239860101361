import React, { useEffect } from 'react';
import DOMPurify from 'dompurify';

function ChatMainMessageBodyCardDesacribe({ chatDescribe, onLoaded }) {
    // 清理 HTML 并转换 Markdown 图片和链接
    const cleanHTML = DOMPurify.sanitize(chatDescribe, { ADD_ATTR: ['target'] });
        
    const markdownImageRegex = /!\[([^\]]+)\]\((.*?)\s*("(?:.*[^"])")?\s*\)/g;
    const markdownLinkRegex = /\[([^\]]+)\]\((.*?)\s*("(?:.*[^"])")?\s*\)/g;
    const convertedHTML = cleanHTML
        .replace(markdownImageRegex, '<img src="$2" alt="$1" />')
        .replace(markdownLinkRegex, '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>');

    const createMarkup = () => {
        return { __html: convertedHTML };
    };

    useEffect(() => {
        // 通知父组件加载完成
        if (onLoaded) { onLoaded(); };
    }, [chatDescribe])

    return (
        <div className="chat-main-message-body-card-describe text-secondary font-italic">
            {/* 使用 dangerouslySetInnerHTML 渲染 HTML */}
            <div className='small ms-2' dangerouslySetInnerHTML={createMarkup()} />
        </div>
    );
}

export default ChatMainMessageBodyCardDesacribe;