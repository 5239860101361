import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row } from 'react-bootstrap';
import FileCard from './chat_main_message_body_file_card.jsx';
import { baseUrl_api, apiUrl_api } from './config.jsx';

const FileItem = ({ item }) => {
    return (
        item.status.toLowerCase() === "succeeded" && (
            <FileCard
                key={item.id}
                file={item}
            />
        )
    );
};


function ChatMainMessageBodyFile ({ uid, chatClass, filesArray }) {
    const [files, setFiles] = useState([]);
    const [reload, setReload] = useState(false);
    const [optionsLanguage, setOptionsLanguage] = useState([]);

    const groupFiles = (files, numberOfFilesPerRow) => {
        const groupedFiles = [];
        for (let i = 0; i < files.length; i += numberOfFilesPerRow) {
            groupedFiles.push(files.slice(i, i + numberOfFilesPerRow));
        }
        return groupedFiles;
    };
    
    const reloadHistory = async () => {
        try {
            const response = await fetch(baseUrl_api + '/chathistory', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                credentials: 'include',
                body: JSON.stringify({ 'uid':uid, 'classID': chatClass.classID }),
            });

            const data = await response.json();
            const processedFiles = processFiles(data);
            setFiles(processedFiles);
        } catch (error) {
            console.error('Error:', error);
        };
    };

    // 新的函数，用于处理 filesArray
    const processFiles = (files) => {
        try {
            // 创建 medias 数组的副本并进行反向排序
            const sortedFiles = [...files].sort((a, b) => b.timestamp - a.timestamp);

            // 提取每个元素的 response 属性
            const response = sortedFiles.map(file => {
                const file_response = JSON.parse(file.response);

                let sourceLanguageLabel = optionsLanguage.find(option => option.value === file_response.source_language)?.label;
                if (!sourceLanguageLabel) sourceLanguageLabel = "Auto";
                file_response.source_language = sourceLanguageLabel;
                
                const targetLanguageLabel = optionsLanguage.find(option => option.value === file_response.target_language)?.label;
                file_response.target_language = targetLanguageLabel;
                // console.log(72, file_response);
                return file_response;
            });
            return response;
        } catch {
            // 如果解析失败，返回空数组
            return [];
        };
    };

    const formatOptions = (data) => {
        const formattedOptions = data.map(item => ({
            id: item.ID,
            label: item.OptionLabel,
            value: item.OptionValue,
            language: item.Alias
        }));
        setOptionsLanguage(formattedOptions);
    };

    const loadLanguage = () => {
        // 获取数据库中语言选项
        const bodyData = {option_class: "language"}
        fetch(apiUrl_api + "/options", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyData),
            keepalive: true,
        })
        .then(response => response.json())
        .then(data => {
            const dataArray = JSON.parse(data);
            formatOptions(dataArray); // 调用formatOptions函数来格式化选项
        })
        .catch(error => {
            console.error(error);
        });
    };

    useEffect(() => {
        if (reload) {
            // 执行重新载入组件的逻辑
            // 例如，您可以重置 mediaFiles 状态或执行其他操作
            // setMediaFiles([]);
            setReload(false); // 重置 reload 状态
            reloadHistory();
            // 如果需要，您可以在这里添加其他重新载入组件的逻辑
        }
    }, [reload]);

    useEffect(() => {
        loadLanguage();
    }, [chatClass.className])
    
    useEffect(() => {
        try {
            // 检测是否存在 {reload: true} 的项
            const reloadItem = filesArray.find(file => file.reload === true);
            
            if (reloadItem) {
                // 如果存在，则设置 reload 状态为 true
                setReload(true);
            } else {
                const processedFiles = processFiles(filesArray);
                setFiles(processedFiles)
            };
        } catch(error) {
            console.error('Error:', error);
            // filesArray = [];
        };
    }, [filesArray, optionsLanguage]);

    return (
        <div className="chat-main-message-body-media-container mt-3">
            {files.length > 0 && groupFiles(files, 3).map((fileGroup, index) => (
                <Row key={index} className="ms-3 mb-4 mt-4">
                    {fileGroup.map((file, fileIndex) => (
                        <FileItem key={fileIndex} item={file} />
                    ))}
                </Row>
            ))}
        </div>
    );
};

export default ChatMainMessageBodyFile;
