import React from 'react';

function ChatMainMessageHeader({ chatClass }) {
    return (
        <div className="chat-main-message-header">
            <h3>{chatClass.displayName}</h3>
            <h6 className='text-secondary font-italic'>{chatClass.nickName}</h6>
        </div>
    );
}

export default ChatMainMessageHeader;