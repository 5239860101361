import React, { useEffect, useState, useRef } from 'react';
import './advertisement.css'

// 固定高度广告
function AdvBottom({ advSlot = "1564791087", slide = false }) {
    const [showAd, setShowAd] = useState(true);
    const [adBottom, setAdBottom] = useState('0px');
    const [isPaused, setIsPaused] = useState(false); // 新增状态来控制动画是否暂停
    const AD_LOAD_TIMEOUT = 5000;
    const adTimeoutRef = useRef(); // 使用 ref 来存储超时函数的引用

    useEffect(() => {
        let adLoadTimeout;

        const existingScript = document.querySelector('script[src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"]');
        
        if (!existingScript) {
            const script = document.createElement('script');
            script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
            script.async = true;
            script.crossOrigin = "anonymous";
            document.head.appendChild(script);
            script.onload = () => {
                initAds();
            };
        } else {
            initAds();
        };

        function initAds() {
            if (window.adsbygoogle ) {
                // 确保 window.adsbygoogle 是一个数组
                window.adsbygoogle = window.adsbygoogle || [];

                // 获取所有的 <ins class="adsbygoogle"> 元素
                const adSlots = document.querySelectorAll('ins.adsbygoogle');

                // 检查是否有任何广告尚未被加载
                const alreadyLoaded = Array.from(adSlots).every(adSlot => adSlot.getAttribute('data-adsbygoogle-status') === 'done');

                try {
                    window.adsbygoogle.push({});
                } catch (error) {
                    if (error.message.includes("All 'ins' elements in the DOM with class=adsbygoogle already have ads in them.")) {
                        // 处理特定的 TagError
                        // console.error('Specific TagError caught:', error);
                        setShowAd(true);
                        // 在这里添加您的错误处理逻辑
                    } else {
                        // 处理其他类型的错误
                        console.error('AdSense init error:', error);
                        setShowAd(false);
                    };
                }
                // 设置超时函数来滑动隐藏广告
                adLoadTimeout = setTimeout(() => {
                    setAdBottom('-140px'); // 设置 bottom 为负的广告高度，使其滑出视图
                }, AD_LOAD_TIMEOUT);
            };
        };        

        return () => {
            if (adLoadTimeout) {
                clearTimeout(adLoadTimeout);
            };
        };
    }, []);

    // useEffect(() => {
    //     console.log(52, showAd);
    // }, [showAd])

    // 鼠标悬停事件处理程序
    const handleMouseEnter = () => {
        setIsPaused(true); // 设置动画暂停状态为 true
        setAdBottom('0px');
        if (adTimeoutRef.current) {
            clearTimeout(adTimeoutRef.current); // 清除超时函数以暂停滑动
        };
    };

    const handleMouseLeave = () => {
        setIsPaused(false); // 设置动画暂停状态为 false
        // 如果广告已经开始滑动，但还没有完全消失，恢复动画
        if (adBottom > '-140px') {
            adTimeoutRef.current = setTimeout(() => {
                setAdBottom('-140px');
            }, AD_LOAD_TIMEOUT);
        };
    };

    // 如果 showAd 为 false，则不渲染广告
    if (!showAd) {
        return null;
    };

    // // 滑动效果
    // return (
    //     <div
    //         className={[isPaused ? 'paused' : '', 'advertisement-bottom'].join(' ').trim()}
    //         style={{ bottom: adBottom }}
    //         onMouseEnter={handleMouseEnter}
    //         onMouseLeave={handleMouseLeave}
    //     >
    //         <ins className="adsbygoogle adsbygoogle-bottom"
    //             data-ad-layout="in-article"
    //             data-ad-format="fluid"
    //             data-ad-client="ca-pub-9568214720585557"
    //             data-ad-slot={advSlot}
    //             // data-full-width-responsive="true"
    //         />
    //     </div>
    // );

    return (
        <div>
            <ins className="adsbygoogle adsbygoogle-bottom"
                data-ad-layout="in-article"
                data-ad-format="fluid"
                data-ad-client="ca-pub-9568214720585557"
                data-ad-slot={advSlot}
                // data-full-width-responsive="true"
            />
        </div>
    );
}

export default AdvBottom;
