import './message.css'
import { apiUrl_api, baseUrl, } from './config.jsx';
import React from 'react';
import { Card, Row, Col, Button, Table, } from 'react-bootstrap';

const plans = [
    { id: 'PAY001', title: 'Basic', price: '¥ 9.9', items: '150', amount: '990' },
    { id: 'PAY002', title: 'Pro', price: '¥ 19.9', items: '450', amount: '1990' },
    { id: 'PAY003', title: 'Enterprise', price: '¥ 39.9', items: '1000', amount: '3990' },
    { id: 'PAY005', title: 'Ultimate', price: '¥ 99.9', items: '2800', amount: '9990' },
];

const featureRows = [
    { name: "ChatGPT 3.5", plans: [-1, "Basic", "Pro", "Enterprise", "Ultimate"] },
    { name: "ChatGPT 4", plans: [-2, "Basic", "Pro", "Enterprise", "Ultimate" ] },
    { name: "Dall-E", plans: [-2, "Basic", "Pro", "Enterprise", "Ultimate"] },
    { name: "Vision", plans: [-2, "Basic", "Pro", "Enterprise", "Ultimate"] },
    { name: "Midjourney", plans: [-5, "Basic", "Pro", "Enterprise", "Ultimate"] },
    { name: "Suno", plans: [-10, "Basic", "Pro", "Enterprise", "Ultimate"] },
];

function PricingCard({ plan, handlePricingClick, loginInfo }) {
    return (
        <Col className="col-md-6 col-lg-3">
            <Card className="mb-4 rounded-3 shadow-sm">
                <Card.Header className="py-3">
                    <h4 className="my-0 fw-normal">{plan.title}</h4>
                </Card.Header>
                <Card.Body>
                    <h1 className="card-title pricing-card-title">{plan.price}<small className="text-body-secondary fw-light"></small></h1>
                    <ul className="list-unstyled mt-3 mb-4">
                        <li>{plan.items} tokens<small className="text-body-success fw-light"> /Never Expires</small></li>
                        <li style={{ marginTop: "10px" }}></li>
                    </ul>
                    <Button
                        variant="outline-success"
                        className="w-100 btn btn-lg"
                        onClick={() => handlePricingClick(plan.items, plan.amount, `AI思维车间对话${plan.items}条`, plan.id, "CNY")}
                    >Get started</Button>
                </Card.Body>
            </Card>
        </Col>
    );
}

function Pricing({ loginInfo }) {
    const handlePricingClick = (time, amount, description, event_key, currency) => {
        const postData = {
            url: baseUrl,
            uid: loginInfo.uid,
            time: time,
            amount: amount,
            currency: 'CNY',
            description: description,
            event_key: event_key,
            currency: currency,
            mail: loginInfo.mail,
            pay_app: "ChatGPT",
        }

        // 发起支付申请
        fetch(apiUrl_api + "/pay/stripe_pay_application", {
            method: "POST",
            body: JSON.stringify(postData),
            headers: { "Content-Type": "application/json", },
            keepalive: true,
            credentials: "include", // 允许发送 cookie
        })
        .then(response => response.json())
        .then(data => {
            // 获取重定向URL
            window.location.assign(data.pay_url);
        })
        .catch(error => {
            console.error(error);
        });
    };

    // 渲染表格
    const renderedRows = featureRows.map((row, index) => (
        <tr key={index}>
            <th scope="row" className="text-start">{row.name}</th>
            {row.plans.map((plan, planIndex) => {
                // 对于第一列，直接显示数值
                if (planIndex === 0) {
                    return <td key={planIndex} className="text-secondary">{plan}</td>;
                } else {
                    // 查找对应的计划
                    const planDetail = plans.find(p => p.title === plan);
                    if (planDetail) {
                        // 计算并显示items除以特性的数值
                        const calculatedValue = parseInt(planDetail.items) / Math.abs(row.plans[0]);
                        return <td key={planIndex} className="text-secondary">{calculatedValue.toFixed(0)}</td>; // 保留两位小数
                    } else {
                        return <td key={planIndex} className="text-secondary">-</td>; // 如果没有找到对应的计划，显示一个占位符
                    }
                }
            })}
        </tr>
    ));

    return (
        <div className="none-scrollbar">
            <h1 className="text-center mt-5 mb-5">Pricing</h1>
            <Row className='row-cols-1 row-cols-md-3 mb-3 text-center justify-content-center'>
                {plans.map(plan => (
                    <PricingCard key={plan.id} plan={plan} handlePricingClick={handlePricingClick} loginInfo={loginInfo} />
                ))}
            </Row>
            <h2 className="display-6 text-center mb-4">Compare plans</h2>
            <Col className="col-md-6 col-lg-9 mx-auto">
                <div className="table-responsive text-center">
                    <Table className="text-center">
                        <thead>
                            <tr>
                                <th style={{ width: '25%' }}></th>
                                <th style={{ width: '15%' }}>Cost <small className="text-body-success fw-normal">(tokens)</small></th>
                                <th style={{ width: '15%' }}>Basic</th>
                                <th style={{ width: '15%' }}>Pro</th>
                                <th style={{ width: '15%' }}>Enterprise</th>
                                <th style={{ width: '15%' }}>Ultimate</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {featureRows.map((row, index) => (
                                <tr key={index}>
                                    <th scope="row" className="text-start">{row.name}</th>
                                    {row.plans.map((plan, planIndex) => (
                                        <td key={planIndex}>
                                            {planIndex === 0 ? ` ${plan}` : <i className="bi bi-check2"></i>}
                                            {planIndex === 0 ? ` ${plan}` : `${plan[0]}` }
                                        </td>
                                    ))}
                                </tr>
                            ))} */}
                            {renderedRows}
                        </tbody>
                    </Table>
                </div>
            </Col>
        </div>
    )

}

export default Pricing;



