import React, { useState, useRef, useEffect } from 'react';
import { Button, Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import ChatMainDialogBodyPan from './chat_main_dialog_body_pan.jsx'
import { chatMedia, imageChatClassName, fileTranslator } from './config.jsx'

function ChatMainDialogBody({ chatClassName, setSendMessage, isReceived }) {
    const [text, setText] = useState('');
    const [showButtonPan, setShowButtonPan] = useState(false);
    const textareaRef = useRef(null);
    const sendButtonRef = useRef(null);
    const [showPanModal, setShowPanModal] = useState(false);
    const [placeholder, setPlaceholder] = useState("Type your message ...");

    useEffect(() => {
        checkScroll();
    }, [text]); // 依赖于text的变化来检查滚动条

    const checkScroll = () => {
        const textarea = textareaRef.current;
        if (textarea.scrollHeight > textarea.clientHeight) {
            setShowButtonPan(true);
        } else {
            setShowButtonPan(false);
        };
    };

    useEffect(() => {
        if(chatClassName) {
            const textarea = textareaRef.current;
            textarea.focus();
        };
    }, [chatClassName]);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setText(newValue);
        checkScroll();
    };

    const renderTooltipSend = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Alt + S
        </Tooltip>
    );

    const renderTooltipPan = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Alt + P
        </Tooltip>
    );

    const handleKeyDown = (event) => {
        if (showPanModal) {
            // 如果模态框是打开的，不处理键盘事件
            return;
        }
        
        if (event.key === 'Enter' && !text.trim()) {
            event.preventDefault();
        }

        if (((event.altKey || event.ctrlKey) && event.key === 'Enter') ||
            (event.altKey && (event.key === 'S' || event.key === 's'))) {
            handleSendClick();
            event.preventDefault();
        }

        if (event.altKey && (event.key === 'P' || event.key === 'p')) {
            handelPanClick();
            event.preventDefault(); // 防止默认行为
        }
    };

    const handelPanClick = () =>{
        setShowPanModal(true);
    };
    
    const handleSendClick = () =>{
        const code = chatClassName.toLowerCase() === "vision" ? "Vision" : "Chat";

        const message = {code: code, message: text};
        setSendMessage(message);
        setShowPanModal(false);
        setText("");
    };

    useEffect (() => {
        if(isReceived) {
            setPlaceholder("Type your message ...");
            const textarea = textareaRef.current;
            textarea.focus();
        } else {
            if (imageChatClassName.includes(chatClassName)) {
                setPlaceholder("I'm drawing, maybe 2 minutes, please wait a moment and don't type again...");
            } else if (chatMedia.includes(chatClassName)) {
                setPlaceholder("I'm generating, maybe 10 minutes, please wait a moment and don't type again...");
            } else {
                setPlaceholder("I'm thinking, please wait a moment and don't type again...");
            };
        };
    }, [isReceived])

    return (
        <div className="chat-main-dialog-container">
            <Row className="chat-main-dialog-container-row">
                <Col xs={12} className="chat-main-dialog-container-col">
                    <textarea
                        ref={textareaRef}
                        className="form-control chat-main-dialog-textarea border-0"
                        id="user-dialog"
                        value={text}
                        placeholder={placeholder}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        onScroll={checkScroll} // 添加滚动事件监听
                        disabled={!isReceived || fileTranslator.includes(chatClassName)}
                    />
                </Col>

                <Col className="chat-main-dialog-container-col-right">
                    {showButtonPan && ( // 根据showButtonPan的值来决定是否显示按钮
                        <OverlayTrigger
                            placement="right"
                            overlay={renderTooltipPan}
                        >
                            <Button variant="outline-secondary"
                                className="button-pan border-0"
                                onClick={handelPanClick}
                                disabled={!isReceived || fileTranslator.includes(chatClassName)}
                            >
                                <i className="bi bi-arrows-fullscreen icon-enlarged"></i>
                            </Button>
                        </OverlayTrigger>
                    )}

                    <OverlayTrigger
                        placement="right"
                        overlay={renderTooltipSend}                        
                    >
                        <Button variant="outline-secondary"
                            className="button-send border-0"
                            onClick={handleSendClick}
                            ref={sendButtonRef}
                        >
                            <i className="bi bi-send icon-enlarged"></i>
                        </Button>
                    </OverlayTrigger>
                </Col>
            </Row>

            <ChatMainDialogBodyPan
                show={showPanModal}
                onHide={() => setShowPanModal(false)}
                text={text}
                setText={setText} // 如果需要在子组件中更新text
                onSendClick={handleSendClick}
            />
        </div>
    );
}

export default ChatMainDialogBody;