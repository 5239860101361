// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './app_home_page.jsx'; // 你的主页组件
import CheckIn from './app_checkin.jsx'; // 关于页面组件

function App() {
  return (
    // <Router>
    <Router basename="/chat">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/chat" element={<HomePage />} />
        <Route path="/checkin" element={<CheckIn />} />
      </Routes>
    </Router>
  );
}

export default App;
