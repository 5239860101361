import React, { useEffect, useState, useRef, } from 'react';
import { Card, Row, Col, Badge, Image } from 'react-bootstrap';
import io from 'socket.io-client';
import { apiUrl, apiUrl_api, } from './config.jsx';
import './login.css'

function LoginWechat({ isMobile, onModalClose }) {
  const [state, setState] = useState('');
  // const [show, setShow] = useState(true);
  const [weChatLoginUrl, setWeChatLoginUrl] = useState('')
  
  const socketRef = useRef(null);

  useEffect(() => {
    // if(!isMobile) {
    //   // 生成一个唯一的state参数
    //   const uuid = () => {
    //     return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    //       const r = (Math.random() * 16) | 0,
    //         v = c === 'x' ? r : (r & 0x3) | 0x8;
    //       return v.toString(16);
    //     });
    //   };
    //   setState(uuid());
    // }

    // 手机登录暂时显示二维码，如果需要隐藏恢复上面的代码
    // 生成一个唯一的state参数
    const uuid = () => {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    };
    setState(uuid());

  }, []);

  useEffect(() => {
    // 将生成的state参数发送给服务器
    if(state) {
      // 创建 WebSocket 连接
      socketRef.current = io(`${apiUrl}?state=${state}`, {
        withCredentials: true,
        timeout: 180000, // 设置超时时间为3分钟
      }); // 请根据您的后端服务器地址进行调整
      
      let bodyData = {};

      // 超时断开监听
      socketRef.current.on('connect_timeout', () => {
          console.error('socket time out');
          socketRef.current.disconnect();
      });

      // 监听 login_success 事件
      socketRef.current.on('login_success', (response) => {
        const uid = response['uuid']

        // post 到后端登记 session id
        bodyData = { uid: uid, };
        fetch(apiUrl_api+ "/login", {
          method: "POST",
          headers: { "Content-Type": "application/json", },
          body: JSON.stringify(bodyData),
          keepalive: true,
          credentials: "include", // 允许发送 cookie
        })

        // 关闭模态框
        handleClose(uid);
      });

      bodyData = { state: state, };
      fetch(apiUrl_api + "/login/wechat_login", {
        method: "POST",
        headers: { "Content-Type": "application/json", },
        body: JSON.stringify(bodyData),
        keepalive: true,
        credentials: "include", // 允许发送 cookie
      })
      .then(response => response.json())
      .then(data => {
        setWeChatLoginUrl(data.code_url);
      })
      .catch((error) => {
        console.error("Error fetching qrCodeUrl:", error);
      });

      // 在组件卸载时关闭 WebSocket 连接
      return () => {
        socketRef.current.disconnect();
      };
    }
  }, [state])  

  const handleClose = (uid) => {
    // setShow(false);
    // 在这里调用父组件传递的处理函数
    // 关闭 WebSocket 连接
    if (socketRef.current) {
      socketRef.current.disconnect();
    }

    let loginInfo = {};

    if(uid) {
      loginInfo = {
        "islogin": true,
        "uid":uid,
      };
    }

    onModalClose(loginInfo);
  };

  return (
    <div>
      { !isMobile && 
        <Card className="mb-0 border-0 text-reset d-flex justify-content-center align-items-center">                
          <Card.Body>
            <h4 className="me-auto mb-0 d-flex justify-content-center align-items-center">
              <Badge className="rounded-pill bg-warning me-1">
                <span className="mb-2">AI</span>
              </Badge>思维车间
            </h4>
          </Card.Body>
          <div className="d-flex justify-content-center align-items-center m-3">
            <Image className="login-QRCode" src={weChatLoginUrl} alt="QRcode" fluid />
          </div>
          
          <Card.Body>
            <Row className="gx-5">
              <Col>
                <div className="d-flex flex-column justify-content-center align-items-center mb-3">
                  <h5 className="mb-0 text-secondary hide-on-small-screens">请使用微信扫码登录</h5>
                  <h6 className="mb-0 mt-3 text-secondary ms-5 me-5 hide-on-small-screens">Please use WeChat to scan the QR code to log in</h6>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card> 
      }
    </div>
  );    
}

export default LoginWechat;
