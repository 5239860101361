
import { apiUrl_api, baseUrl, } from './config.jsx';

const bodyData = {url: baseUrl};

export const LoginGoogle = () => {

    fetch(apiUrl_api + "/login/google_login", {
        method: "POST",
        headers: { "Content-Type": "application/json", },
        body: JSON.stringify(bodyData),
        keepalive: true,
        credentials: "include", // 允许发送 cookie
    })
    .then(response => response.json())
    .then(data => {
        // 获取重定向URL
        window.location.assign(data.auth_url);
    })
    .catch(error => {
        console.error(error);
    });
};

export const LoginWechatMobile = () => {
    fetch(apiUrl_api + "/login/wechat_mobile", {
        method: "POST",
        headers: { "Content-Type": "application/json", },
        body: JSON.stringify(bodyData),
        keepalive: true,
        credentials: "include", // 允许发送 cookie
    })
    .then(response => response.json())
    .then(data => {
        // 获取重定向URL
        window.location.assign(data.auth_url);
    })
    .catch(error => {
        console.error(error);
    });
};

