import React, { useEffect, useState } from 'react';
import { Row, Col, ButtonGroup, } from 'react-bootstrap';
import { baseUrl_api, } from './config.jsx';
import ChatMainDialogHeaderTools from './chat_main_dialog_header_tools.jsx';
import { floatBilling } from './config.jsx';

function ChatMainDialogHeader({
    uid, chatClassName, onRadioChange, onFileLoadDone, toolButtons,
    onShowFiles, onShowMJParam, onShowExpand, onSetParam
}) {
    const [models, setModels] = useState([]);
    const [selectedModelRate, setSelectedModelRate] = useState(null);

    useEffect(() => {
        if (models.length > 0) {
          const defaultModel = models[0];
          handleChange({ target: { id: defaultModel.Remark } });
        }
    }, [models]);

    const handleChange = (event) => {
        onRadioChange(event.target.id); // 将选中的 radio 的 id 传递给父组件
        
        const selectedModel = models.find(model => model.Remark === event.target.id);
        if (selectedModel) {
            setSelectedModelRate(selectedModel.Rate); // 替换 YourDesiredAttribute 为你想显示的属性名
        }
    };

    const getModel = async (model = null) => {
        try {
            const response = await fetch(baseUrl_api + '/getmodel', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                credentials: 'include',
                body: JSON.stringify({ 'model': model }),
            });
            const data = await response.json();
            setModels(data);
            if (data.length > 0) {
                setSelectedModelRate(data[0].Rate); // 默认选中第一个模型的属性
            }
        } catch (error) {
            console.error('Error:', error);
        };
    };

    useEffect (() => {
        if (chatClassName) {
            if (chatClassName.startsWith("ChatGPT") || chatClassName.startsWith("New") ){
                if(models.length < 2) {
                    getModel();
                };
            } else {
                if(chatClassName.startsWith("Midjourney")) {
                    chatClassName = "Midjourney"
                } else if (chatClassName.startsWith("PhotoMaker")) {
                    chatClassName = "PhotoMaker"
                };
                getModel(chatClassName);
            };
        };
    }, [chatClassName])

    return (
        <>
        { selectedModelRate && (
            <Row className="justify-content-start">
                <Col xs="auto" className="d-flex align-items-center pe-1">
                    <ButtonGroup className="mb-3">
                        {models.map((model, index) => (
                            <React.Fragment key={model.ID}>
                                <input type="radio" className="btn-check"
                                    name="options-base" id={model.Remark}
                                    autoComplete="off" onChange={handleChange}
                                    defaultChecked={index === 0} // 第一个选项默认选中
                                />
                                <label className="btn btn-select-select btn-outline-secondary text-in-small-screens" htmlFor={model.Remark}>
                                    {model.DisplayName}
                                </label>
                            </React.Fragment>
                        ))}
                    </ButtonGroup>
                </Col>
                
                { !floatBilling.includes(chatClassName) && (
                    <Col xs="auto" className="d-flex align-items-center pe-1">
                        <p className="d-flex text-secondary font-italic text-in-small-screens">
                            -{selectedModelRate}&nbsp;{selectedModelRate > 1 ? <span className="hide-on-small-screens">tokens</span> : <span className="hide-on-small-screens">token</span>}
                        </p>
                    </Col>
                )}
                
                {/* { chatClassFile.includes(chatClassName) && */}
                    <Col xs="auto" className="d-flex align-items-center ps-0">
                        <ChatMainDialogHeaderTools
                            uid={uid}
                            chatClassName={chatClassName}
                            onFileLoadDone={onFileLoadDone}
                            toolButtons={toolButtons}
                            onShowFiles={onShowFiles}
                            onShowMJParam={onShowMJParam}
                            onShowExpand={onShowExpand}
                            onSetParam={onSetParam}
                        />
                    </Col>
                {/* } */}
            </Row>
        )}
        </>
    );
}

export default ChatMainDialogHeader;