import React, { useState, useEffect } from 'react';
import { Col, ButtonGroup, Button, Row, } from 'react-bootstrap';
import { baseUrl_api, } from './config.jsx';

function ChatPhotoMakerHeader({ uid, chatClass, onOptionChange, onGenderChange, onReload }) {
    const [selectedOption, setSelectedOption] = useState('draw');
    const [selectedGender, setSelectedGender] = useState('male');
    const [taskDesc, setTaskDesc] =  useState(null);
    const [taskDescCN, setTaskDescCN] =  useState(null);
    const [nonDesc, setNonDesc] = useState(null);
    const [nonDescCN, setNonDescCN] = useState(null);

    const [desc, setDesc] = useState(null);
    const [descCN, setDescCN] = useState(null);

    const handleOptionChange = (event) => {
        onOptionChange(event.target.id);
        setSelectedOption(event.target.id);

        photoMakerTask();
    };

    const handleGenderChange = (event) => {
        setSelectedGender(event.target.id);
        onGenderChange(event.target.id);
    };

    const photoMakerTask = async () => {
        try {
            const response = await fetch(baseUrl_api + '/photomakertask', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                credentials: 'include',
                body: JSON.stringify({ 'uid': uid }),
            });
    
            const data = await response.json();
            
            if (data.code === 200) {
                let fullDesc = ''; // 初始化完整描述字符串
                let fullDescCN = '';    // 中文
            
                // 检查taskcount是否存在并且不为null
                if (data.message.taskcount) {
                    const taskWord = data.message.taskcount === 1 ? 'task' : 'tasks'; // 单复数判断
                    fullDesc += `You have ${data.message.taskcount} ${taskWord} waiting to be processed.`;
                    fullDescCN += `您有 ${data.message.taskcount} 个任务正在排队处理.`;
                };
            
                // 检查noncount是否存在并且不为null
                if (data.message.noncount) {
                    const nonTaskWord = data.message.noncount === 1 ? 'task' : 'tasks'; // 单复数判断
                    // 如果fullDesc已经包含描述，则添加连接词
                    if (fullDesc.length > 0) {
                        fullDesc += ` In the past 24 hours, there have been ${data.message.noncount} ${nonTaskWord} that could not be processed due to insufficient tokens.`;
                        fullDescCN += ` 过去的24小时中, 您还有 ${data.message.noncount} 个任务由于token不足而无法处理.`;
                    } else {
                        // 如果fullDesc为空，则直接添加描述
                        fullDesc += `In the past 24 hours, there have been ${data.message.noncount} ${nonTaskWord} that could not be processed due to insufficient tokens.`;
                        fullDescCN += `过去的24小时中, 您有 ${data.message.noncount} 个任务由于 token 不足而无法处理.`;
                    };
                };
            
                // 设置描述，如果fullDesc为空则设置为null
                if (fullDesc.length > 0) {
                    setDesc(fullDesc);
                    setDescCN(fullDescCN);
                } else {
                    setDesc(null);
                    setDescCN(null);
                };
            };
            
        } catch (error) {
            console.error('Error:', error);
        };
    };

    useEffect(() => {
        // 定义一个定时器，每隔2分钟调用loadPhotoMakerImages函数
        const intervalId = setInterval(() => {
            photoMakerTask();
        }, 60000); // 1分钟

        // 清除定时器的效果
        return () => clearInterval(intervalId);
    }, []); // 空依赖数组[]表示这个effect只在组件挂载时运行一次

    useEffect(() => {
        photoMakerTask();
    }, [chatClass.className]); 

    return (
        <>
            <div className="chat-photomaker-header">
                <h3>{chatClass.displayName}</h3>
                <h6 className='text-secondary font-italic'>{chatClass.nickName}</h6>                
            </div>
            <Row className="justify-content-start">
                <Col xs="auto" className="d-flex align-items-center chat-photomaker-header">
                    <ButtonGroup className="mb-3">
                        <React.Fragment>
                            <input type="radio" className="btn-check"
                                id="draw"
                                name="option"
                                autoComplete="off"
                                checked={selectedOption === 'draw'} // 使用checked属性来控制按钮的选中状态
                                onChange={handleOptionChange} // 添加onChange事件处理器
                            />
                            <label className="btn btn-select-select btn-outline-secondary text-in-small-screens" htmlFor="draw">
                                Draw
                            </label>
                        </React.Fragment>
                        <React.Fragment>
                            <input type="radio" className="btn-check"
                                id="images"
                                name="option"
                                autoComplete="off"
                                checked={selectedOption === 'images'} // 使用checked属性来控制按钮的选中状态
                                onChange={handleOptionChange} // 添加onChange事件处理器
                            />
                            <label className="btn btn-select-select btn-outline-secondary text-in-small-screens" htmlFor="images">
                                Images
                            </label>
                        </React.Fragment>
                    </ButtonGroup>

                    {selectedOption === "images" && (
                        <ButtonGroup className="mb-3 ms-3">
                            <Button variant='outline-secondary' className='border-0' 
                                onClick={onReload}                            
                            >
                                <i className="bi bi-arrow-clockwise"></i>
                            </Button>
                        </ButtonGroup>
                    )}                    

                    {selectedOption === "draw" && (
                        <p className="ms-3 d-flex text-secondary font-italic text-in-small-screens">
                            -5&nbsp;<span className="hide-on-small-screens">tokens</span>
                        </p>
                    )}

                    <div className="text-secondary font-italic ms-4 describ-align-center">
                        {desc}
                        <br />
                        {descCN}
                    </div>
                </Col>

                <Col xs="auto" className="d-flex align-items-center chat-photomaker-header">
                    {selectedOption === "draw" && (
                        <ButtonGroup className="mb-3">
                            <React.Fragment>
                                <input type="radio" className="btn-check"
                                    id="male"
                                    name="gender"
                                    autoComplete="off"
                                    checked={selectedGender === 'male'} // 使用checked属性来控制按钮的选中状态
                                    onChange={handleGenderChange} // 添加onChange事件处理器
                                />
                                <label className="btn btn-select-select btn-outline-secondary text-in-small-screens" htmlFor="male">
                                    Male
                                </label>
                            </React.Fragment>
                            <React.Fragment>
                                <input type="radio" className="btn-check"
                                    id="female"
                                    name="gender"
                                    autoComplete="off"
                                    checked={selectedGender === 'female'} // 使用checked属性来控制按钮的选中状态
                                    onChange={handleGenderChange} // 添加onChange事件处理器
                                />
                                <label className="btn btn-select-select btn-outline-secondary text-in-small-screens" htmlFor="female">
                                    Female
                                </label>
                            </React.Fragment>
                            <React.Fragment>
                                <input type="radio" className="btn-check"
                                    id="boy"
                                    name="gender"
                                    autoComplete="off"
                                    checked={selectedGender === 'boy'} // 使用checked属性来控制按钮的选中状态
                                    onChange={handleGenderChange} // 添加onChange事件处理器
                                />
                                <label className="btn btn-select-select btn-outline-secondary text-in-small-screens" htmlFor="boy">
                                    Boy
                                </label>
                            </React.Fragment>
                            <React.Fragment>
                                <input type="radio" className="btn-check"
                                    id="girl"
                                    name="gender"
                                    autoComplete="off"
                                    checked={selectedGender === 'girl'} // 使用checked属性来控制按钮的选中状态
                                    onChange={handleGenderChange} // 添加onChange事件处理器
                                />
                                <label className="btn btn-select-select btn-outline-secondary text-in-small-screens" htmlFor="girl">
                                    Girl
                                </label>
                            </React.Fragment>
                        </ButtonGroup>
                    )}                    
                </Col>
                
                {selectedOption === "images" && (
                    <div className="text-secondary font-italic ms-4 describ-align-center">
                    Only show images within the last 10 days. 只能显示10天以内的图片.
                </div>
                )}
                
            </Row>
        </>
    );
}

export default ChatPhotoMakerHeader;