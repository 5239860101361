import React, { useState, useEffect, } from 'react';
import { Modal, Button, Form, ButtonGroup, Col, Row, } from 'react-bootstrap';
import { baseUrl_api, } from "./config.jsx";

function ShowMJParam ({ show, onHide, onSetting, param }) {
    const [size, setSize] = useState('--ar 1:1');
    const [version, setVersion] = useState('--v 5.2')
    const [quality, setQuality] = useState('HD')
    const [chaos, setChaos] = useState(0);
    const [style, setStyle] = useState("");
    const [qualityItems, setQualityItems] = useState("");
    const [sizeItems, setSizeItems] = useState("");
    const [versionItems, setVersionItems] = useState("");
    const [styleItems, setStyleItems] = useState("");
    const [isActive, setIsActive] = useState(true); // 默认开启
    const [chaosDesc, setChaosDesc] = useState("Balanced");
    const [chaosClassName, setChaosClassName] = useState("");
    const [disabled, setDisabled] = useState(true);
    
    useEffect(() => {
        setChaosDesc(chaos)

        if (chaos >= 0 && chaos <= 25) {
            setChaosClassName("badge bg-secondary-subtle border border-secondary-subtle text-secondary-emphasis rounded-pill")
        } else if (chaos > 25 && chaos <= 50) {
            setChaosClassName("badge bg-success-subtle border border-success-subtle text-success-emphasis rounded-pill")
        } else if (chaos > 50 && chaos <= 75) {
            setChaosClassName("badge bg-warning-subtle border border-warning-subtle text-warning-emphasis rounded-pill")
        } else {
            setChaosClassName("badge bg-danger-subtle border border-danger-subtle text-danger-emphasis rounded-pill")
        };
    }, [chaos])

    const onSubmit = () => {
        // 在这里，你可以访问所有的状态，它们包含了用户的输入
        const formData = {
            size: size,
            version: version,
            chaos: chaos,
            style: style,
            active: isActive,
        };
    
        onSetting(formData);
    
        // 如果你需要关闭模态框，可以调用 onHide
        onHide();
      };   

    const handleChaosChange = (e) => {
        setChaos(e.target.value);
    };

    const loadParam = async () => {
        try {
            const response = await fetch(baseUrl_api + '/getparam', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                credentials: 'include',
                body: JSON.stringify({ type: "midjourney" }),
            });

            const data = await response.json();
            
            if (data.code === 200) {
                // 从 JSON 数据中找到 type 为 'style' 的部分
                const styleOptions = data.message.find(item => item.type === 'style').text;
                const sizeOptions = data.message.find(item => item.type === 'size').text;
                const versionOptions = data.message.find(item => item.type === 'model').text;
                const qualityOptions = data.message.find(item => item.type === 'quality').text;

                setStyleItems(styleOptions);
                setSizeItems(sizeOptions);
                setVersionItems(versionOptions);
                setQualityItems(qualityOptions);
                
                setDisabled(false);
            } else {
                onHide();
            };
            
        } catch (error) {
            console.error('Error:', error);
        };
    };

    // 处理下拉选项变化的函数
    const handleStyleChange = (event) => {
        setStyle(event.target.value);
    };

    useEffect (() => {
        if (param) {
            const isEmpty = Object.keys(param).length === 0;
            if(!isEmpty) {
                setSize(param.size);
                setVersion(param.version);
                setChaos(param.chaos);
                setStyle(param.style);
                setIsActive(param.active);
            } else {

            };
        };
        
        loadParam();        
    }, [])

    const handleSwitchChange = (event) => {
        setIsActive(event.target.checked); // 更新状态为 switch 的当前值
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            { styleItems.length > 0 && (
                <>                
                <Modal.Header closeButton>
                    <Modal.Title className="text-success">Midjourney Advanced</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label className="ms-1 mt-3">Size</Form.Label>
                            <ButtonGroup className="mb-3 d-flex flex-wrap">
                                {sizeItems.map((item) => (
                                    <Button
                                        key={item.code}
                                        variant={size === item.value ? 'secondary' : 'outline-secondary'}
                                        className="btn-mj-size-icon"
                                        onClick={() => setSize(item.value)}
                                        disabled={disabled}
                                    >
                                        <i className={item.icon}></i>
                                        <span>{item.name}</span>
                                    </Button>
                                ))}
                            </ButtonGroup>

                            <Row className="chat-siderbar-items-edit-modal-row mt-3">
                                <Form.Label className="ms-1 mt-3">Version</Form.Label>
                                <Col xs={10} className="text-start">
                                    <ButtonGroup className="mb-3 d-flex flex-wrap">
                                        {versionItems.map((item) => (
                                            <Button
                                                key={item.code}
                                                variant={version === item.value ? 'secondary' : 'outline-secondary'}
                                                className="btn-mj-model-icon"
                                                onClick={() => setVersion(item.value)}
                                                disabled={disabled}
                                            >
                                                <i className={item.icon}></i>
                                                <span className="btn-mj-icon-text">{item.name}</span>
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </Col>
                            </Row>

                            {/* <Form.Label className="ms-1 mt-3">Quality</Form.Label>
                            <ButtonGroup className="mb-3 d-flex flex-wrap">
                                {qualityItems.map((item) => (
                                    <Button
                                        key={item.code}
                                        variant={quality === item.value ? 'secondary' : 'outline-secondary'}
                                        className="btn-mj-size-icon"
                                        onClick={() => setQuality(item.value)}
                                        disabled={disabled}
                                    >
                                        <i className={item.icon}></i>
                                        <span>{item.name}</span>
                                    </Button>
                                ))}
                            </ButtonGroup> */}

                            {/* <Row className="chat-siderbar-items-edit-modal-row mt-3">
                                <Form.Label className="ms-1">Chaos</Form.Label>
                                <Col xs={10} className="text-start">
                                    <Form.Control
                                        type="range"
                                        className="range-temperature"
                                        id="customRange"
                                        min="0"
                                        max="100"
                                        step="1"
                                        value={chaos}
                                        onChange={handleChaosChange}
                                        disabled={disabled}
                                    />
                                </Col>
                                <Col xs={2} className="text-start d-flex align-items-center">
                                    <Form.Label className={`text-start ms-1 mt-1 ${chaosClassName}`}>{chaosDesc}</Form.Label>                            
                                </Col>
                            </Row> */}

                            <Row className="chat-siderbar-items-edit-modal-row  mt-3">
                                <Form.Label className="ms-1">Style</Form.Label>
                                <Col xs={10} className="text-start">
                                    <Form.Control
                                        as="select" value={style}
                                        className="range-temperature"
                                        onChange={handleStyleChange} 
                                        disabled={disabled}
                                    >
                                        {styleItems.map((option) => (
                                            <option key={option.code} value={option.value}>
                                                {option.code} {option.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className="chat-siderbar-items-edit-modal-row mt-3">
                                <Col xs="auto" className="text-start">
                                    <Form.Label className="ms-1 mt-3">Enable</Form.Label>
                                </Col>
                                <Col xs="auto" className="text-start">
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        className="ms-1 mt-3"
                                        checked={isActive}
                                        onChange={handleSwitchChange}
                                    />
                                </Col>
                            </Row>
                            
                        </Form.Group>
                    </Form>
                </Modal.Body>

                {/* <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="outline-success" onClick={onSubmit} disabled={disabled}>
                        Submit
                    </Button>
                </Modal.Footer> */}
                <Modal.Footer className="flex-nowrap p-0 mt-3">
                    <Button
                        variant="link-success"
                        className="btn-lg fs-6 text-decoration-none col-6 py-3 m-0 border-0 rounded-0 border-end"
                        onClick={onSubmit}
                    >
                        <strong className="link-success">Submit</strong>
                    </Button>
                    <Button
                        variant="link-success"
                        className="btn-lg fs-6 text-decoration-none col-6 py-3 m-0 border-0 rounded-0"
                        onClick={onHide}
                    >
                        <div className='link-success'>Cancel</div>
                    </Button>
                </Modal.Footer>
                </>
            )}
        </Modal>
    );
};

export default ShowMJParam;
