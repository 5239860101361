import React from 'react';
import ChatSidebarItems from './chat_sidebar_items';
import { baseUrl_api } from './config.jsx'


function CahtSidebar ({uid, handleChatClick}) {

    const chatItemClick = async (chatClass) => {
        try {
            const response = await fetch(baseUrl_api + '/promotion', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                credentials: 'include',
            });
            const data = await response.json();

            // 创建一个新的chatClass对象，包含原有的属性和新的promotion属性
            const updatedChatClass = {
                ...chatClass,
                promotion: data.promotion
            };

            handleChatClick(updatedChatClass);

        } catch (error) {
            console.error('Error:', error);
        };
    };

    return (
        <div className="d-flex flex-column align-items-stretch flex-shrink-0 bg-body-tertiary scrollarea">
            <div className="chat-items-container">
                <ChatSidebarItems uid={uid} handleChatClick={chatItemClick} />
            </div>
        </div>
        
    );
};

export default CahtSidebar