import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row } from 'react-bootstrap';
import MediaCard from './chat_main_message_body_media_card.jsx';
import { baseUrl_api } from './config.jsx';

const MediaItem = ({ item, playingMediaId, onPlayToggle }) => {
    return (
        item.length > 0 && (
            <Row className="mb-3 p-3">
                {item.map(media => (
                    <MediaCard
                        key={media.id}
                        media={media}
                        isPlaying={playingMediaId === media.id}
                        onPlayToggle={() => onPlayToggle(media.id)} // 使用父组件传递的函数
                    />
                ))}
            </Row>
        )
    );
};


function ChatMainMessageBodyMedia ({ uid, chatClass, mediasArray }) {
    const [mediaFiles, setMediaFiles] = useState([]);
    const [reload, setReload] = useState(false);
    const [playingMediaId, setPlayingMediaId] = useState(null);
    
    const reloadHistory = async () => {
        try {
            const response = await fetch(baseUrl_api + '/chathistory', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                credentials: 'include',
                body: JSON.stringify({ 'uid':uid, 'classID': chatClass.classID }),
            });

            const data = await response.json();
            const processedMedias = processMedias(data);
            setMediaFiles(processedMedias);
        } catch (error) {
            console.error('Error:', error);
        };
    };

    // 新的函数，用于处理 mediasArray
    const processMedias = (medias) => {
        try {
            // 创建 medias 数组的副本并进行反向排序
            const sortedMedias = [...medias].sort((a, b) => b.timestamp - a.timestamp);

            // 提取每个元素的 response 属性
            const responses = sortedMedias.map(media => JSON.parse(media.response));
            return responses;
        } catch {
            // 如果解析失败，返回空数组
            return [];
        }
    };
    
    useEffect(() => {        
        try {
            // 检测是否存在 {reload: true} 的项
            const reloadItem = mediasArray.find(media => media.reload === true);
            if (reloadItem) {
                // 如果存在，则设置 reload 状态为 true
                setReload(true);
            } else {
                const processedMedias = processMedias(mediasArray);
                setMediaFiles(processedMedias)
            };
        } catch(error) {
            console.error('Error:', error);
            // mediasArray = [];
        }        
    }, [mediasArray]);

    useEffect(() => {
        if (reload) {
            // 执行重新载入组件的逻辑
            // 例如，您可以重置 mediaFiles 状态或执行其他操作
            // setMediaFiles([]);
            setReload(false); // 重置 reload 状态
            reloadHistory();
            // 如果需要，您可以在这里添加其他重新载入组件的逻辑
        }
    }, [reload]);

    const handlePlayToggle = (mediaId) => {
        if (playingMediaId === mediaId) {
            setPlayingMediaId(null);
        } else {
            setPlayingMediaId(mediaId);
        };
    };

    return (
        <div className="chat-main-message-body-media-container mt-3">
            { mediaFiles.length > 0 && mediaFiles.map((item, index) => (
                <MediaItem
                    key={index}
                    item={item}
                    playingMediaId={playingMediaId} // 传递当前播放的 mediaId
                    onPlayToggle={handlePlayToggle} // 传递处理播放切换的函数
                />
            ))}
        </div>
    );
};

export default ChatMainMessageBodyMedia;
