import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const ContentByFileType = ({ files, blobUrls, showEstimate = true }) => {
    try {
        return files.map((file, index) => {
            const FileNameDisplay = () => (
                <div className="file-name-display" style={{ marginBottom: '10px' }}>
                    {file.file_name}
                </div>
            );
            
            const FileTokens = ({ tokens }) => {
                const estimate = -Math.ceil(tokens / 350);
                file.characters = tokens;
                file.delta = estimate;
                
                return(
                    <>
                        <p className="text-secondary font-italic ms-2 mb-1">
                            Estimate: {estimate} tokens
                        </p>
                        <p className="text-secondary font-italic  ms-2 mb-0 small">
                            Token deductions are estimates. Actual deductions will be based on the completed translation.
                        </p>
                        <p className="text-secondary font-italic  ms-2 mt-0 small">
                            Token 扣除是估计值, 实际扣除将基于完成的翻译。
                        </p>
                    </>
                );
            };
            
            switch ( file.file_type || file.filetype ) {
                case "application/pdf":
                    return (
                        <>
                            <FileNameDisplay />
                            <div key={file.id} style={{ width: '100%', height: '600px', overflow: 'auto' }} className='hidden-scrollbar'>
                                <Document
                                    file={file.file_url}
                                    onLoadSuccess={() => console.log('PDF loaded successfully!')}
                                    onError={(error) => console.error('Error while loading PDF:', error)}
                                >
                                    <Page pageNumber={1} />
                                </Document>
                            </div>
                            { showEstimate && <FileTokens tokens={file.tokens * 10} /> }
                        </>
                    );
                case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                    return (
                        <>
                            <FileNameDisplay />
                            <div key={file.id}>
                                <iframe src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(file.file_url)}`} width="100%" height="600px" frameBorder="0"></iframe>
                            </div>
                            { showEstimate && <FileTokens tokens={file.tokens + 1000} /> }
                        </>
                    );
                case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                    return (
                        <>
                            <FileNameDisplay />
                            <div key={file.id}>
                                <iframe src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(file.file_url)}`} width="100%" height="600px" frameBorder="0"></iframe>
                            </div>
                            { showEstimate && <FileTokens tokens={file.tokens * 10} /> }
                        </>
                    );
                case "application/vnd.openxmlformats-officedocument.presentationml.presentation": // 添加对 PPTX 文件的支持
                    return (
                        <>
                            <FileNameDisplay />
                            <div key={file.id}>
                                <iframe src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(file.file_url)}`} width="100%" height="600px" frameBorder="0"></iframe>
                            </div>
                            { showEstimate && <FileTokens tokens={file.tokens * 8} /> }
                        </>
                    );
                case "application/vnd.ms-outlook": // 对 MSG 文件的支持
                    return (
                        <>
                            <FileNameDisplay />
                            <div key={file.id}>
                                <p className='mb-0'>MSG files cannot be previewed directly in the browser. Please download the file to view.</p>
                                <p>MSG 文件无法直接在浏览器中预览。请下载文件查看。</p>
                                <a href={file.file_url} download>Download MSG File</a>
                            </div>
                            { showEstimate && <FileTokens tokens={file.tokens * 20} /> }
                        </>
                    );
                case "text/plain":
                    return (
                        <>
                            <FileNameDisplay />
                            <div key={file.id}>
                                <iframe src={blobUrls[file.file_url]} width="100%" height="600px" frameBorder="0"></iframe>
                            </div>
                            { showEstimate && <FileTokens tokens={file.tokens + 100}/> }
                        </>
                    );
                default:
                    return (
                        <>
                            <FileNameDisplay />
                            <div key={file.id}>                            
                                <div>Unsupported file type</div>
                            </div>
                            { showEstimate && <FileTokens /> }
                        </>
                    );
            };
        });
    } catch {

    };    
};

export default ContentByFileType;
