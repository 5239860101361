import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Card, Button } from 'react-bootstrap';
import { baseUrl_api } from './config.jsx';
// import CheckInAdv from './modal_checkin_adv.jsx';
import Adv from './advertisement_checkin';


// 单独的卡片组件
const CardComponent = React.memo(({ date, day, text, signed }) => {
    return (
        <Card
            className={`${signed ? 'check-in-card-checked' : 'check-in-card'} border-0`}
            data-day={day}
            title={date}
        >
            <Card.Body
                className="check-in-card-body d-flex flex-column justify-content-center align-items-center p-0"
            >
                <Card.Text className={`${signed ? 'text-success': 'text-secondary'} check-in-font-text`}>
                    {text}
                </Card.Text>
            </Card.Body>
        </Card>
    )
});



function CheckIn({ uid, show, onHide }) {
    const [cardsDay, setCardsDay] = useState([]);
    const [nextDelta, setNextDelta] = useState(5);
    // const [showAdv, setShowAdv] = useState(false);
    // const [btnDisabled, setBtnDisabled] = useState(true);
    const [signed, setSigned] = useState(null);
    const [countdown, setCountdown] = useState(5);
    const [allowClose, setAllowClose] = useState(false);

    
    function getSimpleUserLocaleDate() {
        // 省略区域设置参数，让浏览器自动使用用户的本地设置
        return new Date().toLocaleDateString();
    }

    // 使用
    const date = getSimpleUserLocaleDate();    

    const loadCheckIn = async () => {
        try {
            const today = new Date();
            const localDate = today.getFullYear() + '-' + 
                  ('0' + (today.getMonth() + 1)).slice(-2) + '-' + 
                  ('0' + today.getDate()).slice(-2);
                  
            const response = await fetch(baseUrl_api + '/getcheckin', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                credentials: 'include',
                body: JSON.stringify({ uid: uid, date: date }),
            });
            
            const data = await response.json();
            
            if (data.code === 200) {
                 // 检查是否有匹配的日期
                const isMatched = data.message.value.some(item => item.checkdate === localDate);

                if(isMatched) {
                    setSigned(true);
                } else {
                    setSigned(false);
                };

                setNextDelta(data.message.nextDelta);
                setCardsDay(data.message.value);  
                // setBtnDisabled(false);
            } else {
                setNextDelta(5);
                setCardsDay([]); 
                // setBtnDisabled(false);
            };
        } catch (error) {
            console.error('Error:', error);
        };
    };

    useEffect(() => {
        if(uid) {
            loadCheckIn();
        };
        
        // if (show) {
        //     if (signed) {
        //         setCountdown(0);
        //     } else {
        //         setCountdown(5);
        //     };
            
        //     setAllowClose(false);
        //     const timer = setInterval(() => {
        //         setCountdown((prevCountdown) => {
        //             if (prevCountdown <= 1) {
        //                 setAllowClose(true);
        //             }
        //             return prevCountdown - 1;
        //         });
        //     }, 1000);
        //     return () => clearInterval(timer);
        // };
    }, [show]);

    useEffect(() => {
        if (show) {
            if (signed) {
                setCountdown(0);
            } else {
                setCountdown(5);
            };
            
            setAllowClose(false);
            const timer = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown <= 1) {
                        setAllowClose(true);
                    }
                    return prevCountdown - 1;
                });
            }, 1000);
            return () => clearInterval(timer);
        };
    }, [signed])

    const handleClose = () => {
        if(allowClose) {
            onHide();
            onAdvHide();
        };
    };

    const onAdvHide = async () => {
        const iDelta = nextDelta;
        const response = await fetch(baseUrl_api + '/addcheckin', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', },
            credentials: 'include',
            body: JSON.stringify({ uid: uid, date: date, iDelta: iDelta }),
        });

        // loadCheckIn();
    };

    // const handleCheckIn = () => {
    //     setShowAdv(true);
    // };
    

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                centered
                keyboard={false}
                backdrop={countdown > 0 ? "static" : true}
                size='lg'
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-success">Get Free Tokens</Modal.Title>
                </Modal.Header>

                <Modal.Body className="justify-content-center">
                    <div className="d-flex justify-content-center align-items-center">
                        {cardsDay.map((card, index) => (
                            <CardComponent
                                key={index}
                                date={card.checkdate}
                                day={card.sort}
                                text={card.text} // 示例：已签到的天数奖励20，未签到的奖励5
                                signed={card.signed}
                            />
                        ))}
                    </div>                    
                    <Adv advSlot="8900857602" />
                    <Adv advSlot="1237990008" />
                </Modal.Body>
                
                <Modal.Footer>
                    <div className="container text-center py-1">
                        <Button
                            variant="outline-success"
                            className="btn-outline-secondary rounded-3 btn-inline"
                            onClick={handleClose}
                            disabled={countdown > 0}
                        >
                            {countdown > 0 ? `${countdown}s` : `${ signed ? 'Checked' : 'Check In'}`}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

            {/* <CheckInAdv show={showAdv} onHide={onAdvHide} isSigned={isSigned} /> */}
        </>
    );
}

export default CheckIn;
