import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Badge, Form } from 'react-bootstrap';

function About({ show, onHide }) {
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title className="text-success">Welcome to Follow</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Label className="ms-1 mt-3 d-flex justify-content-center align-items-center">
                    <h4 className="mb-0 d-flex justify-content-center align-items-center">
                        <Badge className="rounded-pill bg-warning me-1">
                            <span className="mb-2">AI</span>
                        </Badge>
                        思维车间
                    </h4>
                </Form.Label>
                <img src='/qrcode_for_gh.png' alt="gh" className="img-fluid mx-auto d-block" />
            
                <Form.Label className="mt-3 d-flex justify-content-center"> </Form.Label>                   
                <Form.Label className="mt-0 mb-3 d-flex justify-content-center">
                    <a href="mailto:support@aimindwork.com" className="text-success">Support Mail</a>
                </Form.Label>

                <Form.Label className="mt-0 mb-5 d-flex justify-content-center">
                    <a href="https://work.weixin.qq.com/kfid/kfc331cdaf77543040f" className="text-success">Support WeChat</a>
                </Form.Label>
                
            </Modal.Body>
            {/* <Modal.Footer>
                <Button variant="outlien-success" onClick={onHide}>
                    ok
                </Button>
            </Modal.Footer> */}
        </Modal>
    );
}

export default About;
