// ImageItem.jsx
import React, { useState } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';

const ImageActions = ({ fileIndex, selectedButtonId, setSelectedForFile, setButtonClick }) => {
    const [selected, setSelected] = useState(selectedButtonId || 'graph');
    const [preSelected, setPreSelected] = useState(selectedButtonId || 'graph');

    const handleMouseEnter = () => {
        if (selected !== "all") {
            setPreSelected(selected);
        }
        setSelected('all');
    };

    const handleMouseLeave = () => setSelected(preSelected);

    const handleClick = (id) => () => {
        setSelected(id);
        setPreSelected(id);
        setSelectedForFile(fileIndex, id);

        if(id === "cref") {
            setButtonClick(true);
        } else {
            setButtonClick(false);
        };
    };

    return (        
        <ButtonGroup
            className="chat-mj-image-expand-button-actions"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}                
        >
            <Button
                    variant="secondary" id="cref"
                    className="border-0 m-0 p-0 image-mj-icon"
                    hidden={selected !== 'cref' && selected !== 'all'}
                    onClick={handleClick('cref')}
                >
                    <i className="bi bi-person-fill"></i>
                </Button>

                <Button variant="secondary" id="sref"
                    className="border-0 m-0 p-0 image-mj-icon"
                    hidden={selected !== 'sref' && selected !== 'all'}
                    onClick={handleClick('sref')}
                >
                    <i className="bi bi-paperclip icon-45"></i>
                </Button>

                <Button
                    variant="secondary" id="graph"
                    className=" border-0 m-0 p-0 image-mj-icon"
                    hidden={selected !== 'graph' && selected !== 'all'}
                    onClick={handleClick('graph')}
                >
                    <i className="bi bi-image"></i>
                </Button>
        </ButtonGroup>
    );
};

const ImageItem = ({ file, index, chatClassName, selectedButtons, setSelectedForFile, setButtonClick, onDelete }) => {
    return (
        <>
            <div
                key={`Slide ${index}`}
                className="chat-mj-image-expand-button-upload"
                style={{ backgroundImage: `url(${file.thumbnail_url})` }}                
            >
                <div className="chat-mj-image-expand-button-container"> {/* 新增的内部容器 */}
                    <Button
                        variant="outline-secondary"
                        className="chat-mj-image-expand-button-delete justify-content-center align-items-center"
                        onClick={onDelete}
                    >
                        <i className="bi bi-dash fs-4"></i>
                    </Button>

                    {chatClassName.toLowerCase() === "midjourney" && (
                        <ImageActions
                            fileIndex={index}
                            selectedButtonId={selectedButtons[index]}
                            setSelectedForFile={setSelectedForFile}
                            setButtonClick={setButtonClick}
                            onDelete={onDelete}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default ImageItem;
