import React, { useState, useEffect, } from 'react';
import { Modal, Button, Form, Col, Row, } from 'react-bootstrap';
import { baseUrl_api, } from "./config.jsx";

function ShowMJParam ({ show, onHide, onSetting, param }) {
    const [styleItems, setStyleItems] = useState("");
    const [isActive, setIsActive] = useState(true); // 默认开启
    const [disabled, setDisabled] = useState(true);
    const [style, setStyle] = useState("");
    const [steps, setSteps] = useState(50);
    const [stepsClassName, setStepsClassName] = useState("");
    const [strength, setStrength] = useState(30)
    const [strengthClassName, setStrengthClassName] = useState("");
    const [scale, setScale] = useState(5);
    // 设置默认值为0到2147483647之间的随机整数
    const [seed, setSeed] = useState(Math.floor(Math.random() * 2147483647));
    const [prompt, setPrompt] = useState("{prompt}");
    const [negative, setNegative] = useState("");
    const [code, setCode] = useState("");
    const [model, setModel] = useState("A");
    
    useEffect(() => {
        if (strength >= 15 && strength <= 24) {
            setStrengthClassName("badge bg-secondary-subtle border border-secondary-subtle text-secondary-emphasis rounded-pill")
        } else if (strength > 24 && strength <= 33) {
            setStrengthClassName("badge bg-success-subtle border border-success-subtle text-success-emphasis rounded-pill")
        } else if (strength > 33 && strength <= 42) {
            setStrengthClassName("badge bg-warning-subtle border border-warning-subtle text-warning-emphasis rounded-pill")
        } else {
            setStrengthClassName("badge bg-danger-subtle border border-danger-subtle text-danger-emphasis rounded-pill")
        };
    }, [strength])

    useEffect(() => {
        if (steps >= 20 && steps <= 40) {
            setStepsClassName("badge bg-secondary-subtle border border-secondary-subtle text-secondary-emphasis rounded-pill")
        } else if (steps > 41 && steps <= 60) {
            setStepsClassName("badge bg-success-subtle border border-success-subtle text-success-emphasis rounded-pill")
        } else if (steps > 61 && steps <= 80) {
            setStepsClassName("badge bg-warning-subtle border border-warning-subtle text-warning-emphasis rounded-pill")
        } else {
            setStepsClassName("badge bg-danger-subtle border border-danger-subtle text-danger-emphasis rounded-pill")
        };
    }, [steps])

    const onSubmit = () => {
        // 在这里，你可以访问所有的状态，它们包含了用户的输入
        const formData = {
            num_steps: steps,
            style_strength_ratio: strength,
            style: style,
            prompt: prompt,
            negative_prompt: negative,
            seed: seed,
            steps: steps,
            code: code,
            model: model,
            active: isActive,
        };
    
        onSetting(formData);
    
        // 如果你需要关闭模态框，可以调用 onHide
        onHide();
      };   

    const handleStrengthChange = (e) => {
        const value = e.target.value;
        // 将字符串转换为整数
        const intValue = parseInt(value, 10);

        setStrength(intValue);
    };

    const handleStepsChange = (e) => {
        const value = e.target.value;
        // 将字符串转换为整数
        const intValue = parseInt(value, 10);

        setSteps(intValue);
    };

    const handleNegativeChange = (e) => {
        const value = e.target.value;

        setNegative(value);
    };

    const handleSeedChange = (e) => {
        const value = e.target.value;
        // 将字符串转换为整数
        const intValue = parseInt(value, 10);

        setSeed(intValue);
    };

    const handleSeedTextChange = (e) => {
        let value = e.target.value;
        // 正则表达式，用于匹配只包含数字的字符串
        const numericPattern = /^\d*$/;

        if(value === "") {
            value = 0;
        };

        const intValue = parseInt(value, 10);

        // 检查输入值是否只包含数字，并且没有超过最大值
        if (numericPattern.test(value) && (value === '' || parseInt(value, 10) <= 2147483647)) {
            setSeed(intValue);
        };
    };

    const loadParam = async () => {
        try {
            const response = await fetch(baseUrl_api + '/getparam', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                credentials: 'include',
                body: JSON.stringify({ type: "photomaker" }),
            });

            const data = await response.json();
            
            if (data.code === 200) {
                // 从 JSON 数据中找到 type 为 'style' 的部分
                const styleOptions = data.message.find(item => item.type === 'style').text;
                
                setStyleItems(styleOptions);
                
                setDisabled(false);
            } else {
                onHide();
            };
            
        } catch (error) {
            console.error('Error:', error);
        };
    };

    // 处理下拉选项变化的函数
    const handleStyleChange = (event) => {
        // 解析JSON字符串
        if (event.target.value.trim() !== "" ) {            
            const styleJson = JSON.parse(event.target.value);

            const selectedOption = styleItems.find(option => option.value === event.target.value);
            const code = selectedOption ? selectedOption.code : '';
            
            setPrompt(styleJson.prompt);
            setNegative(styleJson.negative_prompt);
            setModel(styleJson.model ? styleJson.model : model);
            setCode(code);

            if (styleJson.seed) {
                const minSeed = Math.max(styleJson.seed - 1000, 0);
                const maxSeed = Math.min(styleJson.seed + 1000, 2147483647);
                const randomSeed = Math.floor(Math.random() * (maxSeed - minSeed + 1)) + minSeed;
                setSeed(randomSeed);
            };
        } else {
            setPrompt("{prompt}");
            setNegative("");
            setModel("A");
            setSeed(Math.floor(Math.random() * 2147483647));
            setCode("")
        };
        setStyle(event.target.value);
    };

    useEffect (() => {
        if (param) {
            const isEmpty = Object.keys(param).length === 0;
            if(!isEmpty) {
                setSteps(param.steps);
                setStrength(param.style_strength_ratio);
                setSeed(param.seed);
                setStyle(param.style);
                setPrompt(param.prompt);
                setNegative(param.negative_prompt);
                setModel(param.model);
                setIsActive(param.active);
                setCode(param.code);
            };
        };
        
        loadParam();
    }, [])

    const handleSwitchChange = (event) => {
        setIsActive(event.target.checked); // 更新状态为 switch 的当前值
    };

    return (
        <Modal show={show} onHide={onHide} centered size="lg">            
            { styleItems.length > 0 && (
                <>
                <Modal.Header closeButton>
                    <Modal.Title className="text-success">PhotoMaker Advanced</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Row className="chat-siderbar-items-edit-modal-row mt-3">
                                <Form.Label className="ms-1">Steps</Form.Label>
                                <Col xs={9} className="text-start">
                                    <Form.Control
                                        type="range"
                                        className="range-temperature"
                                        id="steps"
                                        min={20}
                                        max={100}
                                        step={1}
                                        value={steps}
                                        onChange={handleStepsChange}
                                        disabled={disabled}
                                    />
                                </Col>
                                <Col xs={3} className="text-start d-flex align-items-center">
                                    <Form.Label className={`text-start ms-1 mt-1 ${stepsClassName}`}>{steps}</Form.Label>                            
                                </Col>
                            </Row>

                            <Row className="chat-siderbar-items-edit-modal-row mt-3">
                                <Form.Label className="ms-1">Strength</Form.Label>
                                <Col xs={9} className="text-start">
                                    <Form.Control
                                        type="range"
                                        className="range-temperature"
                                        id="strength"
                                        min={15}
                                        max={50}
                                        step={1}
                                        value={strength}
                                        onChange={handleStrengthChange}
                                        disabled={disabled}
                                    />
                                </Col>
                                <Col xs={3} className="text-start d-flex align-items-center">
                                    <Form.Label className={`text-start ms-1 mt-1 ${strengthClassName}`}>{strength}</Form.Label>                            
                                </Col>
                            </Row>

                            <Row className="chat-siderbar-items-edit-modal-row mt-3">
                                <Form.Label className="ms-1">Seed</Form.Label>
                                <Col xs={9} className="text-start d-flex align-items-center mt-1">
                                    <Form.Control
                                        type="range"
                                        className="range-temperature w-100"
                                        id="seed"
                                        min={0}
                                        max={2147483647}
                                        step={1}
                                        value={seed}
                                        onChange={handleSeedChange}
                                        disabled={disabled}
                                    />
                                </Col>
                                <Col xs={3} className="text-start d-flex align-items-center">
                                    <Form.Control
                                        type="text"
                                        value={seed}
                                        onChange={handleSeedTextChange}
                                        className={`text-start ms-1 mt-1 range-temperature`}
                                    />
                                </Col>
                            </Row>

                            <Row className="chat-siderbar-items-edit-modal-row mt-3">
                                <Form.Label className="ms-1">Style</Form.Label>
                                <Col xs={9} className="text-start">
                                    <Form.Control
                                        as="select" value={style}
                                        className="range-temperature"
                                        onChange={handleStyleChange}
                                        disabled={disabled}
                                    >
                                        {styleItems.map((option) => (
                                            <option key={option.code} value={option.value}>
                                                {option.code} {option.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className="chat-siderbar-items-edit-modal-row mt-4">
                                <Form.Label className="ms-1">Negative Prompt</Form.Label>
                                <Col xs={9} className="text-start">
                                    <Form.Control
                                        as="textarea" value={negative}                                        
                                        className="form-control chat-main-dialog-textarea"
                                        onChange={handleNegativeChange} 
                                        disabled={disabled}
                                        rows={7}
                                    />
                                </Col>
                            </Row>

                            <Row className="chat-siderbar-items-edit-modal-row mt-3">
                                <Col xs="auto" className="text-start">
                                    <Form.Label className="ms-1 mt-3">Enable</Form.Label>
                                </Col>
                                <Col xs="auto" className="text-start">
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        className="ms-1 mt-3"
                                        checked={isActive}
                                        onChange={handleSwitchChange}
                                    />
                                </Col>
                            </Row>
                            
                        </Form.Group>
                    </Form>
                </Modal.Body>
                
                <Modal.Footer className="flex-nowrap p-0 mt-3">
                    <Button
                        variant="link-success"
                        className="btn-lg fs-6 text-decoration-none col-6 py-3 m-0 border-0 rounded-0 border-end"
                        onClick={onSubmit}
                    >
                        <strong className="link-success">Submit</strong>
                    </Button>
                    <Button
                        variant="link-success"
                        className="btn-lg fs-6 text-decoration-none col-6 py-3 m-0 border-0 rounded-0"
                        onClick={onHide}
                    >
                        <div className='link-success'>Cancel</div>
                    </Button>
                </Modal.Footer>
                </>
            )}
        </Modal>
    );
};

export default ShowMJParam;
