import React from 'react';
import { Modal, Button } from 'react-bootstrap';


function ModalDelete({setShowDeleteChat, setIsDeleteChat}) {

  const handleYes = () => {
    setShowDeleteChat(false);
    setIsDeleteChat(true);
  };

  const handleNo = () => {
    setShowDeleteChat(false);
    setIsDeleteChat(false);
  };

  return (
    <Modal show={true} onHide={() => setShowDeleteChat(false)} centered>      
        <Modal.Body className="rounded-3 shadow">
          <Modal.Body className="p-4 text-center">
            <h5 className="mb-3 link-success">Delete Chat?</h5>
            <p className="mb-3">This operation will delete the current chat and all records. Do you wish to proceed?</p>
          </Modal.Body>
          <Modal.Footer className="flex-nowrap p-0">
            <Button
              variant="link-success"
              className="btn-lg fs-6 text-decoration-none col-6 py-3 m-0 border-0 rounded-0 border-end"              
              onClick={handleYes}
            >
              <strong className="link-success">Yes</strong>
            </Button>
            <Button
              variant="link-success"
              className="btn-lg fs-6 text-decoration-none col-6 py-3 m-0 border-0 rounded-0"
              // data-bs-dismiss="modal"              
              onClick={handleNo}
            >
              <div className='link-success'>No</div>
            </Button>
          </Modal.Footer>
        </Modal.Body>      
    </Modal>
  );
};

export default ModalDelete;