import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal, Row, Col, OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import FileUpload from './file_load.jsx';
import ImageActions from './chat_main_mj_image_expand_actions.jsx';
import { toolMidjourney, toolPhotoMaker } from './config.jsx';
import FileDrag from './file_drag.jsx';

function MJImageExpand({ uid, show, onHide, setSendMessage, chatClassName, price = 5, maxFiles = 6 }) {
    const [textValue, setTextValue] = useState('');
    const textareaRef = useRef(null);
    const [cwValue, setcwValue] = useState(50);
    const [showCW, setShowCW] = useState(false);
    const fileUploadRef = useRef();
    const [uploadedImages, setUploadedImages] = useState([]); // 新增状态来存储已上传的图片
    const [selectedButtons, setSelectedButtons] = useState({});
    const [textareaClassName, setTextareaClassName] = useState("form-control border-1 mt-0 chat-main-message-body-container-textarea hidden-scrollbar");
    const [selectedIndex, setSelectedIndex] = useState(null);

    useEffect(() => {
        if (show) {
            const textarea = textareaRef.current;
            textarea.focus();
        };
    }, [show]);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setTextValue(newValue);
    };

    const handleCW = (e) => {
        setcwValue(e.target.value);
        
        // const cwValue = e.target.value;
        // // 创建uploadedImages的副本并更新
        // const updatedImages = uploadedImages.map((image, index) => {
        //     if (index === selectedIndex) {
        //         return { ...image, cw: cwValue.toString() }; // 使用对象展开运算符添加或更新cw属性
        //     }
        //     return image;
        // });

        // // 使用setUploadedImages来更新状态
        // setUploadedImages(updatedImages);
    };

    const handleFileUpload = () => {
        fileUploadRef.current.click();
    };

    const handleFileDrag = (text) => {
        if (text.code  === 200) {
            const textFiles = text.message.files;
            
            if (typeof textFiles === "object" && textFiles.length > 0) {
                // 假设 textFiles 是一个包含文件信息的数组
                setUploadedImages(prevImages => [...prevImages, ...textFiles.map(file => {
                    // 假设 file 是从某个API响应中获取的对象，而不是一个Blob/File对象
                    if (file.image_url) { // 检查是否有 image_url 属性
                        return {
                            image_url: file.image_url,
                            thumbnail_url: file.thumbnail_url // 直接使用图片URL
                        };
                    } else {
                        console.error('Object does not contain an image_url:', file);
                        return null; // 或者根据需要处理无效的对象
                    }
                }).filter(file => file !== null)]); // 过滤掉无效的条目
            };
        };
    };

    // 更新特定文件的选中按钮
    const setSelectedForFile = (fileIndex, selectedButtonId) => {
        setSelectedIndex(fileIndex);

        // 创建uploadedImages的副本并根据条件更新
        const updatedImages = uploadedImages.map((image, index) => {
            if (index === fileIndex) {
                // 根据条件更新cw值
                const cwNewValue = cwValue ? cwValue : 50;
                // 更新cw值（如果需要）和添加action属性
                return { ...image,
                    cw: selectedButtonId === "cref" ? cwNewValue : "",
                    action: selectedButtonId === "graph" ? "" : "--" + selectedButtonId
                };
            }
            return image;
        });

        // 使用setUploadedImages来更新状态
        setUploadedImages(updatedImages);

        // // 更新cwValue状态（如果需要）
        // if(updatedImages[fileIndex].cw) {
        //     setcwValue(updatedImages[fileIndex].cw);
        // } else {
        //     setcwValue(50);
        // };

        // 更新cwValue状态（如果需要）
        if(cwValue) {
            setcwValue(cwValue);
        } else {
            setcwValue(50);
        };

        setSelectedButtons(prev => ({ ...prev, [fileIndex]: selectedButtonId }));
    };

    const setButtonClick = (show) => {
        // console.log(105, show);
        // setShowCW(show);
    };

    useEffect(() => {
        // 直接检查selectedButtons对象的值中是否包含'cref'
        const containsCref = Object.values(selectedButtons).includes('cref');
        
        setShowCW(containsCref); // 直接根据containsCref的值设置setShowCW
    }, [selectedButtons]);
    

    // 修改 handleOnMJExpandFileLoadDone 以处理文件上传后的逻辑
    const handleOnMJExpandFileLoadDone = (text) => {
        const textFiles = text.files;
        
        if (typeof textFiles === "object" && textFiles.length > 0) {
            // 假设 textFiles 是一个包含文件信息的数组
            setUploadedImages(prevImages => [...prevImages, ...textFiles.map(file => {
                // 假设 file 是从某个API响应中获取的对象，而不是一个Blob/File对象
                if (file.image_url) { // 检查是否有 image_url 属性
                    return {
                        image_url: file.image_url,
                        thumbnail_url: file.thumbnail_url // 直接使用图片URL
                    };
                } else {
                    console.error('Object does not contain an image_url:', file);
                    return null; // 或者根据需要处理无效的对象
                }
            }).filter(file => file !== null)]); // 过滤掉无效的条目
        };
    };

    const handleDeleteImage = (imageIndex) => {
        setUploadedImages(prevImages => prevImages.filter((image, index) => index !== imageIndex));
    };    
      

    // 渲染已上传图片和上传按钮
    const renderUploadButtons = () => {
        return (
            <>
                { uploadedImages.map((image, index) => (
                    <ImageActions
                        key={index} // 建议使用更独特的key，如果image对象有唯一id，使用那个
                        file={image} // 将image对象转换为字符串
                        index={index}
                        chatClassName={chatClassName}
                        selectedButtons={selectedButtons}
                        setSelectedForFile={setSelectedForFile}
                        setButtonClick={setButtonClick}
                        handleDeleteImage={handleDeleteImage}
                        onDelete={() => handleDeleteImage(index)}                        
                    />
                ))}
                
                { uploadedImages.length < maxFiles && (
                    <Button
                        variant="outline-secondary"
                        className="chat-mj-image-expand-button-upload"
                        onClick={handleFileUpload}
                    >
                        <span className="button-text">
                            <i className="bi-lg bi-plus-circle-dotted fs-1"></i>
                        </span>
                    </Button>
                )}
            </>
        );
    };

    const handleSendClick = () =>{
        if (textValue && textValue.trim() !== "" && uploadedImages.length > 0) {
            setTextareaClassName("form-control border-1 mt-0 chat-main-message-body-container-textarea hidden-scrollbar");

            let code;

            if (toolMidjourney.includes(chatClassName)) {
                code = "Midjourney_Graph";
            } else if(toolPhotoMaker.includes(chatClassName)) {
                code = "PhotoMaker"
            } else {
                code = "vision";
            };

            // 使用.map()创建一个新数组，其中包含修改后的元素
            const updatedImages = uploadedImages.map(item => {
                // 如果action为"--cref"，则修改cw值
                if (item.action === "--cref") {
                    return {...item, cw: cwValue}; // 返回一个新对象，其中cw属性被设置为cwValue的值
                }
                return item; // 对于其他情况，返回原始元素
            });
            
            const text = {
                code: code,
                message: textValue.trim(),
                files: updatedImages,
            };

            const message = {
                chatType: code,
                classID: "",
                model: code,
                msgType: "text",
                text: text,
                deduct: uploadedImages.length
            };
            
            setSendMessage(message);
            setTextValue("");

            onHide();
        } else {
            setTextareaClassName("form-control border-1 mt-0 chat-main-message-body-container-textarea hidden-scrollbar input-unfinish")
        };
    };

    const handleKeyDown = (event) => {        
        if (event.key === 'Enter' && !textValue.trim()) {
            event.preventDefault();
        };

        if (((event.altKey || event.ctrlKey) && event.key === 'Enter') ||
            (event.altKey && (event.key === 'S' || event.key === 's'))) {
            handleSendClick();
            event.preventDefault();
        };
    };

    return (
        <Modal
            className="chat-main-message-body-file-container"
            show={show} onHide={onHide} size="lg" centered
            aria-labelledby="contained-modal-title-vcenter"
            onEntered={() => textareaRef.current && textareaRef.current.focus()}
        >
            <Modal.Body>
                <FileDrag uid={uid} onDrop={handleFileDrag} chatClassName={chatClassName} maxFiles={maxFiles}>
                    <div className="d-flex flex-wrap chat-photomaker-layout-draw mt-2">
                        {renderUploadButtons()}
                        <FileUpload
                            uid={uid}
                            ref={fileUploadRef}
                            fileType="image"
                            onFileSelect={handleOnMJExpandFileLoadDone}
                            chatClassName={chatClassName}
                            maxFiles={maxFiles - uploadedImages.length} // 更新最大文件数以限制上传数量
                            resize={true}
                            container="photomaker"
                        />
                    </div>
                </FileDrag>
                
                <div className="chat-main-message-body-file-container-body">
                    <Row className="mb-0 pr-3">
                        {/* 这里可以根据需要渲染其他内容 */}
                    </Row>
                    
                    <Row className="mt-0 ms-2 mr-2 mb-2" hidden={!showCW}>                        
                        <Form.Label className="ms-1">Similarity</Form.Label>
                        <Col xs={10} className="text-start">
                            <Form.Control
                                type="range"
                                className="range-temperature"
                                id="customRange"
                                min="0"
                                max="100"
                                step="1"
                                value={cwValue}
                                onChange={handleCW}                                
                            />
                        </Col>
                        <Col xs={2} className="text-start d-flex align-items-center">
                            <Form.Label className={`text-start ms-1 mt-1`}>{cwValue}</Form.Label>                            
                        </Col>
                    </Row>
                    
                    <Row className="m-2">
                        <p className="text-secondary font-italic ms-2">                    
                            {/* Deduct: {uploadedImages.length} files, -{price} tokens/file, -{uploadedImages.length * price} tokens total. */}
                            Deduct: -{price} tokens.
                        </p>
                        <Col xs={12} className="chat-main-dialog-container-col">
                            <textarea
                                ref={textareaRef}
                                className={textareaClassName}
                                id="user-dialog-file"
                                value={textValue}
                                rows="5"
                                placeholder="Type your prompt..."
                                onChange={handleChange}
                                onKeyDown={handleKeyDown} // 如果需要，可以添加键盘事件处理
                            />
                        </Col>
                        <Col className="chat-main-dialog-container-col-right">
                            <OverlayTrigger
                                placement="right"
                                overlay={<Tooltip id="button-tooltip">Alt + S</Tooltip>}                        
                            >
                                <Button variant="outline-secondary"
                                    className="button-send-file border-0"
                                    onClick={handleSendClick}
                                >
                                    <i className="bi bi-send icon-enlarged"></i>
                                </Button>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    );
}
  
export default MJImageExpand;
