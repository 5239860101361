import React, { useEffect, useState, } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { imageTypes, } from './config.jsx';
import MarkdownRender from './markdow_render.jsx';
import ChatMainMessageBodyCardReceiverImage from './chat_main_message_body_card_receiver_image.jsx';
import ChatMainMessageBodyCardReceiverArray from './chat_main_message_body_card_receiver_array.jsx';

function ChatMainMessageBodyCardReceiver ({ 
    time, message, messageType, onLoaded, setImageOperate
}) {
    const [parsedMessage, setParsedMessage] = useState(null);
    const [showImage, setShowImage] = useState(false);
    const [showArray, setShowArray] = useState(false);
    const [cardBodyClass, setCardBodyClass] = useState("card-receiver bg-body-tertiary text-secondary-emphasist")
    
    const isImage = () => {
        if (imageTypes.includes(messageType)) {
            setShowImage(true);
            setCardBodyClass("card-receiver bg-body-tertiary text-secondary-emphasist")          
            return true;
        } else if (messageType === 'error') {
            setShowImage(false);
            setCardBodyClass("card-receiver bg-danger-subtle border text-danger-emphasis");
            return false;
        } else if (messageType === 'warning') {
            setShowImage(false);
            setCardBodyClass("card-receiver bg-warning-subtle border text-warning-emphasis");
            return false;
        } else {
            setShowImage(false);
            setCardBodyClass("card-receiver bg-body-tertiary text-secondary-emphasist")
            return false;
        };
    };

    const isArray = () => {
        if (messageType.toLowerCase() === "array") {
            setShowArray(true);
            return true;
        } else {
            setShowArray(false);
            return false;
        };
    };


    useEffect(() => {
        setShowImage(false);
        setShowArray(false);

        if (isImage()) {
            try {
                const messageObject = JSON.parse(message);
                setParsedMessage(messageObject);
            } catch (error) {
                console.error('Error parsing message:', error);
                // Handle parsing error, maybe set a default image or a placeholder
            };
        } else if (isArray()) {
            try {
                const messageObject = JSON.parse(message);
                setParsedMessage(messageObject);
            } catch (error) {
                console.error('Error parsing message:', error);
                // Handle parsing error, maybe set a default image or a placeholder
            };
        }

        // 通知父组件加载完成
        if (onLoaded) {
            onLoaded();
        };
    }, [message, messageType]); 

    return (
        <Card className="mb-3 no-border-card card-receiver">
            <Row className="g-0">
                <Col xs={12} md={12}>
                    <Card.Body className={cardBodyClass}>
                        <Card.Text>
                            { showImage && parsedMessage ? (
                                <ChatMainMessageBodyCardReceiverImage
                                    image={parsedMessage}
                                    setImageOperate={setImageOperate}
                                />
                            ) : showArray && parsedMessage ? (
                                // 这里替换为你的第三个组件，假设它叫做 ChatMainMessageBodyCardReceiverArray
                                <ChatMainMessageBodyCardReceiverArray>
                                    { typeof message === 'object' ? JSON.stringify(parsedMessage) : message}
                                </ChatMainMessageBodyCardReceiverArray>
                            ) : (
                                <MarkdownRender>
                                    { typeof message === 'object' ? JSON.stringify(message) : message}
                                </MarkdownRender>
                            )}
                        </Card.Text>
                    </Card.Body>
                    <div className='d-flex justify-content-between text-secondary'>                        
                        <small className="chat-card-footer">{time}</small>
                    </div>                    
                </Col>
            </Row>
        </Card>
    );
};

export default ChatMainMessageBodyCardReceiver;