import React, { useState, useEffect, } from 'react';
import { Container, Row, Col, Nav, Button, NavDropdown, Tooltip, OverlayTrigger } from 'react-bootstrap';
import './message.css'
import About from './modal_about.jsx';
import CheckIn from './modal_checkin.jsx';
// import CheckIn from './wechat_checkin.jsx';

function Header({ loginInfo, onHeaderItemSelected, onCheckLogin }) {

    const [isLogin, setIsLogin] = useState(false);  // 是否已登录
    const [showAbout, setShowAbout] = useState(false);  //显示 About 页面
    const [showCheckIn, setShowCheckIn] = useState(false);  //显示 check-in 页面

    useEffect(() => {
        if(loginInfo) {
            setIsLogin(loginInfo.islogin);
        }
        
    }, [loginInfo]);

    const onAppsClick = ((event) =>{
        switch (event.toLowerCase()) {
            case "meeting":
                window.open('https://www.aimindwork.com/?e-landing-page=meetingmate', '_blank');
                break;
            default:
                break;
        };
    })

    const onAboutClick = ((event) =>{
        switch (event.toLowerCase()) {
            case "about":
                setShowAbout(true);
                setShowCheckIn(false);
                break;
            case "checkin":
                setShowAbout(false);
                setShowCheckIn(true);
                break;
            default:
                break;
        };
    })

    const renderTooltipAdv = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Check in to get tokens<br />
            签到获得 Token
        </Tooltip>
    );

    return (
        <>
            <header className="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
                <Container className="main-header"> 
                    <Row>
                        <Col className="d-flex align-items-center mb-3 mb-md-0 me-md-auto d-none d-lg-block">
                            {/* <a href="/" className="link-body-emphasis text-decoration-none">
                                <svg className="bi me-2" width="40" height="32">
                                <use xlinkHref="#bootstrap"></use>
                                </svg>
                            </a> */}
                        </Col>
                        <Col className="d-flex justify-content-end align-items-center">
                            <Nav className="nav-pills-end">                            
                                <Nav.Item className="align-items-center">
                                    <Nav.Link
                                        className="custom-nav-link nav-link text-success"
                                        onClick={() => onHeaderItemSelected("Home")}                                    
                                    >
                                        Home
                                    </Nav.Link>
                                </Nav.Item>
                                { isLogin && (
                                    <>
                                        <Nav.Item className="align-items-center">
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={renderTooltipAdv}
                                            >
                                                <Nav.Link
                                                    className="custom-nav-link text-success"
                                                    onClick={() => onAboutClick("CheckIn")}
                                                >
                                                    Check-In
                                                </Nav.Link>
                                            </OverlayTrigger>
                                        </Nav.Item> 

                                        <Nav.Item className="align-items-center">
                                            <Nav.Link
                                                className="custom-nav-link text-success"                                
                                                onClick={() => onHeaderItemSelected("Pricing")}
                                            >
                                                Pricing
                                            </Nav.Link>
                                        </Nav.Item> 

                                        <NavDropdown title={<span className="text-success">Apps</span>} id="nav-dropdown-apps" className="d-none d-md-block">
                                            <NavDropdown.Item
                                                onClick={() => onAppsClick("meeting")}
                                                className="border-0 text-success"
                                            >
                                                Meeting Mate
                                            </NavDropdown.Item>
                                        </NavDropdown>
                                    </>
                                )}
                                {/* <Nav.Item className="align-items-center">
                                    <Nav.Link className="custom-nav-link">About</Nav.Link>
                                </Nav.Item> */}

                                <Nav.Item className="align-items-center">
                                    <Nav.Link
                                        className="custom-nav-link nav-link text-success d-none d-md-block"
                                        onClick={() => onAboutClick("About")}
                                    >
                                        About
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Button
                                        variant="outline-success"
                                        className="border-0 icon-1-2x d-none d-md-block"
                                        onClick={onCheckLogin}
                                    >
                                        {isLogin ? (
                                            <i className="bi bi-person-check"></i>
                                        ) : (
                                            <i className="bi bi-person"></i>
                                        )}
                                    </Button>
                                </Nav.Item>
                            </Nav>
                        </Col>
                    </Row>
                </Container>
            </header>
            <About show={showAbout} onHide={() => setShowAbout(false)}></About>
            
            <CheckIn
                uid={loginInfo.uid}
                show={showCheckIn}
                onHide={() => setShowCheckIn(false)}
            />
        </>
    );
}

export default Header;

