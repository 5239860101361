import React from 'react';
import { Button, ListGroup, Col, } from 'react-bootstrap';

function ChatSidebarNewChat ({ 
    classID, 
    title = "N",
    className = "",
    displayName = "", 
    nickName = "", 
    describe = "",
    isSystem = true,
    disabled = true,
    handleChatClick,
    onDelete = () => {},    
    onEdit = () => {}
}) {    
    const isChatGPT = (displayName === "ChatGPT" || displayName === "Prompt助手");
    const chatTitleClassName = isChatGPT ? "mb-1 text-success font-bold" : "mb-1";
    
    // 处理点击事件的函数
    const handleClick = () => {
        // 创建一个新的对象，包含所有需要的属性
        const chatClass = {
            classID: classID,
            title: title,
            className: className,
            displayName: displayName,
            nickName: nickName,
            describe: describe,
            disabled: disabled,
        };

        // 调用父组件的 handleChatClick 函数，并传递 chatClass 对象
        handleChatClick(chatClass);
    };


    return (
        <ListGroup.Item 
            className="btn list-group-item list-group-item-action py-4 lh-sm border-bottom-only"
            onClick={(event) => {
                event.stopPropagation();
                handleClick(classID)
            }}
        >
            <div className="d-flex w-100 justify-content-between">
                <Col xs="7">
                    <div className={chatTitleClassName}>{displayName}</div>
                    <div className="col-10 mb-1 small text-secondary font-italic">{nickName}</div>
                </Col>
                <Col xs={2} className="d-flex chat-new-item-tools-bar">
                    { !isSystem  && (
                        <>
                        <small className="text-body-secondary">
                            <Button
                                variant="link-success"
                                className="mt-0 mb-0 mr-0 p-0 border-0"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onDelete(classID);
                                }}
                            >
                                <i className="bi bi-trash"></i>
                            </Button>
                        </small>
                        <small className="text-body-secondary">
                            <Button
                                variant="link-success"
                                className="mt-0 mb-0 mr-0 p-0 border-0"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onEdit(classID);
                                }}
                            >
                                <i className="bi bi-pencil-square"></i>
                            </Button>
                        </small>
                        </>
                    )}
                </Col>
            </div>
        </ListGroup.Item>        
    );
};

export default ChatSidebarNewChat;