import React, { useState, useEffect, } from 'react';
import { Row, Col, Button, } from 'react-bootstrap';
import { chatSpecial } from './config.jsx';
import ChatMain from './chat_main.jsx'
import ChatSidebar from './chat_sidebar.jsx'
import ChatIndex from './chat_index.jsx';
import PhotoMaker from './chat_photomaker.jsx';
import './chat.css';

function Chat ({uid}) {
    const [chatClass, setChatClass] = useState([]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleChatClick = async (updatedChatClass) => {
        setIsSidebarOpen(false);
        setChatClass(updatedChatClass);        
    };

    // useEffect(() => {
    //     // 确保 script 标签只被添加一次
    //     const existingScript = document.querySelector('script[src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"]');
        
    //     if (!existingScript) {
    //         const script = document.createElement('script');
    //         script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    //         script.async = true;
    //         script.crossOrigin = "anonymous";
    //         document.head.appendChild(script);

    //         script.onload = () => {
    //             // 确保 window.adsbygoogle 已经定义
    //             if (window.adsbygoogle) {
    //                 window.adsbygoogle = window.adsbygoogle || [];
    //                 window.adsbygoogle.push({});
    //             }
    //         };
    //     } else {
    //         // 如果 script 已经存在，直接初始化广告
    //         if (window.adsbygoogle && !window.adsbygoogle.loaded) {
    //             window.adsbygoogle = window.adsbygoogle || [];
    //             window.adsbygoogle.push({});
    //         };
    //     };
    // }, []);
    

    return (
        <>
            {/* <div className="advertisement-right">
                <ins className="adsbygoogle adsbygoogle-right"
                    // style={{ display: 'inline-block', width:'2100px', height:'128px' }}
                    data-ad-client="ca-pub-9568214720585557"
                    data-ad-slot="1314220088"
                    data-ad-format="auto"
                    data-full-width-responsive="true"
                />
            </div> */}

            <Button variant="outline-success" className="toggle-sidebar-button border-0 d-md-none d-lg-none" onClick={toggleSidebar}>
                {isSidebarOpen ? <i className="bi bi-chevron-right"></i> : <i className="bi bi-chevron-left"></i>}
            </Button>
        
            <Row className="d-flex flex-nowrap chat">
                <Col xs="auto" className={`chat-sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
                    <ChatSidebar uid={uid} handleChatClick={handleChatClick}/>
                </Col>

                { !chatClass.className && (
                    <Col className='chat-main'>
                        <ChatIndex uid={uid} chatClass={chatClass}></ChatIndex>
                    </Col>
                )}

                { chatClass.className && !chatSpecial.includes(chatClass.className) && (
                    <Col className='chat-main'>
                        <ChatMain uid={uid} chatClass={chatClass}></ChatMain>
                    </Col>
                )}

                { chatClass.className && chatClass.className === "PhotoMaker" && (
                    <Col className='chat-main'>
                        <PhotoMaker uid={uid} chatClass={chatClass}></PhotoMaker>
                    </Col>
                )}

            </Row>
        </>
    );
};

export default Chat;

