import React, { useState, useRef, useEffect } from 'react';
import './chat_main_dialog_header_tools_suno.css';
import { ButtonGroup, Button, } from 'react-bootstrap';

function ChatMainDialogHeaderToolsSuno({ onSetParaSuno, onShowSunoExpand }) {
    const [isChecked, setIsChecked] = useState(false);

    const handleToggle = () => {
        setIsChecked(!isChecked);
    };

    useEffect(() => {
        const sunoParam = JSON.stringify({ 'suno': { 'instrumental': isChecked} });
        const sunoObj = JSON.parse(sunoParam);
        if (onSetParaSuno) {
            onSetParaSuno(sunoObj); // 如果提供了 onChange 回调，调用它
        }
    }, [isChecked])


    return (
        <>
            <div className="d-flex align-items-center ms-3 mb-3">
                <label
                    className="switch"
                    title={`Instrumental music ${isChecked ? "off" : "on"}`} // 初始 Tooltip 文本
                >
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={isChecked}
                        onChange={handleToggle}
                    />
                    <span className="slider round"></span>
                </label>
            </div>

            <ButtonGroup className="mb-3 ms-3">
                <Button
                    variant='outline-secondary'
                    className='border-0 ps-2 pe-2 pt-1 pb-1 ms-2'
                    title="Generate by lyrics"
                    disabled={isChecked}
                    onClick={onShowSunoExpand}
                >
                    <i className="bi bi-music-note-list fs-5"></i>
                </Button>
            </ButtonGroup>
        </>
    );
};

export default ChatMainDialogHeaderToolsSuno;
