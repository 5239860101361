import React, { useState, useEffect } from 'react';
import ChatPhotoMakerHeader from './chat_photomaker_header.jsx';
import ChatPhotoMakerBodyDraw from './chat_photomaker_body_draw.jsx';
import ChatPhotoMakerBodyImages from './chat_photomaker_body_images.jsx';
// import FilesContainerShow from "./chat_main_files_container_show.jsx";
import { baseUrl_api, getImageUrlChatClassName, discordUpload, } from './config.jsx';
import { getFile } from './file_upload.jsx';
import ShowPhotoMakerUploadedModal from './modal_photomaker_uploaded';


function ChatPhotoMaker ({ uid, chatClass }) {
    const [selectedOption, setSelectedOption] = useState('draw');
    const [selectedGender, setSelectedGender] = useState('male');
    const [reload, setReload] = useState(false);
    const [files, setFiles] = useState([]);
    const [showFileDrag, setShowFileDrag] = useState(false);
    const [maxFiles, setMaxFiles] = useState(9);
    const [styleItems, setStyleItems] = useState([]);
    const [filterStyleItems, setFilterStyleItems] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const handleRadioChange = (selectedValue) => {
        setSelectedOption(selectedValue);
    };

    const handleGenderChange = (selectedValue) => {
        setSelectedGender(selectedValue);
    };

    useEffect(() => {
        let isMounted = true; // 添加一个标志来跟踪组件的挂载状态

        const fetchDataAndFile = async () => {            
            setShowFileDrag(chatClass && chatClass.classID);
            
            // 页面初次加载的时候, 如果需要读取服务器上传的文件
            if (getImageUrlChatClassName.includes(chatClass.className)) {
                if (isMounted) { // 只有在组件挂载时才调用 getFile
                    getFile(uid, "image", handleGetFile, false);
                };
            };
        };

        if(discordUpload.includes(chatClass.className)) {
            setMaxFiles(1);
        } else {
            setMaxFiles(9);
        };
        
        fetchDataAndFile(); // 调用 async 函数来执行异步操作        

        loadParam(selectedGender);
        
        return () => {
            isMounted = false; // 当组件卸载时，更新标志
        };
    }, [chatClass.className]);

    const handleGetFile = (response) => {
        // 解析响应消息为对象
        let messageObject;
        if (typeof response === "string") {
            messageObject = JSON.parse(response);
        } else {
            messageObject = response;
        };        

        if(response && response.files) {
            if(messageObject.files.length > 0) {
                handleFileUpload(messageObject.files, false)    // 标记文件 isUpload 是 upload还是 get
            };
        };
    };

    useEffect(() => {
        const updatedStyleItems = styleItems.map(item => {
            // 将 remark 和 value 的字符串值转换为对象
            const remarkObj = typeof item.remark === 'string' ? JSON.parse(item.remark) : item.remark;
            const valueObj = typeof item.value === 'string' ? JSON.parse(item.value) : item.value;
    
            return {
                ...item,
                remark: remarkObj,
                value: valueObj
            };
        });
    
        const filteredItems = updatedStyleItems.filter(item => {
            const remarkScope = item.remark.scope || [];
            return remarkScope.includes(selectedGender);
        });
    
        setFilterStyleItems(filteredItems);
    }, [styleItems, selectedGender]);
    

    const loadParam = async () => {
        try {
            const response = await fetch(baseUrl_api + '/getparam', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                credentials: 'include',
                body: JSON.stringify({ type: "photomaker", remark: "button" }),
            });

            const data = await response.json();
            
            if (data.code === 200) {
                // 从 JSON 数据中找到 type 为 'style' 的部分
                const stylesArray = data.message.find(item => item.type === 'style').text;                
                
                const filteredStyles = stylesArray.filter(item => item.code !== 'none');
                
                setStyleItems(filteredStyles)
            };
            
        } catch (error) {
            console.error('Error:', error);
        };
    };

    const sendImage = async(files, param) => {
        if (files.length > 0) {
            setShowModal(true);

            const response = await fetch(baseUrl_api + "/sendmessage", {
                method: "POST",
                headers: { "Content-Type": "application/json", },
                credentials: "include",
                body: JSON.stringify({
                    "uid": uid,
                    "model": "photomaker",
                    "message": param.prompt,
                    "classID": chatClass.classID,
                    "msgType": "text",
                    "chatType": chatClass.className,
                    "files": files,
                    "param": param,
                }),
            });
        };        
    };

    const handleOnFileLoadDone = (text) => {
        const textFiles = text.files;
        
        if (typeof textFiles === "object" && textFiles.length > 0) {
            if(textFiles.length > 0) {
                let file;
                
                if (typeof textFiles[0] === "object") {
                    file = textFiles[0];
                    // type = file.filetype;
                } else {
                    file = JSON.parse(textFiles[0]);
                };

                let gender = '';
                switch (selectedGender) {
                    case 'male':
                        if (text.param.code && text.param.code.toLocaleLowerCase() === "disney") {
                            gender = "King";
                        } else {
                            gender = 'man';
                        };
                        break;
                    case 'female':
                        if (text.param.code && text.param.code.toLocaleLowerCase() === "disney") {
                            gender = "Queen";
                        } else {
                            gender = 'woman';
                        };
                        break;
                    case 'boy':
                        if (text.param.code && text.param.code.toLocaleLowerCase() === "disney") {
                            gender = "Prince";
                        } else {
                            gender = selectedGender;
                        };
                        break;
                    case 'girl':
                        if (text.param.code && text.param.code.toLocaleLowerCase() === "disney") {
                            gender = "Princess";
                        } else {
                            gender = selectedGender;
                        };
                        break;
                    default:
                        // 如果selectedGender不是预期的值，我们可以选择不替换或者抛出错误
                        gender = selectedGender;
                        break;
                };

                text.param.prompt = text.param.prompt.replace(/{gender}/g, gender);

                if(text.param.seed) {
                    const randomInteger = Math.floor(Math.random() * 2001) - 1000;
                    const result = text.param.seed + randomInteger;
                    
                    if (result < 0) {
                        text.param.seed = 0;
                    } else if (result > 2147483647) {
                        text.param.seed = 2147483647;
                    } else {
                        text.param.seed = result;
                    };
                } else {
                    // console.log(211, text.param);
                };
                
                setFiles(textFiles);
                sendImage(textFiles, text.param)
            } else {
                setFiles([]);
            };
        };
    };

    const handleFileUpload = async (dragFiles, isUpload = false) => {
        if (typeof dragFiles === "string") { 
            dragFiles = JSON.parse(dragFiles);
        };

        if (typeof dragFiles === "object") {
            if(dragFiles.length > 0) {
                let file;
                if(typeof dragFiles[0] === "string") {
                    file = JSON.parse(dragFiles[0]);
                } else {
                    file = dragFiles[0];
                };

                setFiles(dragFiles);
            } else {
                setFiles([]);
            };
        };
    };
    

    return (
        <>
        <div className="align-items-start none-scrollbar">
            <ChatPhotoMakerHeader
                uid={uid}
                chatClass={chatClass}
                onOptionChange={handleRadioChange}
                onGenderChange={handleGenderChange}
                onReload={() => setReload(true)}
            />
        </div>
        <div className="chat-photomaker-message scrollarea align-items-start hidden-scrollbar mb-5 ms-1">            
            {selectedOption === "draw" && styleItems.length>0 && (
                <ChatPhotoMakerBodyDraw
                    uid={uid}
                    chatClassName={chatClass.className}
                    onFileLoadDone={handleOnFileLoadDone}
                    gender={selectedGender === "male" || selectedGender === "boy"?"male":"female"}
                    styles={filterStyleItems}
                />
            )}

            {selectedOption === "images" && (
                <ChatPhotoMakerBodyImages
                    uid={uid}
                    chatClass={chatClass}
                    reload={reload}
                    onReload={() => setReload(false)}
                />
            )}

            {/* { showFilesShow && (
                <FilesContainerShow
                    show={showFilesShow}
                    onHide={() => setShowFilesShow(false)}
                    files={files}
                    price={price}
                />
            )} */}

            <ShowPhotoMakerUploadedModal show={showModal} onHide={() => setShowModal(false)}/>

        </div>
        </>
    );
};

export default ChatPhotoMaker;

