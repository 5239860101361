import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

function ChatMainMessageBodyCardSender ({ time, message, messageType, attachments }) {
    let attachmentObjects = null;
    
    // 尝试解析 attachments 字符串为 JSON 对象
    if (attachments && typeof attachments === 'string') {
        try {
            attachmentObjects = JSON.parse(attachments);
        } catch (error) {
            console.error("Error parsing attachment JSON:", error);
        };
    } else {
        attachmentObjects = attachments
    };
    
    const showAttachments = () => {
        if (!attachmentObjects) return null;

        return attachmentObjects.map((item, index) => {
            if (item.filetype === 'image') {
                return (
                    <Card.Text className="preserve-whitespace">
                        <div key={index} className="attachment-image">
                            <a href={item.image_url} target="_blank" rel="noopener noreferrer">
                                <img src={item.thumbnail_url} alt="Attachment" style={{ maxWidth: '100%', cursor: 'pointer' }} />
                            </a>
                        </div>
                    </Card.Text>
                );
            }
            // 可以在这里添加更多的条件来处理其他类型的附件
        });
    };
    
    return (
        <Card className="mb-3 no-border-card">
            <Row className="g-0">
                
                <Col xs={12} md={12}>
                    <Card.Body className="card-sender bg-success-subtle text-success-emphasis">
                        {showAttachments()}
                        <Card.Text className="preserve-whitespace">
                            {message}
                        </Card.Text>
                    </Card.Body>
                    <div className='d-flex justify-content-between text-secondary'>
                        <small className="chat-card-footer">{time}</small>
                    </div>
                    
                </Col>
            </Row>
        </Card>
    );
};

export default ChatMainMessageBodyCardSender;