import { Modal, Spinner } from 'react-bootstrap';


function ShowUploadModal ({ show, text = "Uploading，please wait a moment..." }) {
  return (
    <Modal show={show} centered>
      <Modal.Body>
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p>{text}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShowUploadModal;