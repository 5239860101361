import React, { useState, useRef, } from 'react';
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';
import CardWechat from './login_wechat.jsx'
import './login.css';
import { LoginGoogle, LoginWechatMobile } from './login_auth_url.jsx';
import AdvBottom from './advertisement_bottom.jsx';


function LoginPage({ isMobile, onPageClose }) {
  const [loginType, setLoginType] = useState(null)
    
  const socketRef = useRef(null);

  const handleClose = () => {      
      // 在这里调用父组件传递的处理函数
      // 关闭 WebSocket 连接
      if (socketRef.current) {
          socketRef.current.disconnect();
      }

      // 定义一个空的对象
      let loginInfo = {};
      onPageClose(loginInfo);
  };

  const handleButtonClick = (event) => {
    setLoginType(event.target.id)
};

  return (
    <Container className="container-background">
      <Container className="content login-container">
        <Row className="row-logo hide-on-small-screens">
          <img className="login-logo" src="/logo.png" alt="Logo" />
        </Row>
        <Row className="justify-content-md-center row-login">
          <Col xs={12}>
            <Row>
              <Col xs={0} sm={3} lg={4}/>
              <Col xs={12} sm={6} lg={4}>
                {/* <h1 className="login-title m-3">Login</h1> */}
                {/* <p>Welcome! Please scan the QR code with WeChat to log in.</p> */}
                <Form>
                <Container className="container py-0">
                  <CardWechat isMobile={isMobile}  onModalClose={onPageClose}></CardWechat>
                </Container>
                <Container className="d-flex justify-content-center py-0">
                  <Button
                    variant="outline-success"
                    className={`py-2 mb-2 mt-3 ms-5 me-5 btn-outline-secondary rounded-3 active`}
                    id="wechat"
                  >
                    <i className="bi bi-wechat"></i> Login with Wechat
                  </Button>
                  <div className="text-body-secondary d-flex justify-content-center align-items-center hr-or-container">
                    <hr className="hr-or" />
                    <span style={{ whiteSpace: 'nowrap' }}>or</span>
                    <hr className="hr-or" />
                  </div>
                  <Button
                    variant="outline-success"
                    className={`py-2 mb-2 mt-3 ms-5 me-5 btn-outline-secondary rounded-3 ${loginType === "google" ? "active" : ""}`}
                    onClick={handleButtonClick}
                    id="google"
                  >
                      <i className="bi bi-google"></i> Login with Google
                  </Button>
                  { loginType === "google" && <LoginGoogle /> }
                  { (loginType === "wechat" && isMobile) && <LoginWechatMobile /> }
                </Container>
                </Form>
              </Col>
              <Col xs={0} sm={3} lg={4}/>
            </Row>
            
            {/* <AdvBottom /> */}
          </Col>
          {/* <Col xs={7}>
            <div className="d-flex justify-content-center align-items-center">
              <Image className="login-logo-show mt-5" src="/login-gif.gif" alt="Logo"/>
            </div>
          </Col> */}
        </Row>
      </Container>
    </Container>
  );
}

export default LoginPage;
