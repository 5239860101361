import React, { useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';

function FilesContainerShow ({ show, onHide, files, fileType="image", price = 2 }) {
  // 根据文件类型展示不同的内容
  
  const renderFileContent = (file) => {
    if (typeof file === "string") {
      file = JSON.parse(file);
    };

    switch (fileType) {
      case 'image':
        return (
          <div>
            <a href={file.image_url} target="_blank" rel="noopener noreferrer">
              <img src={file.thumbnail_url} alt={file.name} className="img-fluid" />
            </a>
          </div>

        );
      case 'txt':
        return (
          <div>
            <p>File Name: {file.name}</p>
            <p>File Type: {file.filetype}</p>
            <a href={file.image_url} target="_blank" rel="noopener noreferrer">View Text File</a>
          </div>
        );
      default:
        return <p>Unsupported file type</p>;
    }
  };

  // 将文件按照 3 * 3 的矩阵排列
  const renderFilesGrid = () => {
    const rows = [];
    for (let i = 0; i < files.length; i += 3) {
      const rowItems = [];
      for (let j = i; j < i + 3 && j < files.length; j++) {
        rowItems.push(
          <Col key={j} md={4}>
            {renderFileContent(files[j])}
          </Col>
        );
      }
      rows.push(
        <Row key={i} className="mb-3">
          {rowItems}
        </Row>
      );
    }
    return rows;
  };

  return (
    <Modal show={show} onHide={() => onHide(false)} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-success">Files Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="files-preview">
          {renderFilesGrid()}
        </div>
        <p className="text-secondary font-italic ms-2">                    
          Deduct: {files.length} {files.length > 1 ? 'files' : 'file'}, -{price} tokens/file, -{files.length * price} tokens total.
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default FilesContainerShow;
