import React, { useState, useEffect } from 'react';
import ChatMainDialogHeader from './chat_main_dialog_header.jsx';
import ChatMainDialogBody from './chat_main_dialog_body.jsx';
import { chatClassNameToChatType, fileTranslator } from './config.jsx'

function ChatMainDialog ({
    uid, chatClass, setSendMessage, isReceived, onFileLoadDone,
    toolButtons, onShowFiles, onShowMJParam, onShowExpand,
    onSetParam
}) {
    const [model, setModel] = useState("gpt4o");
    const [message, setMessage] = useState("");
    const [showBody, setShowBody] = useState(true);
    const handleRadioChange = (selectedValue) => {
        setModel(selectedValue);
    };

    const handleSetSendMessage = (text) => {
        setMessage(text)
    };
    // console.log(19, "chatClass", chatClass);
    useEffect (() => {
        if( (message && message.message.trim() !== "") || (chatClass.className.startsWith("PhotoMaker"))) {
            const className = chatClass.className;
            const chatType = chatClassNameToChatType[className] || "Chat";

            // const param = model.toLocaleLowerCase() === "suno" ? null: 1

            if(model) {
                const sendMessage = {
                    "text": message,
                    "model": model,
                    "classID": chatClass.classID,
                    "msgType" : "text",
                    "chatType": chatType,
                };
                setSendMessage(sendMessage);
            };
        };

        setMessage("");
    }, [message])

    useEffect(() => {
        setShowBody(!fileTranslator.includes(chatClass.className))
    }, [chatClass])

    return (
        <div className={`chat-main-dialog bg-body-tertiary scrollarea ${!showBody ? 'chat-main-dialog-translator' : ''}`}>
            <ChatMainDialogHeader
                uid={uid}
                chatClassName={chatClass.className}
                onRadioChange={handleRadioChange}
                onFileLoadDone={onFileLoadDone}
                onShowFiles={onShowFiles}
                toolButtons={toolButtons}
                onShowMJParam={onShowMJParam}
                onShowExpand={onShowExpand}
                onSetParam={onSetParam}
            />

            {showBody && (
                <ChatMainDialogBody
                    chatClassName={chatClass.className}
                    setSendMessage={handleSetSendMessage}
                    isReceived={isReceived}
                />
            )}
        </div>
    );
};

export default ChatMainDialog;