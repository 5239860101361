// 拖曳文件

import React, { useState, useEffect } from 'react';
import { uploadMultipleFiles, uploadDiscordFiles, } from './file_upload';
import { fileAccept, discordUpload, fileTranslator, } from './config.jsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShowUploadModal from './modal_uploading.jsx'


function FileDrag ({ uid, onDrop, children, chatClassName, maxFiles = 6, container = "image" }) {
    const [dragging, setDragging] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const maxFileSize = 20 * 1024 * 1024; // 20MB in bytes
    const acceptType = fileAccept[container];
    
    useEffect (() => {
        setDragging(true);
    })

    const handleDragEnter = (event) => {
        // if (chatClassFile.includes(chatClassName)) {
            event.preventDefault();
            event.stopPropagation();
            setDragging(true);
        // };
    };

    const handleDragLeave = (event) => {
        // if (chatClassFile.includes(chatClassName)) {
            event.preventDefault();
            event.stopPropagation();
        // };
    };

    const handleDragOver = (event) => {
        // if (chatClassFile.includes(chatClassName)) {
            event.preventDefault();
            event.stopPropagation();
        // };
    };

    // 处理多个文件
    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(false);

        const files = Array.from(event.dataTransfer.files); // 转换为数组以便迭代
        const acceptedTypes = Object.values(acceptType).flat();        
        // const maxFiles = 9; // 最大文件数量
        // 检查文件数量是否超过限制
        if (files.length > maxFiles) {
            toast.error(`You can only upload up to ${maxFiles} files at a time.`);
            setShowModal(false);
            return; // 如果超过，停止处理
        };

        // 过滤出符合条件的文件
        // const validFiles = files.filter(file => acceptedTypes.includes(file.type) && file.size <= maxFileSize);
        const validFiles = files.filter(file => {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            // 检查文件类型是否在接受的类型列表中，或者文件类型为空但扩展名为'msg'
            return (acceptedTypes.includes(file.type) || (file.type === '' && fileExtension === 'msg' && container === "translator")) && file.size <= maxFileSize;
        });
        // 如果有不符合条件的文件，提醒用户
        files.forEach(file => {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            let file_type = file.type;
            if(file.type === '' && fileExtension === 'msg' && container === 'translator') file_type = '.msg';
            
            if (!acceptedTypes.includes(file_type)) {
                toast.error(`The file type of "${file.name}" is not accepted.`);
                setShowModal(false);
                return;
            } else if (file.size > maxFileSize) {
                toast.error(`The file "${file.name}" exceeds the 20MB size limit.`);
                setShowModal(false);
                return;
            };
        });

        // 如果所有文件都符合条件，上传文件
        if (validFiles.length > 0 && validFiles.length <= maxFiles) {
            setShowModal(true); // 显示模态框动画
            
            if(discordUpload.includes(chatClassName)) {
                uploadDiscordFiles(uid, validFiles, handleUploadResponse);
            } else if (fileTranslator.includes(chatClassName)) {
                uploadMultipleFiles(uid, validFiles, handleUploadResponse, null, null, container);
            } else {
                uploadMultipleFiles(uid, validFiles, handleUploadResponse);
            }; 
        };
    };

    const handleUploadResponse = (text, type) => {
        setShowModal(false);
        onDrop(text, type);
    };

    return (
        <>
            <div
                className={`chat-main-message-body ${dragging ? 'chat-main-message-body-drag' : ''}`}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                {children}
            </div>
            <ShowUploadModal show={showModal} onHide={() => setShowModal(false)}/>
        </>
    );
};

export default FileDrag;
