import React from 'react';
import ChatMainMessageBody from './chat_main_message_body.jsx';
import ChatMainMessageHeader from './chat_main_message_header.jsx';

function ChatMainMessage ({
    uid, chatClass, sendMessage, receiveMessage, forceUpdate,
    setImageOperate, setFileUpload, attachments=null
}) {
    return (
        <div className='chat-main-message align-items-start'>
            <ChatMainMessageHeader chatClass={chatClass}/>
            <ChatMainMessageBody
                uid={uid} chatClass={chatClass}
                sendMessage={sendMessage}
                receiveMessage={receiveMessage}
                forceUpdate={forceUpdate}
                setImageOperate={setImageOperate}
                setFileUpload={setFileUpload}
                attachments={attachments}
            />
        </div>
    );
};

export default ChatMainMessage;