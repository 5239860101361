import React, { useEffect, useRef } from 'react';
import { Modal, Button, OverlayTrigger, Tooltip, Col } from 'react-bootstrap';

function ChatMainDialogBodyPan({ show, onHide, text, setText, onSendClick }) {
    const textareaRef = useRef(null);

    const renderTooltipSend = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Alt + S
        </Tooltip>
    );

    const handleModalKeyDown = (event) => {
        if (event.key === 'Enter' && !text.trim()) {        
            // 阻止默认行为
            event.preventDefault();
        };        

        if (((event.altKey || event.ctrlKey) && event.key === 'Enter') ||
            (event.altKey && (event.key === 'S' || event.key === 's'))) {
            event.preventDefault();
            onSendClick();
        };
    };

    useEffect(() => {
        if (show && textareaRef.current) {
            setTimeout(() => {
                const textarea = textareaRef.current;
                textarea.focus();
                textarea.selectionStart = textarea.selectionEnd = textarea.value.length;
                textarea.scrollTop = textarea.scrollHeight; // 将 textarea 滚动到底部
            }, 200); // 你可以尝试增加延迟时间，例如 100 或 200 毫秒
        }
    }, [show]);

    return (
        <>
            <Modal className="chat-main-dialog-body-pan-modal"
                show={show}
                onHide={onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                keyboard={true} // 响应Esc键关闭模态框
                // backdrop="static"
            >
                <Modal.Body className="chat-main-dialog-body-pan-modal-body">
                    <Col xs={12} className="chat-main-dialog-container">
                        <textarea ref={textareaRef} value={text}
                            id="user-dialog-pan"
                            className="form-control chat-main-dialog-textarea border-0"                        
                            onChange={(e) => setText(e.target.value)}
                            onKeyDown={handleModalKeyDown}
                        />
                    </Col>
                    <Col className="chat-main-dialog-container-col-right">
                        <OverlayTrigger
                            placement="right"
                            overlay={renderTooltipSend}
                        >
                            <Button variant="outline-secondary"
                                className="button-send border-0"
                                onClick={onSendClick}
                            >
                                <i className="bi bi-send icon-enlarged"></i>
                            </Button>
                        </OverlayTrigger>
                    </Col>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ChatMainDialogBodyPan;