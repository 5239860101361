// 上传文件
import { baseUrl_api, fileAccept, apiUrl_api, allowedFileTypes, fileTranslator } from "./config.jsx";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export async function uploadFile(uid, file, setUploadResponse, maxSize = 5*1024*1024, shouldResize = false, container = "image") {
    if (file.size > maxSize) {
        if (file.type.startsWith('image/')) {
            file = await resizeImage(file, maxSize);
        } else {
            toast.error(`The file "${file.name}" exceeds the maximum size limit and cannot be uploaded.`);
            return;
        };
    };

    const formData = new FormData();
    formData.append('file', file);
    formData.append('uid', uid);
    formData.append('container', container);
    try {
        const response = await fetch(baseUrl_api + "/upload", {
            method: "POST",
            credentials: "include",
            body: formData,
        });

        let text = {};
        if (response.status) {
            text.code = response.status;
        }

        if (response.status === 200) {            
            const uploadResponse = await response.json();
            text.message = uploadResponse;
            text.uniqueIdentifier = Date.now().toString();
        } else {
            switch (response.status) {
                case 404:
                    text.message = "Network Error";
                    break;
                case 413:
                    text.message = "Request Entity Too Large";
                    break;
                case 601:
                    text.message = "Format Error, Only JPG or PNG";
                    break;
                default:
                    text.message = "Unknown Error";
                    break;
            };
        };

        const fileType = getKeyByValue(fileAccept, file.type);
        setUploadResponse(text, fileType);

    } catch (error) {
        console.error("Error to Upload:", error);
    };
};

// 上传多个文件
export async function uploadMultipleFiles(uid, files, setUploadResponse, shouldResize = false, maxSize = 10*1024*1024, container="image") {
    const formData = new FormData();
    // 添加文件到表单数据，并且检查文件类型和大小，如果是图片则调整大小
    for (let file of files) {
        // console.log(69, file, file.type, fileTranslator.includes(file.type));
        const fileExtension = file.name.split('.').pop().toLowerCase();
        let file_type = file.type;
        if(file.type === '' && fileExtension === 'msg' && container === 'translator') file_type = '.msg';
        
        if (allowedFileTypes.includes(file_type)) {
            if (file_type.startsWith('image/')) {
                if (file.size > maxSize) {
                    file = await resizeImage(file, maxSize);
                };
            };
        } else {
            console.error(`The file ${file.name} is not an allowed type.`);
            return;
        };
        formData.append('files', file); // 注意这里使用了'files'而不是'file'
    };

    formData.append('uid', uid);
    formData.append('resize', shouldResize);
    formData.append('container', container);
    
    // console.log(88, baseUrl_api + "/uploadmulity", formData, container);
    try {
        const response = await fetch(baseUrl_api + "/uploadmulity", { // 假设服务器端的URL是/upload/multiple
            method: "POST",
            credentials: "include",
            body: formData,
        });

        let text = {};
        if (response.status) {
            text.code = response.status;
        }

        if (response.status === 200) {
            const uploadResponse = await response.json();
            text.message = uploadResponse;
            text.uniqueIdentifier = Date.now().toString();
        } else {
            // 处理不同的HTTP状态码
            // ...
        }
        const fileType = getKeyByValue(fileAccept, files[0].type);

        setUploadResponse(text, fileType);
    } catch (error) {
        console.error("Error to Upload:", error);
    }
};

// 上传 discord 文件
export async function uploadDiscordFiles(uid, files, setUploadResponse, maxSize = 5*1024*1024) {
    const formData = new FormData();
    // 添加文件到表单数据，并且检查文件类型和大小，如果是图片则调整大小
    for (let file of files) {
        if (file.type.startsWith('image/')) {
            if (file.size > maxSize) {
                file = await resizeImage(file, maxSize);
            };
        } else {
            console.error(`File ${file.name} is not an image and cannot be resized.`);
            return;
        };
        formData.append('files', file); // 注意这里使用了'files'而不是'file'
    };

    formData.append('uid', uid);

    try {
        const response = await fetch(apiUrl_api + "/discord/upload", {
            credentials: "include",
            method: 'POST',
            body: formData,
        });

        let text = {};
        if (response.status) {
            text.code = response.status;
        }

        // switch (response.status) {
        //     case 200:
        //         const uploadResponse = await response.json();
        //         text.message = uploadResponse;
        //         text.uniqueIdentifier = Date.now().toString();

        //         const fileType = getKeyByValue(fileAccept, files[0].type);
        //         setUploadResponse(text, fileType);
        //         break;

        //     case 413:
        //         setUploadResponse("File is too large.", "error");
        //         break;
        //     default:
        //         break;
        // };

        if (response.status === 200) {
            const uploadResponse = await response.json();
            text.message = uploadResponse;
            text.uniqueIdentifier = Date.now().toString();
        } else {
            // 处理不同的HTTP状态码
            // ...
        };
        const fileType = getKeyByValue(fileAccept, files[0].type);

        setUploadResponse(text, fileType);
    } catch (error) {
        console.error("Error to Upload:", error, error.status);
        setUploadResponse("Failed to upload file.", "error");
    }
};


function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key].includes(value));
};

async function resizeImage(file, maxSize) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function(event) {
            const img = new Image();
            img.onload = function() {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const scaleFactor = maxSize / file.size;
                canvas.width = img.width * scaleFactor;
                canvas.height = img.height * scaleFactor;
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                canvas.toBlob((blob) => {
                    const resizedFile = new File([blob], file.name, { type: file.type, lastModified: Date.now() });
                    resolve(resizedFile);
                }, file.type, 0.7); // 0.7 是 JPEG 压缩质量
            };
            img.src = event.target.result;
        };
        reader.readAsDataURL(file);
    });
};



export async function getFile (uid, fileType, setGetResponse, isStream = false, container="image") {
    const response = await fetch(baseUrl_api + "/getfile", {
        method: "POST",
        headers: { "Content-Type": "application/json", },
        credentials: "include",
        body: JSON.stringify({
            "uid": uid,
            "fileType": fileType, 
            "isStream": isStream,
            "container": container,
        }),
    });

    const dataFile = await response.json();
    dataFile.filetype = fileType;

    setGetResponse(dataFile);
};