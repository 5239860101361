import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal, Row, Col, OverlayTrigger, Tooltip, Form, FloatingLabel } from 'react-bootstrap';
import { baseUrl_api, MUSIC_GENRE_LIST } from './config.jsx';

function SunoExpand ({ show, onHide, setSendMessage, price = 10, maxFiles = 6 }) {
    const [textValue, setTextValue] = useState('');
    const [textStyle, setTextStyle] = useState('');
    const [textTitle, setTextTitle] = useState('');
    const [musicStyles, setMusicStyles] = useState([]);
    const textareaRef = useRef(null);
    const textStyleRef = useRef(null);
    const textTitleleRef = useRef(null);
    const [textareaClassName, setTextareaClassName] = useState("form-control border-1 mt-0 chat-main-message-body-container-textarea hidden-scrollbar");
    const [randomIcon, setRandomIcon] = useState(null);

    const randomIcons = ["bi bi-dice-1", "bi bi-dice-2", "bi bi-dice-3", "bi bi-dice-4", "bi bi-dice-5", "bi bi-dice-6"]
    
    const loadParam = async () => {
        try {
            const response = await fetch(baseUrl_api + '/getparam', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                credentials: 'include',
                body: JSON.stringify({ type: "suno" }),
            });

            const data = await response.json();
            
            if (data.code === 200) {
                if(data.message[0].text.length > 0) {
                    setMusicStyles(data.message[0].text);
                } else {
                    setMusicStyles(MUSIC_GENRE_LIST);
                };
            } else {
                onHide();
            };
            
        } catch (error) {
            console.error('Error:', error);
        };
    };

    // 函数用于随机选择音乐风格
    const getRandomMusicStyle = () => {
        const randomIndex = Math.floor(Math.random() * musicStyles.length); // 生成一个随机索引
        
        if(musicStyles.length > 0) {
            setTextStyle(musicStyles[randomIndex].code); // 返回随机选中的音乐风格
        } else {
            setTextStyle("Pop");
        };        
    };

    const getRandomIcon = () => {
        const randomIndex = Math.floor(Math.random() * randomIcons.length); // 生成一个随机索引
        const selectedIcon =  randomIcons[randomIndex]; 
        setRandomIcon(selectedIcon);
    };
    
    useEffect(() => {
        if (show) {
            loadParam();
            getRandomIcon();
        };
    }, [show]);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setTextValue(newValue);
    };

    const handleStyleChange = (event) => {
        const newValue = event.target.value;
        setTextStyle(newValue);
    };    

    const handleTitleChange = (event) => {
        const newValue = event.target.value;
        setTextTitle(newValue);
    };

    const handleSendClick = () =>{
        if (textValue && textValue.trim() !== "") {
            setTextareaClassName("form-control border-1 mt-0 chat-main-message-body-container-textarea");
            const code = "suno";

            // 如果 textStyle 为空，则从 musicStyles 中随机获取一个值
            const selectedStyle = textStyle.trim() || (musicStyles.length > 0 ? musicStyles[Math.floor(Math.random() * musicStyles.length)].code : '');

            const text = {
                code: "Chat",
                message: textValue.trim(),
                style: selectedStyle,
                title: textTitle.trim(),
                is_custom: true,
            };

            const message = {
                chatType: code,
                classID: "",
                model: code,
                msgType: "text",
                text: text,
                deduct: 10
            };
            
            setSendMessage(message);
            setTextValue("");
            setTextStyle("");
            setTextTitle("");

            onHide();
        } else {
            setTextareaClassName("form-control border-1 mt-0 chat-main-message-body-container-textarea input-unfinish")
        };
    };

    const handleKeyDown = (event) => {        
        if (event.key === 'Enter' && !textValue.trim()) {
            event.preventDefault();
        };

        if (((event.altKey || event.ctrlKey) && event.key === 'Enter') ||
            (event.altKey && (event.key === 'S' || event.key === 's'))) {
            handleSendClick();
            event.preventDefault();
        };
    };

    const styleClick = () => {
        // 模拟随机选择的过程
        const interval = setInterval(getRandomIcon, 50); // 每100毫秒更改一次图标

        // 设置2秒后停止图标变化
        setTimeout(() => {
            clearInterval(interval); // 停止周期性更改图标
            getRandomIcon();
            getRandomMusicStyle();
        }, 300);        
    };

    return (
        <Modal
            className="chat-main-message-body-file-container"
            show={show} onHide={onHide} size="lg" centered
            aria-labelledby="contained-modal-title-vcenter"
            onEntered={() => textTitleleRef.current && textTitleleRef.current.focus() }
        >

            <Modal.Body>                                
                <div className="chat-main-message-body-file-container-body">
                    <Row className="m-2">
                        <Modal.Title className="text-success">Make Your Own Song</Modal.Title>
                    </Row>

                    <Row className="m-2">
                        <Col xs={12} className="chat-main-dialog-container-col">
                            <OverlayTrigger
                                placement="left"
                                overlay={<Tooltip id="button-tooltip">自定义歌曲标题</Tooltip>}
                            >
                                <FloatingLabel controlId="floatingInput" label="Type your music title" className="mb-3">
                                    <Form.Control
                                        className={`form-control border-1 mt-0 chat-main-message-body-container-textarea`}
                                        ref={textTitleleRef}
                                        type="text" // 使用 text 类型的输入框
                                        placeholder="Type your music title" // placeholder 必须与 label 文本相同
                                        maxLength={50}
                                        value={textTitle}
                                        onChange={handleTitleChange}
                                    />
                                </FloatingLabel>
                            </OverlayTrigger>
                        </Col>
                    </Row>

                    <Row className="ms-2 mt-2 mb-2 me-0">
                        <Col xs={10}>
                            <OverlayTrigger
                                placement="left"
                                overlay={<Tooltip id="button-tooltip">自定义歌曲风格</Tooltip>}
                            >
                                <FloatingLabel controlId="floatingInput" label="Type your music style" className="mb-3">
                                    <Form.Control
                                        className={`form-control border-1 mt-0 chat-main-message-body-container-textarea`}
                                        ref={textStyleRef}
                                        type="text" // 使用 text 类型的输入框
                                        placeholder="Type your music style" // placeholder 必须与 label 文本相同
                                        maxLength={50}
                                        value={textStyle}
                                        onChange={handleStyleChange}
                                    />
                                </FloatingLabel>
                            </OverlayTrigger>
                        </Col>
                        <Col xs={2} className="flex ms-0">
                            <Button
                                variant="outline-secondary"
                                className="border-0"
                                onClick={styleClick}
                            >
                                <i className={`${randomIcon} fs-2 m-0`}></i>
                            </Button>
                        </Col>
                    </Row>
                    
                    <Row className="m-2">
                        {/* <p className="text-secondary font-italic ms-2">                    
                           Deduct: -{price} tokens.
                        </p> */}
                        <Col xs={12} className="chat-main-dialog-container-col">
                            <textarea
                                ref={textareaRef}
                                className={textareaClassName}
                                id="user-dialog-file"
                                value={textValue}
                                rows="16"
                                placeholder="Type your lyrics..."
                                onChange={handleChange}
                                onKeyDown={handleKeyDown} // 如果需要，可以添加键盘事件处理
                            />
                        </Col>
                        <Col className="chat-main-dialog-container-col-right">
                            <OverlayTrigger
                                placement="right"
                                overlay={<Tooltip id="button-tooltip">Alt + S</Tooltip>}
                            >
                                <Button variant="outline-secondary"
                                    className="button-send-file border-0"
                                    onClick={handleSendClick}
                                >
                                    <i className="bi bi-send icon-enlarged"></i>
                                </Button>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default SunoExpand;