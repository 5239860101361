import React, { useState, useRef, useEffect, } from 'react';
import { Button, Modal, Row, Col, OverlayTrigger, Tooltip, } from 'react-bootstrap';
import 'react-multi-carousel/lib/styles.css';
import { apiUrl_api, } from './config.jsx';
// import { Document, Page, pdfjs } from 'react-pdf';
import LanguageDropdown from './dropdown_language.jsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContentByFileType from './render_by_filetype.jsx';

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function FilesContainerSend({ show, onHide, files, setSendMessage, price = 2 }) {
    const [selectedSourceOption, setSelectedSourceOption] = useState("");
    const [selectedSourceValue, setSelectedSourceValue] = useState("");
    const [selectedTargetOption, setSelectedTargetOption] = useState("");
    const [selectedTargetValue, setSelectedTargetValue] = useState("");
    const sendButtonRef = useRef(null);
    const dropSourceRef = useRef(null);
    const dropTargetRef = useRef(null);
    const [showSourceTooltip, setShowSourceTooltip] = useState(false);
    const [showTargetTooltip, setShowTargetTooltip] = useState(false);
    const [optionsSourceLanguage, setOptionsSourceLanguage] = useState([]);
    const [optionsTargetLanguage, setOptionsTargetLanguage] = useState([]);
    const [blobUrls, setBlobUrls] = useState({});

    const formatOptions = (data) => {
        const formattedOptions = data.map(item => ({
            id: item.ID,
            label: item.OptionLabel,
            value: item.OptionValue,
            language: item.Alias
        }));
        
        // 设置目标语言选项，不包含“自动”选项
        setOptionsTargetLanguage([...formattedOptions]);

        // 创建一个新的“自动”选项对象
        const autoOption = {
            id: 'auto',  // 可以是任意唯一的标识符
            label: '自动',
            value: null,  // 如果需要，可以设置为特定值或留空
            language: 'Auto'
        };

        // 将新的“自动”选项添加到源语言选项数组的开头
        const sourceOptionsWithAuto = [autoOption, ...formattedOptions];
        setOptionsSourceLanguage(sourceOptionsWithAuto);
    };

    useEffect(() => {
        if (show) {
            // const textarea = textareaRef.current;
            loadLanguage();
        };
    }, [show]); // 添加 show 到依赖数组，以便在 Modal 变为可见时设置焦点

    const renderTooltipSend = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Alt + S
        </Tooltip>
    );

    useEffect(() => {
        files.forEach(file => {
            if (file.filetype === "text/plain") {
                fetch(file.file_url)
                    .then(response => response.blob())
                    .then(blob => {
                        const newBlob = new Blob([blob], { type: 'text/plain; charset=utf-8' });
                        const blobUrl = URL.createObjectURL(newBlob);
                        setBlobUrls(prevUrls => ({ ...prevUrls, [file.file_url]: blobUrl }));
                    })
                    .catch(error => console.error('Error loading the text file:', error));
            }
        });

        return () => {
            Object.values(blobUrls).forEach(url => URL.revokeObjectURL(url));
        };
    }, [files]); // 依赖于 files 数组

    const loadLanguage = () => {
        // 获取数据库中语言选项
        const bodyData = {option_class: "language"}
        fetch(apiUrl_api + "/options", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyData),
            keepalive: true,
        })
        .then(response => response.json())
        .then(data => {
            const dataArray = JSON.parse(data);
            formatOptions(dataArray); // 调用formatOptions函数来格式化选项
        })
        .catch(error => {
            console.error(error);
        });
    }

    const handleKeyDown = (event) => {
        if (!selectedTargetValue) {
            event.preventDefault();
        };

        if (((event.altKey || event.ctrlKey) && event.key === 'Enter') ||
            (event.altKey && (event.key === 'S' || event.key === 's'))) {
            handleSendClick();
            event.preventDefault();
        };
    };

    useEffect(() => {
        const userLanguage = navigator.language || navigator.userLanguage;
        const matchingOption = optionsTargetLanguage.find(option => option.value === userLanguage);
        if (matchingOption) {
            setSelectedTargetOption(matchingOption.label);
            setSelectedTargetValue(matchingOption.value);
        } else {
            setSelectedTargetOption(null); // 如果没有找到匹配项，则不显示默认值
        }
    }, [optionsTargetLanguage]); // 只需在optionsLanguage更新时运行

    useEffect(() => {
        setSelectedSourceOption("自动");
        setSelectedSourceValue(null);
    }, [optionsTargetLanguage]); // 只需在optionsLanguage更新时运行
    
    
    const handleSendClick = () =>{
        if (selectedTargetValue) {
            const code = "translator";
            const text = {
                code: code,
                files: files,
                message: "translate",
                language: {source: selectedSourceValue,target: selectedTargetValue}                
            };
            const message = {
                chatType: code,
                classID: "",
                model: code,
                msgType: "text",
                text: text,
                deduct: files.length,
            };
            setSendMessage(message);
            toast(`Your document is being processed, please wait`);
            onHide();
        } else {
            toast.warn(`Please select the target language`);
            return;
        };
    };

    
    
    // // 根据文件类型渲染不同的内容
    // const renderContentByFileType = () => {
    //     // 根据文件类型渲染不同的UI
    //     return files.map((file, index) => {
    //         const FileNameDisplay = () => (
    //             <div className="file-name-display" style={{ marginBottom: '10px' }}>
    //                 {file.file_name}
    //             </div>
    //         );
            
    //         const FileTokens = ({ tokens }) => {
    //             const estimate = -Math.ceil(tokens / 350);
    //             file.characters = tokens;
    //             file.delta = estimate;
                
    //             return(
    //                 <>
    //                     <p className="text-secondary font-italic ms-2 mb-1">
    //                         Estimate: {estimate} tokens
    //                     </p>
    //                     <p className="text-secondary font-italic  ms-2 mb-0 small">
    //                         Token deductions are estimates. Actual deductions will be based on the completed translation.
    //                     </p>
    //                     <p className="text-secondary font-italic  ms-2 mt-0 small">
    //                         Token 扣除是估计值, 实际扣除将基于完成的翻译。
    //                     </p>
    //                 </>
    //             );
    //         };
            
    //         switch (file.filetype) {
    //             case "application/pdf":
    //                 return (
    //                     <>
    //                         <FileNameDisplay />
    //                         <div key={index} style={{ width: '100%', height: '600px', overflow: 'auto' }} className='hidden-scrollbar'>
    //                             <Document
    //                                 file={file.file_url}
    //                                 onLoadSuccess={() => console.log('PDF loaded successfully!')}
    //                                 onError={(error) => console.error('Error while loading PDF:', error)}
    //                             >
    //                                 <Page pageNumber={1} />
    //                             </Document>
    //                         </div>
    //                         <FileTokens tokens={file.tokens * 10} />
    //                     </>
    //                 );
    //             case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    //                 return (
    //                     <>
    //                         <FileNameDisplay />
    //                         <div key={index}>
    //                             <iframe src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(file.file_url)}`} width="100%" height="600px" frameBorder="0"></iframe>
    //                         </div>
    //                         <FileTokens tokens={file.tokens + 1000} />
    //                     </>
    //                 );
    //             case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    //                 return (
    //                     <>
    //                         <FileNameDisplay />
    //                         <div key={index}>
    //                             <iframe src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(file.file_url)}`} width="100%" height="600px" frameBorder="0"></iframe>
    //                         </div>
    //                         <FileTokens tokens={file.tokens * 10} />
    //                     </>
    //                 );
    //             case "application/vnd.openxmlformats-officedocument.presentationml.presentation": // 添加对 PPTX 文件的支持
    //                 return (
    //                     <>
    //                         <FileNameDisplay />
    //                         <div key={index}>
    //                             <iframe src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(file.file_url)}`} width="100%" height="600px" frameBorder="0"></iframe>
    //                         </div>
    //                         <FileTokens tokens={file.tokens * 8} />
    //                     </>
    //                 );
    //             case "application/vnd.ms-outlook": // 对 MSG 文件的支持
    //                 return (
    //                     <>
    //                         <FileNameDisplay />
    //                         <div key={index}>
    //                             <p className='mb-0'>MSG files cannot be previewed directly in the browser. Please download the file to view.</p>
    //                             <p>MSG 文件无法直接在浏览器中预览。请下载文件查看。</p>
    //                             <a href={file.file_url} download>Download MSG File</a>
    //                         </div>
    //                         <FileTokens tokens={file.tokens * 20} /> 
    //                     </>
    //                 );
    //             case "text/plain":
    //                 return (
    //                     <>
    //                         <FileNameDisplay />
    //                         <div key={index}>
    //                             <iframe src={blobUrls[file.file_url]} width="100%" height="600px" frameBorder="0"></iframe>
    //                         </div>
    //                         <FileTokens tokens={file.tokens + 100}/>
    //                     </>
    //                 );
    //             default:
    //                 return (
    //                     <>
    //                         <FileNameDisplay />
    //                         <div key={index}>                            
    //                             <div>Unsupported file type</div>
    //                         </div>
    //                         <FileTokens />
    //                     </>
    //                 );
    //         };
    //     });
    // };
  
    return (
        <Modal
            className="chat-main-message-body-file-container"
            show={show} onHide={onHide} size="lg" centered
            aria-labelledby="contained-modal-title-vcenter"
            onKeyDown={handleKeyDown}
        >
            <Modal.Body>
                <div className="chat-main-message-body-file-container-body">
                    <Row className="mb-0 pr-3">
                        {/* {renderContentByFileType()} */}
                        <ContentByFileType files={files} blobUrls={blobUrls} />
                    </Row>
                    
                    <Row className="m-2">
                        <Col xs={5}>
                            <LanguageDropdown
                                id="dropdown-source"
                                options={optionsSourceLanguage}
                                selectedOption={selectedSourceOption}
                                setSelectedOption={setSelectedSourceOption}
                                setSelectedValue={setSelectedSourceValue}
                                tooltipText="选择源文件语言"
                                toggleRef={dropSourceRef}
                                showTooltip={showSourceTooltip}
                                setShowTooltip={setShowSourceTooltip}
                                onKeyDown={handleKeyDown}
                            />
                        </Col>

                        <Col xs={5}>
                            <LanguageDropdown
                                id="dropdown-target"
                                options={optionsTargetLanguage}
                                selectedOption={selectedTargetOption}
                                setSelectedOption={setSelectedTargetOption}
                                setSelectedValue={setSelectedTargetValue}
                                tooltipText="选择目标文件语言"
                                toggleRef={dropTargetRef}
                                showTooltip={showTargetTooltip}
                                setShowTooltip={setShowTargetTooltip}
                                onKeyDown={handleKeyDown}
                            />
                        </Col>


                        <Col xs={2}>
                            <OverlayTrigger
                                placement="right"
                                overlay={renderTooltipSend}                        
                            >
                                <Button variant="outline-secondary"
                                    className="fs-4 border-0"
                                    onClick={handleSendClick}
                                    ref={sendButtonRef}
                                >
                                    <i className="bi bi-send icon-enlarged"></i>
                                </Button>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    );
  }
  
  export default FilesContainerSend;