import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import CodeBlock from './code_block.jsx';

const ChatMainMessageBodyCardReceiverArray = ({ children }) => {
    const [array, setArray] = useState([]);

    useEffect(() => {
        try {
            // 尝试解析传入的 children 字符串
            const parsedArray = typeof children === "string" ? JSON.parse(children) : children;

            setArray(parsedArray);
        } catch (error) {
            console.error('Error parsing JSON:', error);
            setArray([]); // 如果解析失败，设置为空数组
        }
    }, [children]); // 依赖项是 children，这样每次 children 变化时都会重新解析

    // 将对象数组转换为 Markdown 格式的字符串
    const arrayToMarkdown = (array) => {
        return array.map(item => {
            // 替换 \ue000 和 \ue001 符号，并在它们之间的文本两侧添加加粗语法
            const title = item.title.replace(/\ue000(.*?)\ue001/g, '**$1**');
            const link = item.link.replace(/\ue000(.*?)\ue001/g, '**$1**');
            const snippet = item.snippet.replace(/\ue000(.*?)\ue001/g, '**$1**');

            // 返回格式化后的 Markdown 字符串
            return `##### ${title}\n[${link}](${item.link})\n> ${snippet}\n`;
        }).join('\n\n');
    };


    // 转换后的 Markdown 字符串
    const markdownContent = arrayToMarkdown(array);

    const components = {        
        code({ node, inline, className, markdownContent, ...props }) {
            const match = /language-(\w+)/.exec(className || '');

            return !inline && match ? (
                // <CodeBlock language={match[1]} value={String(children).replace(/\n$/, '')} {...props} />
                <CodeBlock language={match[1]} value={String(children)} {...props} />
            ) : (
                <code className={className} {...props}>
                    {children}
                </code>
            );
        }
    };

    return <ReactMarkdown components={components} breaks={true}>{markdownContent}</ReactMarkdown>;
};

export default ChatMainMessageBodyCardReceiverArray;