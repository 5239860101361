import React from 'react';
import ReactMarkdown from 'react-markdown';
import CodeBlock from './code_block.jsx';

const MarkdownRender = ({ children }) => {
    // console.log(6, children);
    const components = {
        code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');

            return !inline && match ? (
                // <CodeBlock language={match[1]} value={String(children).replace(/\n$/, '')} {...props} />
                <CodeBlock language={match[1]} value={String(children)} {...props} />
            ) : (
                <code className={className} {...props}>
                    {children}
                </code>
            );
        }
    };

    return <ReactMarkdown components={components} breaks={true}>{children}</ReactMarkdown>;
};

export default MarkdownRender;