import React, { useEffect, useState } from 'react';
import { Col, ButtonGroup, Button } from 'react-bootstrap';
import { BiLeftArrow, BiUpArrow, BiDownArrow, BiCrop, BiRightArrow } from 'react-icons/bi';

function ChatMainMessageBodyCardReceiverImage({ image, setImageOperate }) {
    const [variation, setVariation] = useState(false);
    const [upscale, setUpscale] = useState(false);
    const [zoom, setZoom] = useState(false);
    const [pan, setPan] = useState(false);
    const [version, setVersion] = useState("v");

    // 创建一个数组，包含index和icon
    const zooms = [
        { index: 1, icon: '1.5x', desc:'Zoom out 1.5x', title: 'Zoom out 1.5x' },
        { index: 2, icon: '2x', desc:'Zoom out 2x', title: 'Zoom out 2x' },
        { index: 3, icon: <BiCrop />, desc:'Crop to a square', title: 'Crop to a square' },
    ];

    const pans = [
        { index: 1, icon: <BiLeftArrow />, desc: 'Pan left', title: 'Pan left' },
        { index: 2, icon: <BiRightArrow />, desc: 'Pan right', title: 'Pan right' },
        { index: 3, icon: <BiUpArrow />, desc: 'Pan up', title: 'Pan up' },
        { index: 4, icon: <BiDownArrow />, desc: 'Pan down', title: 'Pan down' },
    ];

    const upscales = [
        { index: 1, icon: 'U1', desc: 'U1', title: 'Generate the 1st image' },
        { index: 2, icon: 'U2', desc: 'U2', title: 'Generate the 2nd image' },
        { index: 3, icon: 'U3', desc: 'U3', title: 'Generate the 3rd image' },
        { index: 4, icon: 'U4', desc: 'U4', title: 'Generate the 4th image' },
    ];

    const variations = [
        { index: 1, icon: 'V1', desc: 'V1', title: 'Draw again based on the 1st image' },
        { index: 2, icon: 'V2', desc: 'V2', title: 'Draw again based on the 2nd image' },
        { index: 3, icon: 'V3', desc: 'V3', title: 'Draw again based on the 3rd image' },
        { index: 4, icon: 'V4', desc: 'V4', title: 'Draw again based on the 4th image' },
    ];

    // 创建一个事件处理函数，它会捕获image对象
    const handleMidjourneyButtonClick = (index, btnType, desc) => {
        // 执行其他操作...
        image.index = index;
        image.operate = btnType;

        const operate = {
            model: "midjourney",
            msgType: "text",
            chatType: btnType,
            text :{        
                code:"midjourneyOperate",
                message: {
                    image: image,
                },
                describe: desc,
            },
        };

        setImageOperate(operate)
    };

    function openImageInNewWindow(imageUrl) {
        window.open(imageUrl, '_blank');
    }

    useEffect(() => {
        if(image.operate) {
            switch(image.operate.toLowerCase()) {
                case "draw":
                case "variation":
                case "zoomout":
                    setVariation(true);
                    setUpscale(true);
                    setZoom(false);
                    setPan(false);
                    break;
                case "upscale":
                    setVariation(false);
                    setUpscale(false);
                    setZoom(true);
                    setPan(true);

                    // if (image.version && image.version.toLowerCase() === "niji") {
                    //     setZoom(false);
                    //     setPan(false);
                    // } else {
                    //     setZoom(true);
                    //     setPan(true);
                    // };
                    
                    break;
                case "pan":
                    setVariation(true);
                    setUpscale(true);
                    setZoom(false);
                    setPan(false);
                    break;                
                case "refresh":
                    setVariation(image.pre_operate ? false : true);
                    setUpscale(true);
                    setZoom(false);
                    setPan(false);                    
                    break;
                default:
                    break;
            };
        };
    }, [image])

    // 创建一个函数来构建按钮组
    const createButtonGroup = (buttons, btnType) => (
        <ButtonGroup className="chat-image-button-group">
            {buttons.map(({ index, icon, desc, title }) => (
                <Button
                    key={index}
                    variant="outline-secondary"
                    title={title}
                    onClick={() => handleMidjourneyButtonClick(index, btnType, desc)}
                >
                    {icon}
                </Button>
            ))}
            {btnType === "upscale" && (
                <Button
                    variant="outline-secondary"
                    title="Draw again"
                    onClick={() => handleMidjourneyButtonClick(5, "variation", "Refresh")}
                >
                    <small><i className="fas fa-sync-alt"></i></small>
                </Button>
            )}
        </ButtonGroup>
    );

    // 根据条件动态构建按钮数组
    const getButtons = (btnType) => {
        switch (btnType) {
            case "upscale":
                return upscales.map(item => ({
                    index: item.index,
                    icon: <small>{item.icon}</small>,
                    desc: item.desc,
                    title: item.title
                }));
            case "variation":
                return variations.map(item => ({
                    index: item.index,
                    icon: <small>{item.icon}</small>,
                    desc: item.desc,
                    title: item.title
                }));
            case "zoomout":
                return zooms.filter(({ index }) => (image.isSquare === false && index === 3) || index !== 3);
            case "pan":
                // return pans.filter(({ index }) => {
                //     if ("pre_index" in image) {
                //         return image.pre_index <= 2 ? index <= 2 : index > 2;
                //     };
                //     return true;
                // });
                return pans;
            default:
                return [];
        }
    };

    return (
        <Col className="image-container">
            { image.thumbnail_url && 
                <a href={image.image_url} target="_blank" rel="noopener noreferrer">
                    <img
                        src={image.thumbnail_url}
                        className="chat-image"
                        alt={image.msg || "Image"}
                    />
                </a>
            }
            {image.message && <p>{image.message}</p>}           

            {upscale && createButtonGroup(getButtons("upscale"), "upscale")}
            {variation && createButtonGroup(getButtons("variation"), "variation")}
            {zoom && createButtonGroup(getButtons("zoomout"), "zoomout")}
            {pan && createButtonGroup(getButtons("pan"), "pan")}
        </Col>
    );
};

export default ChatMainMessageBodyCardReceiverImage;