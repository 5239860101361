import React, { useEffect, useRef, useState, } from 'react';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import FileUpload from './file_load.jsx';

function ChatPhotoMakerBodyDraw( {uid, chatClassName, onFileLoadDone, gender, styles = []} ) {
    const fileUploadRef = useRef(); // 创建一个引用
    const [maxFiles, setMaxFiles] = useState(1);
    const [selectStyle, setSelectStyle] = useState(null);
    const [param, setParam] = useState(null);
    const [resize, setResize] = useState(false);
    const [model, setModel] = useState("InstantID");

    const handleFileUpload = (style, code) => {
        let styleObject;
        if (typeof style.value === "string") {
            styleObject = JSON.parse(style.value);
        } else {
            styleObject = style.value;
        };

        const prompt = styleObject.prompt;
        const negative_prompt = styleObject.negative_prompt;
        const seed = styleObject.seed;
        const paramModel = styleObject.model ? styleObject.model : "InstantID"

        setModel(styleObject.model ? styleObject.model : "InstantID");
        
        setParam ({
            prompt: prompt,
            negative_prompt: negative_prompt,
            seed: seed,
            model: paramModel,
            code: code.toLocaleLowerCase(),
        })

        setSelectStyle(style.value);
        fileUploadRef.current.click(); // 触发文件选择框
    };

    // 根据按钮的id获取背景图片路径
    const getButtonBackgroundImage = (buttonId) => {
        const buttonIdSanitized = buttonId.replace(/\s+/g, '_').toLowerCase(); // 将空格替换为下划线并转换为小写
        return `https://aimindwork.blob.core.windows.net/asset/PhotoMaker/${buttonIdSanitized}_${gender}.png`;
    };    

    const onFileSelect = (text) => {
        text.param = param;
        onFileLoadDone(text);
    };

    useEffect(() => {
        if(model.toLocaleLowerCase() === "InstantID") {
            setResize(true);
        } else {
            setResize(false);
        };
    }, [model])

    return (
        <div className="d-flex flex-wrap chat-photomaker-layout-draw">           
            {styles && styles.length > 0 && styles.map((style) => (
                <Button
                    key={style.code}
                    variant="outline-secondary"
                    className="chat-photomaker-button-upload"
                    style={{ backgroundImage: `url(${getButtonBackgroundImage(style.code)})` }}
                    onClick={() => handleFileUpload(style, style.code)}
                    id={style.code}
                >
                    <span className="button-text">{style.code}</span>
                    <span className="button-text">{ style.code !== style.name? style.name : ""}</span>
                </Button>
            ))}

            <FileUpload
                uid={uid}
                ref={fileUploadRef}
                fileType="image"
                onFileSelect={onFileSelect}
                chatClassName={chatClassName}
                maxFiles={maxFiles}
                resize={true}
                container="photomaker"
            />
        </div>
    );
}

export default ChatPhotoMakerBodyDraw;
