import React, { useState, useEffect } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';

function ChatList({ listData, id }) {
    // 使用状态来跟踪详情的显示和隐藏
    const [detailVisible, setDetailVisible] = useState({});
    const [textStyle, setTextStyle] = useState("text-danger");

    // 切换详情的显示状态
    const toggleDetail = (index) => {
        setDetailVisible((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    useEffect (() => {
        if(id === "recharge") {
            setTextStyle("text-success");
        };
    }, [])

    // 格式化日期时间
    const formatDateTime = (datetime) => {
        const date = new Date(datetime);
        return date.toLocaleString("en-US", {
            hour12: false,
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        }).replace(/,/g, "").replace(/\//g, "-");
    };

    // 渲染列表项
    const renderListItems = (listData) => {
        return listData.map((item, index) => (
            <React.Fragment key={index}>
                <ListGroupItem
                    className="list-group-item d-flex justify-content-between bg-body-tertiary clickable"
                    onClick={() => item.detail && toggleDetail(index)}
                >
                    <div>
                        <h6 className="my-0">
                            {item.detail ? (
                                <i className={`bi ${detailVisible[index] ? 'bi-chevron-up' : 'bi-chevron-down'}`}></i>
                            ) : null}
                            {' ' + (item.createdate || "Total")}
                        </h6>
                        <small className="text-secondary">{item.createdate}</small>
                    </div>
                    <span className={textStyle}>{item.delta}</span>
                </ListGroupItem>
                {item.detail && detailVisible[index] && item.detail.map((detailItem, detailIndex) => (
                    <ListGroupItem
                        key={detailIndex}
                        className="list-group-item d-flex justify-content-between lh-sm"
                    >
                        <div>
                            <h6 className="my-0">{detailItem.model}</h6>
                            <small className="text-secondary">{formatDateTime(detailItem.createtime)}</small>
                        </div>
                        <span className="text-secondary">{detailItem.delta}</span>
                    </ListGroupItem>
                ))}
            </React.Fragment>
        ));
    };

    return (
        <ListGroup as="ul" id={`ul-${id}`} className="list-group-chat">
            {listData ? renderListItems(listData) : null}
        </ListGroup>
    );
}

export default ChatList;
