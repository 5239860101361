import React from 'react';
import {  Modal, Row } from 'react-bootstrap';
import 'react-multi-carousel/lib/styles.css';
import ContentByFileType from './render_by_filetype.jsx';

const ShowRenderByFileType = ({ show, onHide, files, blobUrls, showEstimate = true }) => {
    try {        
        return (
            <Modal
                className="chat-main-message-body-file-container"
                show={show} onHide={onHide} size="lg" centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="chat-main-message-body-file-container-body">
                        <Row className="mb-0 pr-3">
                            <ContentByFileType files={files} blobUrls={blobUrls} showEstimate={showEstimate} />
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        );
    } catch {

    };
};

export default ShowRenderByFileType;