// ImageItem.jsx
import React, { useState } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';

const ImageActions = ({ fileIndex, selectedButtonId, setSelectedForFile, isImageLoaded, setButtonClick }) => {
    const [selected, setSelected] = useState(selectedButtonId || 'graph');
    const [preSelected, setPreSelected] = useState(selectedButtonId || 'graph');

    const handleMouseEnter = () => {
        if (selected !== "all") {
            setPreSelected(selected);
        }
        setSelected('all');
    };

    const handleMouseLeave = () => setSelected(preSelected);

    const handleClick = (id) => () => {
        setSelected(id);
        setPreSelected(id);
        setSelectedForFile(fileIndex, id);

        if(id === "cref") {
            setButtonClick(true);
        } else {
            setButtonClick(false);
        };
    };

    return (
        <ButtonGroup
            className="image-mj-actions"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ visibility: isImageLoaded ? 'visible' : 'hidden' }}
        >
            <Button
                    variant="secondary" id="cref"
                    className="border-0 m-0 p-0 image-mj-icon"
                    hidden={selected !== 'cref' && selected !== 'all'}
                    onClick={handleClick('cref')}
                >
                    <i className="bi bi-person-fill"></i>
                </Button>

                <Button variant="secondary" id="sref"
                    className="border-0 m-0 p-0 image-mj-icon"
                    hidden={selected !== 'sref' && selected !== 'all'}
                    onClick={handleClick('sref')}
                >
                    <i className="bi bi-paperclip icon-45"></i>
                </Button>

                <Button
                    variant="secondary" id="graph"
                    className=" border-0 m-0 p-0 image-mj-icon"
                    hidden={selected !== 'graph' && selected !== 'all'}
                    onClick={handleClick('graph')}
                >
                    <i className="bi bi-image"></i>
                </Button>
        </ButtonGroup>
    );
};

const ImageItem = ({ fileString, index, chatClassName, selectedButtons, setSelectedForFile, setButtonClick }) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    let fileObj = typeof fileString === "string" ? JSON.parse(fileString) : fileString;

    return (
        <div className="p-3 m-3">
            <img src={fileObj.thumbnail_url} alt={`Slide ${index}`} onLoad={() => setIsImageLoaded(true)} />
            {chatClassName.toLowerCase() === "midjourney" && (
                <ImageActions
                    fileIndex={index}
                    selectedButtonId={selectedButtons[index]}
                    setSelectedForFile={setSelectedForFile}
                    isImageLoaded={isImageLoaded}
                    setButtonClick={setButtonClick}
                />
            )}
        </div>
    );
};

export default ImageItem;
