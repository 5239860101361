import React, { useState, useCallback, useEffect, } from 'react';
import { Button, ListGroup, } from 'react-bootstrap';
import ChatDelete from './chat_sidebar_items_delete.jsx';
import ChatEdit from './chat_sidebar_items_edit.jsx';
import NewChat from './chat_sidebar_items_newchat.jsx';
import { baseUrl_api } from './config.jsx'
import { v4 as uuidv4 } from 'uuid';
import { toast, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function ChatSidebarItems ({ uid, handleChatClick, }) {
    const [customPrompt, setCustomPrompt] = useState([]);
    const [promptClass, setPromptClass] = useState([]);
    const [systemPrompt, setSystmePrompt] = useState([]);

    const [showDeleteChat, setShowDeleteChat] = useState(false);
    const [isDeleteChat, setIsDeleteChat] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    
    const [showEditChat, setShowEditChat] = useState(false);
    const [idToEdit, setIdToEdit] = useState(null);
    const [showNewDelete, setShowNewDelete] = useState(false);

    const handleAddChatClick = () => {
        if(customPrompt.length < 10) {
            const guid = uuidv4();

            const newCard = {
                id: guid,
                content: <
                    NewChat 
                    classID={guid}
                    title="N"
                    className="New Chat"
                    displayName="New Chat"
                    nickName="Make your own chat."
                    isSystem={false}
                    disabled={true}
                    handleChatClick={onChatClick}
                    onDelete={handleDeleteCard}
                    onEdit={handleEditCard}
                />,
            };

            // setCustomPrompt([...customPrompt, newCard]);
            setCustomPrompt([newCard, ...customPrompt]);
        } else {
            toast.error(`You may create only up to 10 custom chats.`);
            return;
        };
    };

    const onChatClick = (chatClass) =>{
        if (chatClass.disabled) {
            setIdToEdit(chatClass.classID);
            setShowEditChat(true);
        } else {
            setIdToEdit(null);
            handleChatClick(chatClass);
        } ;
    };

    // 删除 chat
    const handleDeleteCard = useCallback( async (id) => {
        setShowDeleteChat(true);
        setIdToDelete(id);
    }, []);

    // 编辑 chat
    const handleEditCard = useCallback((id) => {
        setIdToEdit(id);
        setShowEditChat(true);
    }, []);

    // 模型初始化    
    const getPromptClass = async () => {
        if (uid) {
            try {
                const response = await fetch(baseUrl_api + '/class', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', },
                    credentials: 'include',
                    body: JSON.stringify({ 'uid': uid }),
                });
                const data = await response.json();
                setPromptClass(data);
            } catch (error) {
                console.error('Error:', error);
            };
        };
    };

    useEffect(() => {
        const newCustomPrompt = [];
        const newSystemPrompt = [];
    
        promptClass.forEach((item) => {
            if(item.IsCustom) {
                const newCustomCard = {
                    id: item.ClassID,
                    content: (
                        <NewChat
                            classID={item.ClassID}
                            className={item.Class}
                            title={item.Title}
                            displayName={item.DisplayName}
                            nickName={item.NickName}
                            describe={item.Describe} // 注意这里可能有拼写错误
                            isSystem={!item.IsCustom} // 假设 IsCustom 为 true 时，isSystem 应为 false
                            handleChatClick={onChatClick}
                            onDelete={handleDeleteCard}
                            onEdit={handleEditCard}
                            disabled={item.disabled}
                        />
                    ),
                };

                newCustomPrompt.push(newCustomCard);
            } else {
                const newSystemCard = {
                    id: item.ClassID,
                    content: (
                        <NewChat
                            classID={item.ClassID}
                            className={item.Class}
                            title={item.Title}
                            displayName={item.DisplayName}
                            nickName={item.NickName}
                            describe={item.Describe} // 注意这里可能有拼写错误
                            isSystem={!item.IsCustom} // 假设 IsCustom 为 true 时，isSystem 应为 false
                            handleChatClick={onChatClick}
                            disabled={item.disabled}
                        />
                    ),
                };

                newSystemPrompt.push(newSystemCard);
            };
        });
    
        // 更新状态
        // setCustomPrompt((prevCustomPrompt) => [...prevCustomPrompt, ...newCustomPrompt]);
        // setSystmePrompt((prevSystemPrompt) => [...prevSystemPrompt, ...newSystemPrompt]);
        setCustomPrompt(newCustomPrompt);        
        setSystmePrompt(newSystemPrompt);
    }, [promptClass]);
    
    
    // 页面初始化
    useEffect(() => {
        getPromptClass();
        setShowNewDelete(true);
        // if (uid === "o7dH553U_e2l6Ped2h32cfvy13fs") {
        //     setShowNewDelete(true);
        // };
    }, [uid]);
    
    useEffect(() => {
        if (isDeleteChat && idToDelete !== null) {
            setCustomPrompt(prevCards => prevCards.filter(card => card.id !== idToDelete));
            
            try {
                const bodyData ={
                    id: idToDelete,
                    uid: uid,
                };
                const response = fetch(baseUrl_api + '/deletenewchat', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', },
                    credentials: 'include',
                    body: JSON.stringify(bodyData),
                });
            } catch (error) {
                console.error('Error:', error);
            };            
            
            setIsDeleteChat(false);
            setIdToDelete(null);
        }
    }, [isDeleteChat, idToDelete]);

    const handelSetCustomChat = async (editChatClass) =>{
        // 使用 map 方法来创建一个新的数组
        const updatePromises = customPrompt.map( async (card) => {
            // 检查当前遍历的项是否是需要更新的项
            if (card.id === editChatClass.id) {
                let isDisabled = true;
                let className = "";
                
                // 如果是, 写入数据库
                try {
                    const bodyData ={
                        id: editChatClass.id,
                        uid: uid,
                        prompt: editChatClass.prompt,
                        temperature: editChatClass.temperature,
                        tokens: 1024,
                        displayname: editChatClass.name,
                        nickname: editChatClass.desc,
                    };
                    const response = await fetch(baseUrl_api + '/addnewchat', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json', },
                        credentials: 'include',
                        body: JSON.stringify(bodyData),
                    });
                    const data = await response.json();
                    if(data.code === 200) {                        
                        className = data.className;
                        isDisabled = false;  
                    };

                } catch (error) {
                    console.error('Error:', error);
                };

                // 如果是，创建一个新的对象来替换原来的项
                return {
                    ...card, // 复制原来的属性
                    content: (
                        <NewChat
                            classID={card.id}
                            title="N" // 假设 title 始终为 "N"
                            className={className}
                            displayName={editChatClass.name}
                            nickName={editChatClass.desc} // 假设 nickName 始终为 "Make your own chat."
                            isSystem={false} // 假设 isSystem 始终为 falseS
                            disabled={isDisabled}
                            handleChatClick={onChatClick}
                            onDelete={handleDeleteCard}
                            onEdit={handleEditCard}
                        />
                    ),
                };
            }
            // 如果不是需要更新的项，直接返回原来的项
            return card;
        });

        // 等待所有的更新操作完成
        const updatedCustomsizePrompt = await Promise.all(updatePromises);
        // 使用新的数组来更新状态
        setCustomPrompt(updatedCustomsizePrompt);
    };    

    return (
        <>
        { showNewDelete &&
            <Button
                variant="outline-success"
                className="btn btn-lg fs-6 text-decoration-none py-3 m-0 my-1 rounded-0"
                style={{ width: '100%', borderStyle: 'dashed' }}
                onClick={handleAddChatClick}
            >
                <i className="bi bi-lg bi-plus-circle-dotted"> New Chat</i>
            </Button>
        }
        <ListGroup className="list-group list-group-flush border-bottom scrollarea">
            { customPrompt
                .map((item) => (
                    <div key={item.id}>
                        {item.content}
                    </div>
                ))
            }
            <div id="items-system">
                {systemPrompt.map((card) => (
                    <div key={card.id}>
                        {card.content}
                    </div>
                ))}
            </div>
        </ListGroup>

        { showDeleteChat && 
            <ChatDelete
                setShowDeleteChat={setShowDeleteChat}
                setIsDeleteChat={setIsDeleteChat}
            />
        }

        { showEditChat && 
            <ChatEdit
                classID={idToEdit}
                setShowEditChat={setShowEditChat}
                onSetCustomChat={handelSetCustomChat}
            />
        }
        </>
    );
};
  
export default ChatSidebarItems;