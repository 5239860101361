import React, {useState, useEffect, useRef } from 'react';
import ChatMainMessageBodyCardReceiver from './chat_main_message_body_card_receiver.jsx'
import ChatMainMessageBodyCardDesacribe from './chat_main_message_body_card_describe.jsx';
import ChatMainMessageBodyCardPromotion from './chat_main_message_body_card_promotion.jsx'
import ChatMainMessageBodyCardSender from './chat_main_message_body_card_sender.jsx'
import { baseUrl_api, getImageUrlChatClassName, discordUpload, chatFile, chatMedia, chatArray, fileTranslator } from './config.jsx'
import moment from 'moment';
import 'moment-timezone';
import FileDrag from './file_drag.jsx';
import { getFile } from './file_upload.jsx'
import ChatMainMessageBodyMedia from './chat_main_message_body_media.jsx';
import ChatMainMessageBodyFile from './chat_main_message_body_file.jsx';


function generateTimestamps() {
    const now = moment();
    const userTimezone = moment.tz.guess();
    const localTime = now.tz(userTimezone).format();

    return {
        currentDate: now.format('YYYY-MM-DD'), // 当前日期（格式化为 YYYY-MM-DD）
        currentTime: now.format('HH:mm:ss'),   // 当前时间（格式化为 HH:MM:SS）
        timestamp: Math.floor(now.valueOf() / 1000), // 当前时间戳（秒）
        datetime: localTime // 用户时区的本地日期和时间
    };
}


function ChatMainBody ({
    uid, chatClass, sendMessage, receiveMessage, forceUpdate,
    setImageOperate, setFileUpload, // 标记文件是否长传, 对应 chat_main 的 isUpload 参数
    attachments=null
}) {
    const [showFileDrag, setShowFileDrag] = useState(false);
    const messageEndRef = useRef(null);
    const [receiverLoaded, setReceiverLoaded] = useState(false);
    const [message, setMessage] = useState([]);
    const [currentReceiveMessage, setCurrentReceiveMessage] = useState('');
    const [lastMessageId, setLastMessageId] = useState(null);
    const [maxFiles, setMaxFiles] = useState(9);
    const [fileType, setFileType] = useState("image")

    // 处理文件上传的函数
    const handleFileUpload = (message, type) => {
        if(message.code) {
            const files = {
                "code": message.code,
                "message": JSON.stringify(message.message.files),
                "filetype":  type,
            };

            // 上传文件到服务器等操作
            // handleGetFile(file);

            if (message.code === 200) {
                setFileUpload(files.message, true);
            };
        } else {
            setFileUpload(null, true);
        };        
    };

    const handleLoaded = () => {
        setReceiverLoaded(true);
    };


    const handleSendMessage = async (messageObject) => {
        messageObject.classID = chatClass.classID;
        setImageOperate(messageObject);
    };


    const loadHistory = async () => {
        try {
            const response = await fetch(baseUrl_api + '/chathistory', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                credentials: 'include',
                body: JSON.stringify({ 'uid':uid, 'classID': chatClass.classID }),
            });

            const data = await response.json();
            setMessage(data);
        } catch (error) {
            console.error('Error:', error);
        };
    };

    useEffect(() => {
        const { currentDate, currentTime, timestamp, datetime } = generateTimestamps();

        // 将 sendMessage 构建为对象
        if(sendMessage) {
            const newMessage = {
                completiontokens: 100,
                date: currentDate,
                datetime: datetime,
                msgcontent: sendMessage,
                msgtype: "text",
                prompttokens: 200,
                time: currentTime,
                timestamp: timestamp,
                attachments: attachments
            };
            
            if (!chatArray.includes(chatClass.className)) {
                setMessage(prevMessages => [...prevMessages, newMessage]);
                setLastMessageId(null);
            };
        };        
    }, [sendMessage, forceUpdate])


    useEffect(() => {
        // console.log(114, receiveMessage);
        // 更新接收到的消息内容
        if (receiveMessage) {
            // 累积接收到的消息
            setCurrentReceiveMessage(receiveMessage);
        };
    }, [receiveMessage, forceUpdate])
    
   
    useEffect(() => {
        // console.log(
        //     130, 
        //     "currentReceiveMessage:", 
        //     currentReceiveMessage, 
        //     currentReceiveMessage.type, 
        //     lastMessageId
        // );

        const { currentDate, currentTime, timestamp, datetime } = generateTimestamps();
        // 如果当前累积的接收消息不为空，并且满足结束条件（例如，收到特定的结束符号或者静默期）
        if (currentReceiveMessage) {
            let currentReceiveMessage_json;
            if(typeof currentReceiveMessage === "string") {
                currentReceiveMessage_json = JSON.parse(currentReceiveMessage);            
            } else {
                currentReceiveMessage_json = currentReceiveMessage;
            }
            const receiveType = currentReceiveMessage_json.type;
            let receiveMessage = currentReceiveMessage_json.message;

            if (typeof receiveMessage === 'object' && receiveMessage !== null) {
                // 将对象转换为 JSON 字符串
                receiveMessage = JSON.stringify(receiveMessage);              
            };

            if (!chatArray.includes(chatClass.className)) {
                if (currentReceiveMessage.type && currentReceiveMessage.type !== "text" || !lastMessageId) {
                    // console.log(151, receiveMessage);
                    const newMessage = {
                        completiontokens: 100,
                        date: currentDate,
                        datetime: datetime,
                        response: receiveMessage,
                        msgtype: "text",
                        prompttokens: 200,
                        time: currentTime,
                        timestamp: timestamp,
                        receiveType: receiveType,
                    };
                
                    setLastMessageId(timestamp);
                    setMessage(prevMessages => [...prevMessages, newMessage]);
                } else {
                    // console.log(167, receiveMessage);
                    setMessage(prevMessages => prevMessages.map(msg => {
                        if (msg.timestamp === lastMessageId) {
                            return {
                                ...msg,
                                response: receiveMessage,
                                receiveType: receiveType
                            };
                        }
                        return msg;
                    }));
                };
            } else {
                const messageObject = JSON.parse(currentReceiveMessage);
                const newMessage = {
                    reload: messageObject.reload
                };
            
                setLastMessageId(timestamp);
                setMessage(prevMessages => [...prevMessages, newMessage]);
            }
        };
    }, [currentReceiveMessage, forceUpdate]); // 当 currentReceiveMessage 更新时触发

    useEffect(() => {
        // 滚动到最底端的函数
        const scrollToBottom = () => {
            if (messageEndRef.current) {
                messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        };
        
        // 当消息数组更新时，执行滚动
        if (!chatArray.includes(chatClass.className)) {
            scrollToBottom();
        };
    }, [message]); // 添加 message 作为 useEffect 的依赖项

    // 检查所有子组件是否都加载完成
    useEffect(() => {
        if (receiverLoaded && !chatArray.includes(chatClass.className)) {
            setTimeout(() => {
                if (messageEndRef.current) {
                    messageEndRef.current.scrollIntoView({ behavior: 'auto' });
                }
        
                // 重置加载状态，以便下次加载
                setReceiverLoaded(false);
            }, 300);
        };
    }, [receiverLoaded]);
    
    useEffect(() => {
        let isMounted = true; // 添加一个标志来跟踪组件的挂载状态
        setMessage([]);
        // setLoadedCount(0);

        const fetchDataAndFile = async () => {
            if (chatClass.className) {
                await loadHistory(); // 等待历史消息加载完成
            };

            setShowFileDrag(chatClass && chatClass.classID && 
                ( chatClass.className.toLowerCase().startsWith('chatgpt') ||
                    chatClass.className.toLowerCase()==='translator'
                )
            );
            
            // 页面初次加载的时候, 如果需要读取服务器上传的文件
            if (getImageUrlChatClassName.includes(chatClass.className)) {
                if (isMounted) { // 只有在组件挂载时才调用 getFile
                    getFile(uid, "image", handleGetFile, false);
                };
            };
        };

        if(discordUpload.includes(chatClass.className)) {
            setMaxFiles(1);
            setFileType("image");
        } else if (fileTranslator.includes(chatClass.className)) {
            setMaxFiles(1);
            setFileType("translator");
        } else {
            setMaxFiles(9);
            setFileType("image");
        };
        
        fetchDataAndFile(); // 调用 async 函数来执行异步操作

        return () => {
            isMounted = false; // 当组件卸载时，更新标志
        };

    }, [chatClass.className]);

    const handleGetFile = (response) => {
        // 解析响应消息为对象
        let messageObject;
        if (typeof response === "string") {
            messageObject = JSON.parse(response);
        } else {
            messageObject = response;
        };        

        if(response && response.files) {
            if(messageObject.files.length > 0) {
                setFileUpload(messageObject.files, false)    // 标记文件 isUpload 是 upload还是 get
            };
        };
    };

    function convertToLocalTime(timestamp) {
        const date = new Date(timestamp * 1000);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    function generateRandomCode(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    // console.log(273, chatClass.className, message, receiveMessage);

    const renderChildren = () => {
        return (
            <>
                { chatClass.describe && (
                    <ChatMainMessageBodyCardDesacribe
                        chatDescribe={chatClass.describe}
                    />
                )}

                { chatClass.promotion && (
                    <ChatMainMessageBodyCardPromotion
                        chatPromotion={chatClass.promotion}
                    />
                )}

                { chatMedia.includes(chatClass.className) && (
                    <ChatMainMessageBodyMedia uid={uid} chatClass={chatClass} mediasArray={message}/>
                )}

                { chatFile.includes(chatClass.className) && (
                    <ChatMainMessageBodyFile uid={uid} chatClass={chatClass} filesArray={message}/>
                )}

                { !chatArray.includes(chatClass.className) &&  (
                    <>
                        { message.map((msg) => {
                            return (
                                <div>
                                    { msg.msgcontent && (
                                        <ChatMainMessageBodyCardSender
                                            key={`sender-${msg.timestamp}`} // 为发送者组件添加唯一的 key
                                            // date={msg.date}
                                            time={convertToLocalTime(msg.timestamp)}
                                            message={msg.msgcontent} // 假设消息内容的属性名是 msgcontent
                                            messageType={msg.msgtype}
                                            attachments={msg.attachments}
                                        />
                                    )}

                                    { msg.response &&
                                        !chatMedia.includes(chatClass.className) && (
                                        <ChatMainMessageBodyCardReceiver
                                            key={`receiver-${msg.timestamp}`} // 为接收者组件添加唯一的 key
                                            // date={msg.date}
                                            time={convertToLocalTime(msg.timestamp)}
                                            message={msg.response} // 假设消息内容的属性名是 msgcontent
                                            messageType={
                                                msg.receiveType ? msg.receiveType : msg.msgtype
                                            }
                                            setImageOperate={handleSendMessage}
                                            onLoaded={handleLoaded}
                                        />
                                    )}
                                </div>
                            )
                        })}
                    </>
                )}

                <div ref={messageEndRef} />
            </>
        )
    }

    return (
        <>
            {showFileDrag ? (
                <FileDrag
                    uid={uid}
                    onDrop={handleFileUpload}
                    chatClassName={chatClass.className}
                    maxFiles={maxFiles}
                    container={fileType}
                >
                    {renderChildren()}
                </FileDrag>
            ) : (
                <div className="chat-main-message-body">
                    {renderChildren()}
                </div>                
            )}
        </>
    );

    // return (
    //     <>
    //     { showFileDrag && (
    //         <FileDrag uid={uid} onDrop={handleFileUpload} chatClassName={chatClass.className} maxFiles={maxFiles}>
    //             { chatClass.describe && (
    //                 <ChatMainMessageBodyCardDesacribe
    //                     chatDescribe={chatClass.describe}
    //                 />
    //             )}

    //             { chatClass.promotion && (
    //                 <ChatMainMessageBodyCardPromotion
    //                     chatPromotion={chatClass.promotion}
    //                 />
    //             )}
                
    //             { message.map((msg) => {
    //                 return (
    //                     <div>
    //                         {msg.msgcontent && (
    //                             <ChatMainMessageBodyCardSender
    //                                 key={`sender-${msg.timestamp}`} // 为发送者组件添加唯一的 key
    //                                 // date={msg.date}
    //                                 time={convertToLocalTime(msg.timestamp)}
    //                                 message={msg.msgcontent} // 假设消息内容的属性名是 msgcontent
    //                                 messageType={msg.msgtype}
    //                             />
    //                         )}
    //                         { msg.response && (
    //                             <ChatMainMessageBodyCardReceiver
    //                                 key={`receiver-${msg.timestamp}`} // 为接收者组件添加唯一的 key
    //                                 // date={msg.date}
    //                                 time={convertToLocalTime(msg.timestamp)}
    //                                 message={msg.response} // 假设消息内容的属性名是 msgcontent
    //                                 messageType={
    //                                     msg.receiveType ? msg.receiveType : msg.msgtype
    //                                 }
    //                                 setImageOperate={handleSendMessage}
    //                                 onLoaded={handleLoaded}
    //                             />
    //                         )}
    //                     </div>
    //                 )
    //             })}

    //             <div ref={messageEndRef} />
    //         </FileDrag>
    //     )}
    //     </>
    // );
};

export default ChatMainBody;