import './App.css';
import React, { useState, useEffect, useCallback, } from 'react';
import { Container, } from 'react-bootstrap';
import Header from './header.jsx';
import Pricing from './pricing.jsx';
import Profile from './profile.jsx';
import Chat from './chat';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Cookie from 'universal-cookie';
import { apiUrl_api, baseUrl, baseUrl_api, } from './config.jsx';
import LoginCheck from './login_check';
import LoginPage from './login_back.jsx';
import { toast, ToastContainer } from 'react-toastify';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const cookie = new Cookie();

function HomePage() {
  const [loginInfo, setLoginInfo] = useState({});   // 登录信息
  const [headerItem, setHeaderItem] = useState('home');
  
  const [showProfile, setShowProfile] = useState(false)  // 是否显示个人资料弹窗
  const [showLoginCheck, setShowLoginCheck] = useState(false)  // 是否显示登录检查弹窗

  const cookie_loginInfo = cookie.get('login-info');

  useEffect (() => {
    // 读取缓存中登录信息
    if(cookie_loginInfo && Object.keys(cookie_loginInfo).length>0) {     
      setLoginInfo(cookie_loginInfo);
      
    } else {
      // 如果缓存没有记录，读取后端记录
      const bodyData = {url: baseUrl}
      
      fetch(apiUrl_api + "/login/get_uid", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bodyData),
        keepalive: true,
        credentials: "include", // 允许发送 cookie
      })
      .then(response => response.json())
      .then(data => {
        if(data.uid) {
          const newData = {...data, islogin: true};
          
          setLoginInfo(newData);
          handleCookie(newData);
        } else {
          // 如果后端没有返回 uid，显示登录检查弹窗
          setShowLoginCheck(true);
        };
      })
      .catch(error => {
          console.error(error);
      });
    };
  },[]);

  const handelCheckLgoin = useCallback(() => {
    if (loginInfo.islogin) {
      // 如果已经登录，则显示个人资料弹窗
      setShowProfile(true);

      // 关闭登录检查页面
      setShowLoginCheck(false);
    } else {
      // 如果未登录，则显示登录检查弹窗
      setShowLoginCheck(true);      
    };
  });

  const logout = () => {
    fetch(baseUrl_api + "logout", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        keepalive: true,
        credentials: "include", // 允许发送 cookie
    })    
    .then(data => {
        // console.log(data);
    });

    const bodyData = { url: baseUrl };

    fetch(apiUrl_api + "/logout", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bodyData),
        credentials: "include", // 允许发送 cookie
    })
    .then(response => {
        if (response.ok) {
            // 注销成功，清除前端的登录信息
          cookie.remove('login-info', { path: '/' });
          setLoginInfo({});
          // 现在可以安全地重新加载页面
          window.location.reload();
        } else {
          // 处理错误情况
          console.error('Logout failed:', response);
        }
    })
    .catch(error => {
        console.error('Logout error:', error);
    });
  };

  // 关闭登录检查弹窗
  const handleLoginModalClose = (userInfo) => {
    setShowLoginCheck(false);  
    handleCookie(userInfo);
  };

  // 关闭个人资料弹窗
  const handleProfileModalClose = (islogout) => {
    if(islogout) {
      logout();
      setLoginInfo({});   // 如果点击 logout，清除 loginInfo
    }
    setShowProfile(false);
  };
  
  // 写入 cookie
  const handleCookie = (userInfo) => {
    // 创建一个新的Date对象表示当前时间
    const sixHoursLater = new Date();

    // 将当前时间向前推进6小时
    sixHoursLater.setTime(sixHoursLater.getTime() + (6 * 60 * 60 * 1000));

    // 设置cookie，并指定6小时后过期
    cookie.set('login-info', userInfo, { expires: sixHoursLater, path: '/' });

    setLoginInfo(userInfo);
  };

  return (
    <Container className="container-main">      
      <ToastContainer position={toast.POSITION.TOP_CENTER} />
      <Header loginInfo={loginInfo}
        onHeaderItemSelected = {setHeaderItem}
        onCheckLogin = {handelCheckLgoin}
        style={{ height: '100px' }}
      />

      {showLoginCheck ? (
        <LoginPage onPageClose={handleLoginModalClose}/>
      ) : (
        <> 
          { headerItem.toLowerCase() === "home" && <Chat uid={loginInfo.uid}/> }  
          { headerItem.toLowerCase() === "checkin" && <Pricing uid={loginInfo}/> } 
          { headerItem.toLowerCase() === "pricing" && <Pricing loginInfo={loginInfo} /> }              
          { showProfile && <Profile uid={loginInfo.uid} onModalClose={handleProfileModalClose} /> }  
          { showLoginCheck && <LoginCheck onModalClose={handleLoginModalClose} /> }              
        </>
      )}
    </Container>
  );
}

export default HomePage;
