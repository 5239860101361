import React, { useEffect, useState, } from 'react';
import { Modal, Button, Col, Card, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Cookie from 'universal-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import './login.css';
import { baseUrl_api, apiUrl_api, baseUrl, } from './config.jsx';
import ChatList from './profile_list.jsx';

function Profile({ uid, onModalClose, loginInfo }) {
    const cookie = new Cookie();
    const [show, setShow] = useState(true);
    const [total, setTotal] = useState(null);
    const [used, setUsed] = useState([]);
    const [usedTotal, setUsedTotal] = useState(null);
    const [recharge, setRecharge] = useState([]);
    const [rechargeTotal, setRechargeTotal] = useState(null);
    const [showCS, setShowCS] = useState(false);

    const handleClose = (user) => {
        onModalClose(user);
    };

    const getChatUsed = async () => {
        if (uid) {
            const bodyData = { uid: uid };
            try {
                const response = await fetch(baseUrl_api + "/getchatused", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(bodyData),
                    keepalive: true,
                });

                const chatLogData = await response.json();

                setTotal(chatLogData.total);
                setUsed(chatLogData.used);
                setRecharge(chatLogData.recharge);

            } catch (error) {
                console.error("Error to Image:", error);
            };
        }
    };

    const getUserPay = async (user) => {
        const response = await fetch(baseUrl_api + "/checkuser", {
            method: "POST",
            headers: { "Content-Type": "application/json", },
            credentials: "include",
            body: JSON.stringify({
                "uid": uid  
            }),
        });

        const userPay = await response.json();

        if(userPay.code === 200) {
            if (userPay.userpay.pay > 0) {
                setShowCS(true);
            } else {
                setShowCS(false);
            };
        } else {
            setShowCS(false);
        };
    }
    

    useEffect ( () => {
        // 调用异步函数
        getChatUsed();
        getUserPay(uid)
    }, [])

    useEffect (() => {
        if (used) {
            const lastElement = used[used.length - 1];
            const lastDelta = lastElement ? lastElement.delta : undefined;

            if(lastDelta) {
                setUsedTotal(lastDelta);
            };
        };
    }, [used])

    useEffect ( () => {
        if(recharge) {
            const lastElement = recharge[recharge.length - 1];
            const lastDelta = lastElement ? lastElement.delta : undefined;

            if(lastDelta) {
                setRechargeTotal(lastDelta);
            };
        };
    }, [recharge])

    const handleLgoout = () => {
        cookie.remove('login-info');

        const bodyData = {url: baseUrl};
        fetch(apiUrl_api + "/logout", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyData),
            keepalive: true,
        })

        fetch(baseUrl_api + "/logout", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            keepalive: true,
        })

        onModalClose(true);     // 传递注销信号
        setShow(false);
    };

    const renderTooltipCS = (props) => (
        <Tooltip id="button-tooltip" {...props} className="profile-cs-tooltip">
            <img
                src="https://aimindwork.blob.core.windows.net/asset/kefu_xiaobo01.jpg"
                alt="Customer Service"
                style={{ width: '300px', height: 'auto', borderRadius: '10px' }}
            />
        </Tooltip>
    );

    return (
        <Modal show={show} onHide={handleClose} centered size='lg'>
            <Modal.Header className="justify-content-center bg-light">
                <div className="profile modal-gx-n">
                    <div className="profile-img text-primary rounded-top-xl"></div>
                    <div className="profile-body">
                        <div className="avatar avatar-xl">
                            <span className="avatar-text avatar-text-user">
                                <i className="bi bi-person icon-1-5x"></i>
                            </span>
                        </div>
                    </div>
                    <h6 className='text-center'>Chat Log</h6>
                    { total && (
                    <>
                        <hr className="my-3"></hr>
                        <h6
                            variant='outline-secondary'
                            className='text-center'>
                                Remaining <strong>{total}</strong> tokens
                        </h6>
                    </>
                    )}
                </div>
            </Modal.Header>
            <Modal.Body className="justify-content-center bg-light">
                <Row className="list-container py-8 list-container">                    
                    { used && used.length > 0 &&
                        <Col className="col-6">
                            <Card className='mb-4 rounded-3 shadow-sm'>
                                <Card.Header className='py-3 text-center'>
                                    <h4 className="my-0 fw-normal">
                                        Used <span className="text-danger">
                                                {usedTotal}
                                            </span>
                                    </h4>
                                    <small className="text-body-secondary">使用记录</small>
                                </Card.Header>
                                <Card.Body className="list-chat">
                                    <ChatList listData={used} id={"used"}/>
                                </Card.Body>
                            </Card>
                        </Col>
                    }
                    { recharge && recharge.length > 0 &&
                        <Col className="col-6">
                            <Card className='mb-4 rounded-3 shadow-sm'>
                                <Card.Header className='py-3 text-center'>
                                    <h4 className="my-0 fw-normal">
                                        Recharge <span className="text-success">
                                            {rechargeTotal}
                                        </span></h4>
                                    <small className="text-body-secondary">充值记录</small>
                                </Card.Header>
                                <Card.Body className="list-chat">
                                    <ChatList listData={recharge} id={"recharge"} />
                                </Card.Body>
                            </Card>
                        </Col>
                    }
                </Row>
                <hr className="my-3"></hr>
                <div className="container text-center py-1">
                    <Button
                        variant="outline-success"
                        className="py-2 mb-2 m-2 btn-outline-secondary rounded-3 btn-inline"
                        onClick={handleLgoout}
                    >
                        <i className="bi bi-person-dash"></i> Logout
                    </Button>

                    {showCS && (
                        <OverlayTrigger
                            placement="top-start"
                            overlay={renderTooltipCS}
                        >
                            <Button
                                variant="outline-success"
                                className="py-2 mb-2 m-2 btn-outline-secondary rounded-3 btn-inline"
                                // onClick={handleLgoout}
                            >
                                <i className="bi bi-headset"></i>
                            </Button>
                        </OverlayTrigger>
                    )}
                    
                </div>
            </Modal.Body>
        </Modal>
    );
        
}

export default Profile;
