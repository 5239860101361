import React, { useState, useEffect} from 'react';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { baseUrl_api, } from './config.jsx';

function ChatPhotoMakerBodyImages({ uid, chatClass, reload, onReload }) {
    const [images, setImages] = useState([]);
    const [openGroups, setOpenGroups] = useState({});
    const [reloadFlag, setReloadFlag] = useState(reload);

    const loadPhotoMakerImages = async () => {
        try {
            const response = await fetch(baseUrl_api + '/chathistory', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                credentials: 'include',
                body: JSON.stringify({ 'uid': uid, 'classID': chatClass.classID }),
            });
    
            const data = await response.json();

            // 筛选掉非JSON格式的字符串项
            const validJsonItems = data.filter(item => {
                try {
                    // 尝试解析response字段
                    JSON.parse(item.response);
                    return true; // 解析成功，保留该项
                } catch (error) {
                    // 解析失败，筛选掉该项
                    return false;
                }
            });
            
            if (validJsonItems.length > 0) {
                // 解析每个项的response字符串为JSON对象
                const parsedImages = validJsonItems.map(item => {
                    const responseObj = JSON.parse(item.response);
                    return {
                        ...item,
                        response: responseObj
                    };
                });
                setImages(parsedImages);
    
                // 初始化所有日期分组为打开状态
                const initialOpenGroups = {};
                parsedImages.forEach((image) => {
                    const date = new Date(image.timestamp * 1000).toLocaleDateString();
                    initialOpenGroups[date] = true; // 默认打开
                });
    
                // 设置分组状态，按日期倒序排列
                const sortedDates = Object.keys(initialOpenGroups).sort((a, b) => new Date(b) - new Date(a));
                const sortedOpenGroups = {};
                sortedDates.forEach(date => {
                    sortedOpenGroups[date] = initialOpenGroups[date];
                });
                setOpenGroups(sortedOpenGroups);
            } else {
                setImages([]);
            };

            onReload();
        } catch (error) {
            console.error('Error:', error);
        };
    };

    // 分组函数，根据日期分组图片
    const groupImagesByDate = (images) => {
        return images.reduce((groups, image) => {
            const date = new Date(image.timestamp * 1000).toLocaleDateString();
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(image);
            return groups;
        }, {});
    };

    // 处理图片加载错误
    const handleImageError = (e, imageId) => {
        e.target.style.display = 'none'; // 隐藏加载失败的图片
        setImages((prevImages) => prevImages.filter(image => image.timestamp !== imageId));
    };

    // 切换日期分组的展开/收缩状态
    const toggleGroup = (date) => {
        setOpenGroups((prevOpenGroups) => ({
            ...prevOpenGroups,
            [date]: !prevOpenGroups[date],
        }));
    };

    useEffect(() => {
        loadPhotoMakerImages();
    }, []);

    useEffect(() => {
        if (reload) {
            loadPhotoMakerImages();
        }
    }, [reload]);

    useEffect(() => {
        // 定义一个定时器，每隔2分钟调用loadPhotoMakerImages函数
        const intervalId = setInterval(() => {
            loadPhotoMakerImages();
        }, 120000); // 120000毫秒 = 2分钟

        // 清除定时器的效果
        return () => clearInterval(intervalId);
    }, []); // 空依赖数组[]表示这个effect只在组件挂载时运行一次

    // 分组后的图片
    const groupedImages = groupImagesByDate(images);

    return (
        <div className="chat-photomaker-body-images">
            {Object.entries(groupedImages)
            .sort((a, b) => new Date(b[0]) - new Date(a[0])) // 使用日期对象进行比较
            .map(([date, images]) => (
                <div key={date}>
                    <Button variant="link" onClick={() => toggleGroup(date)}>
                        {date} ({openGroups[date] ? 'Hide' : 'Show'})
                    </Button>
                    {openGroups[date] && (
                        <div className="ms-2">
                            {[...images] // 创建images数组的副本
                            .sort((a, b) => b.timestamp - a.timestamp) // 使用sort函数进行倒序排列
                            .map((image) => (
                                <a
                                    key={image.timestamp}
                                    href={image.response.image_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={(e) => {
                                        e.preventDefault(); // 阻止默认的下载行为
                                        window.open(image.response.image_url, '_blank'); // 在新标签页中打开图像
                                    }}
                                >
                                    <img
                                        src={image.response.thumbnail_url}
                                        className="chat-photomaker-layout-show"
                                        onError={(e) => handleImageError(e, image.timestamp)} // 传递事件对象和图片的timestamp
                                    />
                                </a>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );

};

export default ChatPhotoMakerBodyImages;