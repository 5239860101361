import React from 'react';
import { Dropdown, Tooltip, Overlay } from 'react-bootstrap';

const LanguageDropdown = ({ id, options, selectedOption, setSelectedOption, setSelectedValue, tooltipText, toggleRef, showTooltip, setShowTooltip }) => {
    return (
        <Dropdown>
            <Dropdown.Toggle
                variant="outline-success"
                id={id}
                className="me-2"
                ref={toggleRef}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                <i className="fs-5 bi bi-translate"></i> {selectedOption}
            </Dropdown.Toggle>

            <Overlay target={toggleRef.current} show={showTooltip} placement="top">
                {(props) => (
                    <Tooltip id={`overlay-translate-${id}`} {...props}>
                        {tooltipText}
                    </Tooltip>
                )}
            </Overlay>

            <Dropdown.Menu>
                {options.map((option) => (
                    <Dropdown.Item
                        key={option.id}
                        onClick={() => {
                            setSelectedOption(option.label);
                            setSelectedValue(option.value);
                        }}
                    >
                        {option.label}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default LanguageDropdown;
